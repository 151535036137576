// import React from 'react'

import './index.css'

function Label (props) {
  const { angle, index = 0 } = props

  return <>
    <svg id="label-01"
      className="label"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 741 91.14"
      width="741"
      height="91.14"
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        transformOrigin: 'center right',
        transform: `translateY(50%) rotate(${angle}deg)`,
        animationDelay: `${1700 + (index * 200)}ms`,
      }}
    >
      <g>
        <path d="M13,10.4a21,21,0,0,1,5.8,1.1,2.44,2.44,0,0,0,.69.13,6.57,6.57,0,0,0,1.29-.39,2.2,2.2,0,0,1,.73-.13c1,0,2,.76,3,2.28a7.6,7.6,0,0,1,1.6,4.34,3,3,0,0,1-.72,2,2.24,2.24,0,0,1-1.75.83,2.51,2.51,0,0,1-1.39-.35,24.57,24.57,0,0,1-2.37-2,7.58,7.58,0,0,0-5.07-2.4,3,3,0,0,0-1.92.58,1.84,1.84,0,0,0-.72,1.52c0,1.25,1.09,2.19,3.29,2.82a48,48,0,0,1,5.69,1.91q5.47,2.65,5.48,7.84A9.07,9.07,0,0,1,22.88,38q-3.82,3-10,3a27.45,27.45,0,0,1-5.8-.66,13.58,13.58,0,0,1-4.4-1.53,6,6,0,0,1-1.86-3A13.35,13.35,0,0,1,0,31.22a2.49,2.49,0,0,1,.43-1.59,1.65,1.65,0,0,1,1.35-.69,2.12,2.12,0,0,1,1.57.84,33.35,33.35,0,0,1,2.13,3,5.86,5.86,0,0,0,2.52,2,8.51,8.51,0,0,0,3.58.79A4.93,4.93,0,0,0,14.16,35a1.73,1.73,0,0,0,1-1.51A1.84,1.84,0,0,0,14.37,32a7.47,7.47,0,0,0-2.51-1.16,33.38,33.38,0,0,1-4.68-1.78,13.61,13.61,0,0,1-3-2,8.93,8.93,0,0,1-3.4-6.83,8.33,8.33,0,0,1,.88-3.68,9.22,9.22,0,0,1,2.5-3.11A13.22,13.22,0,0,1,13,10.4Z"/>
        <path d="M40.58,18.91a10.49,10.49,0,0,1,6.34,1.89,5.54,5.54,0,0,1,1.92,2.31,9.37,9.37,0,0,1,.55,3.58l0,3.2a12.15,12.15,0,0,0,.47,4.32,1.58,1.58,0,0,0,.45.6,3,3,0,0,0,.9.24.78.78,0,0,1,.69.9A3.7,3.7,0,0,1,51,38.1,6.88,6.88,0,0,1,48.9,40a6.6,6.6,0,0,1-3.38.95A4.42,4.42,0,0,1,42,39.36a1.21,1.21,0,0,0-.84-.56,2.11,2.11,0,0,0-1.14.54,8,8,0,0,1-4.88,1.57,7.87,7.87,0,0,1-4.55-1.2A5.94,5.94,0,0,1,28.08,35,5.3,5.3,0,0,1,29.88,31q2.82-2.72,8.42-2.73A3,3,0,0,0,39.87,28c.22-.16.33-.55.33-1.15a8.29,8.29,0,0,0-.48-3.3,1.59,1.59,0,0,0-1.54-1A1.82,1.82,0,0,0,37,23a7.08,7.08,0,0,0-1.26,1.42c-1.27,1.82-2.61,2.73-4,2.73a2.37,2.37,0,0,1-1.71-.63,2.1,2.1,0,0,1-.67-1.63,3.3,3.3,0,0,1,.83-2.06A7.38,7.38,0,0,1,32.46,21,17.15,17.15,0,0,1,40.58,18.91ZM39.14,31.35a2.15,2.15,0,0,0-1.64.77,2.9,2.9,0,0,0-.2,3.52,1.56,1.56,0,0,0,1.31.63c1.14,0,1.71-1,1.71-2.9a3.51,3.51,0,0,0-.24-1.59A1.05,1.05,0,0,0,39.14,31.35Z"/>
        <path d="M74.59,25.66,69,36.48a16.71,16.71,0,0,1-2.2,3.5,2.61,2.61,0,0,1-1.93,1,2,2,0,0,1-1.63-.73A16.24,16.24,0,0,1,61,36.91l-6.1-11.57a4.85,4.85,0,0,0-1-1.37,3,3,0,0,0-1.28-.63C51.54,23,51,22.43,51,21.58s.67-1.52,2-1.92a24.47,24.47,0,0,1,6.38-.6,20,20,0,0,1,5.38.53c1.16.35,1.73.89,1.73,1.62a2,2,0,0,1-.81,1.48,4.21,4.21,0,0,0-.67.71,1.11,1.11,0,0,0-.15.6,6.06,6.06,0,0,0,.94,2.43q.95,1.65,1.77,1.65a1.11,1.11,0,0,0,.88-.4,16.48,16.48,0,0,0,1.24-2.11,4.49,4.49,0,0,0,.67-1.89,2,2,0,0,0-.69-1.22A2.21,2.21,0,0,1,69,21c0-.69.46-1.18,1.37-1.49a15.69,15.69,0,0,1,4.47-.45q4.4,0,4.39,1.89c0,.73-.53,1.26-1.57,1.59A4.24,4.24,0,0,0,76,23.4,11.57,11.57,0,0,0,74.59,25.66Z"/>
        <path d="M98.18,30.71H88.08c-.44,0-.66.19-.66.58a3.45,3.45,0,0,0,1.51,2.63,5.06,5.06,0,0,0,3.25,1.23,7.8,7.8,0,0,0,2.29-.39,7.9,7.9,0,0,0,2.08-1,2.2,2.2,0,0,1,1.16-.52A1.46,1.46,0,0,1,98.8,34a2.28,2.28,0,0,1,.54,1.42,3.92,3.92,0,0,1-1.93,3,12.79,12.79,0,0,1-7.89,2.56A11.17,11.17,0,0,1,82,38.29a10.63,10.63,0,0,1-2.45-3.12,10.81,10.81,0,0,1-1.27-5.11,10.44,10.44,0,0,1,2.19-6.49,11.47,11.47,0,0,1,4.82-3.71A12,12,0,0,1,90,18.93a10.69,10.69,0,0,1,6.94,2.32A9.25,9.25,0,0,1,99.25,24a7.15,7.15,0,0,1,1,3.53,4.06,4.06,0,0,1-.58,2.28A1.73,1.73,0,0,1,98.18,30.71Zm-9.54-4h2.17c.92,0,1.37-.42,1.37-1.27a2.72,2.72,0,0,0-.66-1.89,2.22,2.22,0,0,0-1.74-.73,2.19,2.19,0,0,0-1.89.95,3,3,0,0,0-.65,1.78,1.13,1.13,0,0,0,.31.9A1.76,1.76,0,0,0,88.64,26.69Z"/>
        <path d="M124.43,20.16V31.8q0,3.73,2.47,3.74a2.47,2.47,0,0,0,1.23-.35,1.94,1.94,0,0,0,.83-.88,13.45,13.45,0,0,0,.48-4.59,31.24,31.24,0,0,0-.24-4.81,1.67,1.67,0,0,0-.3-.79,3.26,3.26,0,0,0-.82-.57,1.89,1.89,0,0,1-1.22-1.63c0-.89.7-1.5,2.1-1.85a32.8,32.8,0,0,1,7.89-1c1.44,0,2.17.77,2.17,2.3,0,.16,0,.48,0,1,0,.69-.05,1.52-.07,2.51v9.48c0,.48.42.93,1.25,1.33a1.61,1.61,0,0,1,1,1.5q0,1.94-4.57,3a23.39,23.39,0,0,1-5.2.62,2.86,2.86,0,0,1-1.41-.24,1.11,1.11,0,0,1-.44-.92c-.07-.89-.35-1.33-.82-1.33a.94.94,0,0,0-.47.12c-.14.08-.58.37-1.31.87a8.1,8.1,0,0,1-4.66,1.48c-2.75,0-4.69-.8-5.82-2.38q-1.44-2-1.44-6.73c0-1.17,0-2.18,0-3v-1a7.87,7.87,0,0,0-.34-2.71,2.6,2.6,0,0,0-1.21-1.37,3.89,3.89,0,0,1-1.06-.68,1.06,1.06,0,0,1-.23-.71c0-1.09.82-1.81,2.47-2.17a39,39,0,0,1,8.42-.93C124,19.08,124.43,19.44,124.43,20.16Z"/>
        <path d="M151.07,18.93a20.56,20.56,0,0,1,3.5.48,5.73,5.73,0,0,0,.82.08,5.35,5.35,0,0,0,1.42-.39,2.6,2.6,0,0,1,.83-.19,2.71,2.71,0,0,1,2,1.27A9,9,0,0,1,161,22.39a5.35,5.35,0,0,1,.52,2.09,1.87,1.87,0,0,1-.6,1.42,2.1,2.1,0,0,1-1.51.57,2.54,2.54,0,0,1-1.34-.36,14.89,14.89,0,0,1-1.9-1.61,3.78,3.78,0,0,0-2.28-1.33,1.41,1.41,0,0,0-1,.44,1.43,1.43,0,0,0-.44,1c0,.7.67,1.36,2,2A18.53,18.53,0,0,1,160,30.19a5.44,5.44,0,0,1,1.64,4,6.06,6.06,0,0,1-2.62,5,8.79,8.79,0,0,1-5.52,1.74,28,28,0,0,1-3.05-.28c-1.39-.16-2.17-.24-2.35-.24a4.14,4.14,0,0,0-.64.07,6.78,6.78,0,0,1-.9.08,2.3,2.3,0,0,1-1.59-.51,7.25,7.25,0,0,1-1.64-2.5,7.56,7.56,0,0,1-.75-2.94c0-1.31.52-2,1.55-2a1.74,1.74,0,0,1,1.11.4,27.52,27.52,0,0,1,2.11,2.11,8.22,8.22,0,0,0,1.46,1.3,2.33,2.33,0,0,0,1.25.36,1.69,1.69,0,0,0,1.14-.38,1.28,1.28,0,0,0,.43-1c0-.64-.55-1.23-1.66-1.76a15.54,15.54,0,0,1-5-3.5A6.24,6.24,0,0,1,143.4,26a6.54,6.54,0,0,1,1.57-4.4A7.54,7.54,0,0,1,151.07,18.93Z"/>
        <path d="M169.42,11.31a4.33,4.33,0,0,1,3.24,1.37,7,7,0,0,1,1.78,5.11,16.31,16.31,0,0,1-.76,4.72,17.81,17.81,0,0,1-2,4.5c-1,1.49-1.73,2.23-2.32,2.23s-1.38-.95-2.43-2.85a21.53,21.53,0,0,1-1.83-4.51,16.53,16.53,0,0,1-.68-4.41,7.27,7.27,0,0,1,1.1-4.1A4.39,4.39,0,0,1,169.42,11.31Zm.11,20.3a4.27,4.27,0,0,1,3.2,1.39,4.6,4.6,0,0,1,0,6.52,4.61,4.61,0,0,1-6.48.12,4.5,4.5,0,0,1-1.44-3.39A4.34,4.34,0,0,1,166,33.09,4.67,4.67,0,0,1,169.53,31.61Z"/>
        <path d="M13,48.17a20.88,20.88,0,0,1,5.8,1.09,2.44,2.44,0,0,0,.69.13A7,7,0,0,0,20.73,49a2,2,0,0,1,.73-.13c1,0,2,.76,3,2.27a7.62,7.62,0,0,1,1.6,4.34,3,3,0,0,1-.72,2,2.21,2.21,0,0,1-1.75.83A2.51,2.51,0,0,1,22.24,58a24.57,24.57,0,0,1-2.37-2,7.55,7.55,0,0,0-5.07-2.41,3,3,0,0,0-1.92.58,1.88,1.88,0,0,0-.72,1.53c0,1.24,1.09,2.18,3.29,2.81a48,48,0,0,1,5.69,1.91q5.47,2.67,5.48,7.84a9.06,9.06,0,0,1-3.74,7.48q-3.82,3-10,3A27.41,27.41,0,0,1,7.09,78a13.79,13.79,0,0,1-4.4-1.54,5.89,5.89,0,0,1-1.86-3A13.35,13.35,0,0,1,0,69,2.45,2.45,0,0,1,.43,67.4a1.65,1.65,0,0,1,1.35-.69,2.12,2.12,0,0,1,1.57.84,30.43,30.43,0,0,1,2.13,3,5.85,5.85,0,0,0,2.52,2,8.52,8.52,0,0,0,3.58.8,5,5,0,0,0,2.58-.59,1.75,1.75,0,0,0,1-1.52,1.86,1.86,0,0,0-.78-1.48,7.88,7.88,0,0,0-2.51-1.16,32.34,32.34,0,0,1-4.68-1.77,14.34,14.34,0,0,1-3-2A8.94,8.94,0,0,1,.77,58a8.4,8.4,0,0,1,.88-3.69,9.19,9.19,0,0,1,2.5-3.1A13.22,13.22,0,0,1,13,48.17Z"/>
        <path d="M41.61,56.68a11.89,11.89,0,0,1,6.9,2,10.89,10.89,0,0,1,3.42,3.8,10.64,10.64,0,0,1,1.33,5.11q0,4.66-4.28,7.93a15,15,0,0,1-9.34,3.11,11.8,11.8,0,0,1-8.32-2.92,9.56,9.56,0,0,1-3.09-7.39A10.4,10.4,0,0,1,32,60.07,14.26,14.26,0,0,1,41.61,56.68Zm-1.72,5a1.84,1.84,0,0,0-1.51.7,2.82,2.82,0,0,0-.57,1.85,17.73,17.73,0,0,0,.48,3.52,28.31,28.31,0,0,0,1.15,3.94,2.3,2.3,0,0,0,2.17,1.76q1.92,0,1.92-2.43a20.36,20.36,0,0,0-.49-4.1,16.75,16.75,0,0,0-1.17-3.74A2.27,2.27,0,0,0,39.89,61.64Z"/>
        <path d="M65.22,56.85q1.23,0,1.23,1.74V59c0,.23,0,.42,0,.56,0,.78.23,1.16.68,1.16.25,0,.63-.31,1.14-.92a8.31,8.31,0,0,1,2.92-2.12,8.86,8.86,0,0,1,3.61-.78c2.39,0,4.06.87,5,2.62.35.64.65,1,.88,1s.53-.29,1.12-.86a8.82,8.82,0,0,1,6.33-2.73,6.73,6.73,0,0,1,4.54,1.52,6,6,0,0,1,1.84,4.77c0,.46,0,1.23,0,2.32,0,.38,0,1.5,0,3.38,0,1.2,0,2,0,2.53,0,.3,0,.52,0,.65a3.66,3.66,0,0,0,.86,2.4,7.9,7.9,0,0,1,.64.83,1.07,1.07,0,0,1,.12.55q0,2.74-6.47,2.75a12.79,12.79,0,0,1-4.61-.69c-1.17-.46-1.75-1.06-1.75-1.81a1.42,1.42,0,0,1,.12-.65c.08-.15.35-.55.82-1.19s.67-2.55.67-5.87a13.33,13.33,0,0,0-.41-4A1.84,1.84,0,0,0,82.45,63a2,2,0,0,0-1.25.44,2,2,0,0,0-.72,1.11,25.47,25.47,0,0,0-.22,4.12A33.9,33.9,0,0,0,80.49,73a6.71,6.71,0,0,0,1.08,2.15,1.49,1.49,0,0,1,.22.82q0,2.55-6.68,2.55a16,16,0,0,1-5.42-.6,1.68,1.68,0,0,1-1.12-1.67,1.47,1.47,0,0,1,.14-.75,8.81,8.81,0,0,1,1-1,2.23,2.23,0,0,0,.61-.85,6.17,6.17,0,0,0,.21-1.45q.15-2.37.15-4.77,0-4.22-2.15-4.21c-1.08,0-1.71.6-1.91,1.8a33.31,33.31,0,0,0-.26,4.86,28.2,28.2,0,0,0,.1,3.09,4.48,4.48,0,0,0,.52,1.53,3.22,3.22,0,0,1,.52,1.5c0,.93-.65,1.58-2,1.93a17.64,17.64,0,0,1-4.68.58A16.44,16.44,0,0,1,56,78c-1-.35-1.54-.91-1.54-1.67a1.25,1.25,0,0,1,.2-.73,6.59,6.59,0,0,1,.94-.94,3.65,3.65,0,0,0,1.11-2.43q.24-2.67.24-5.52a13.93,13.93,0,0,0-.2-2.85,2.3,2.3,0,0,0-.88-1.3,4.65,4.65,0,0,1-.82-.75,1.22,1.22,0,0,1-.18-.71q0-1.44,2.25-2.28A27.51,27.51,0,0,1,65.22,56.85Z"/>
        <path d="M117.08,68.47H107c-.45,0-.67.19-.67.58a3.47,3.47,0,0,0,1.51,2.63,5,5,0,0,0,3.26,1.24,7.52,7.52,0,0,0,2.29-.4,7.39,7.39,0,0,0,2.07-1,2.28,2.28,0,0,1,1.16-.51,1.49,1.49,0,0,1,1.1.68,2.34,2.34,0,0,1,.53,1.42,3.91,3.91,0,0,1-1.93,3,12.82,12.82,0,0,1-7.88,2.55,11.21,11.21,0,0,1-7.57-2.62,10.73,10.73,0,0,1-2.44-3.11,10.83,10.83,0,0,1-1.27-5.11,10.44,10.44,0,0,1,2.19-6.49,11.55,11.55,0,0,1,4.81-3.72,12.19,12.19,0,0,1,4.75-.92A10.65,10.65,0,0,1,115.84,59a9.27,9.27,0,0,1,2.32,2.75,7.22,7.22,0,0,1,1,3.52,4.2,4.2,0,0,1-.58,2.29A1.75,1.75,0,0,1,117.08,68.47Zm-9.54-4h2.17q1.38,0,1.38-1.26a2.69,2.69,0,0,0-.67-1.9,2.26,2.26,0,0,0-1.74-.73,2.22,2.22,0,0,0-1.89.95,3,3,0,0,0-.64,1.78,1.1,1.1,0,0,0,.31.9A1.68,1.68,0,0,0,107.54,64.45Z"/>
        <path d="M132.25,50.06,132,58.44v.47c0,.29.1.43.28.43a2.67,2.67,0,0,0,.86-.58,8.51,8.51,0,0,1,5.54-2.06A7.48,7.48,0,0,1,144,58.87,9.35,9.35,0,0,1,146.84,66a12.38,12.38,0,0,1-12.53,12.69,13.51,13.51,0,0,1-4-.56,16.37,16.37,0,0,1-3.92-2,1.1,1.1,0,0,0-.52-.19,5.76,5.76,0,0,0-1.33.41,3.75,3.75,0,0,1-1.31.21c-.89,0-1.33-.29-1.33-.86a2.33,2.33,0,0,1,.1-.64,15.7,15.7,0,0,0,.58-3.46c.1-1.38.15-3.55.15-6.53V59.17a12.5,12.5,0,0,0-.17-2.77,1.46,1.46,0,0,0-1-.88,4.61,4.61,0,0,1-1.54-.94,1.64,1.64,0,0,1-.43-1.17c0-1,1-1.9,3.07-2.81a22.22,22.22,0,0,1,4.18-1.28,20.27,20.27,0,0,1,4.28-.55C131.89,48.77,132.27,49.2,132.25,50.06Zm1.85,13.1a1.87,1.87,0,0,0-1.68.8,4.91,4.91,0,0,0-.41,2.45,42.87,42.87,0,0,0,.35,6.81,1.72,1.72,0,0,0,1.82,1.63,2.62,2.62,0,0,0,2.39-1.33,7.53,7.53,0,0,0,.82-3.87q0-3.74-1.38-5.46A2.55,2.55,0,0,0,134.1,63.16Z"/>
        <path d="M161.68,56.68a11.89,11.89,0,0,1,6.9,2,10.89,10.89,0,0,1,3.42,3.8,10.64,10.64,0,0,1,1.33,5.11q0,4.66-4.28,7.93a15,15,0,0,1-9.34,3.11,11.8,11.8,0,0,1-8.32-2.92,9.56,9.56,0,0,1-3.09-7.39,10.4,10.4,0,0,1,3.78-8.29A14.24,14.24,0,0,1,161.68,56.68Zm-1.72,5a1.84,1.84,0,0,0-1.51.7,2.82,2.82,0,0,0-.57,1.85,17.73,17.73,0,0,0,.48,3.52,28.31,28.31,0,0,0,1.15,3.94,2.3,2.3,0,0,0,2.17,1.76c1.28,0,1.91-.81,1.91-2.43a20.29,20.29,0,0,0-.48-4.1,16.75,16.75,0,0,0-1.17-3.74A2.27,2.27,0,0,0,160,61.64Z"/>
        <path d="M200,50.49V70.1a9.39,9.39,0,0,0,.14,2.15,1.86,1.86,0,0,0,.78.93,3.81,3.81,0,0,1,1,.94,1.74,1.74,0,0,1,.27,1c0,1.14-1.15,2-3.46,2.62a25,25,0,0,1-6.23.79,1.33,1.33,0,0,1-.89-.2,1.85,1.85,0,0,1-.27-1c-.07-.73-.33-1.09-.77-1.09a2.28,2.28,0,0,0-1.14.64,7.52,7.52,0,0,1-4.88,1.78,8.58,8.58,0,0,1-6-2.47,11.79,11.79,0,0,1-2.53-3.41,11,11,0,0,1-1.29-5.09,11.36,11.36,0,0,1,1.67-5.85,10.5,10.5,0,0,1,4.21-4,9.44,9.44,0,0,1,4.41-1.16,9,9,0,0,1,4.47,1.11A1.11,1.11,0,0,0,190,58c.46,0,.69-.62.69-1.85a1.78,1.78,0,0,0-.38-1.22,3.11,3.11,0,0,0-1.34-.71c-.9-.29-1.35-.79-1.35-1.51,0-1,1-1.86,3.11-2.45a35.35,35.35,0,0,1,7.37-1.52C199.37,48.73,200,49.31,200,50.49Zm-11.67,10.8a2.47,2.47,0,0,0-2.21,1.49A10.71,10.71,0,0,0,185,68a7.62,7.62,0,0,0,1.06,4.4,2.35,2.35,0,0,0,2,1.07,2.15,2.15,0,0,0,2-1.62,15.65,15.65,0,0,0,.63-5.23c0-2.2-.25-3.67-.75-4.4A2,2,0,0,0,188.32,61.29Z"/>
        <path d="M224,67.27l-2.83,7.8A29.7,29.7,0,0,1,219,79.8a13.63,13.63,0,0,1-2.42,3.13,9.4,9.4,0,0,1-6.72,2.66,7.75,7.75,0,0,1-5.16-1.67,4.14,4.14,0,0,1-1.52-3.29,3.89,3.89,0,0,1,1.06-2.78,3.46,3.46,0,0,1,2.61-1.13,3.24,3.24,0,0,1,2.5,1,3,3,0,0,1,.6.84,13.85,13.85,0,0,1,.45,1.61,1.06,1.06,0,0,0,1.07,1,1.38,1.38,0,0,0,1.12-.58,2.29,2.29,0,0,0,.45-1.44,9.42,9.42,0,0,0-1.12-3.38l-5.17-11a10.66,10.66,0,0,0-1.23-2.15,6.52,6.52,0,0,0-1.8-1,1.5,1.5,0,0,1-.74-.68,1.83,1.83,0,0,1-.32-1c0-1.28,1.27-2.14,3.81-2.6a33.26,33.26,0,0,1,5.78-.45,10.76,10.76,0,0,1,3.58.47,2.2,2.2,0,0,1,1.76,2.13,2.35,2.35,0,0,1-.49,1.26,2.22,2.22,0,0,0-.47,1.23,2.85,2.85,0,0,0,.22,1.07,21.1,21.1,0,0,0,1,1.89c.54,1,1,1.51,1.44,1.51s.9-.44,1.37-1.33a5.27,5.27,0,0,0,.71-2.54,2.4,2.4,0,0,0-.53-1.61,2.8,2.8,0,0,1-.82-1.66c0-.73.49-1.31,1.46-1.75a9.82,9.82,0,0,1,3.91-.65q4.56,0,4.56,2.19a1.77,1.77,0,0,1-.47,1.28,4.52,4.52,0,0,1-1.68.91,3.33,3.33,0,0,0-1.64,1.29A25.5,25.5,0,0,0,224,67.27Z"/>
        <path d="M250.32,56.7a21.43,21.43,0,0,1,3.51.47,4.72,4.72,0,0,0,.81.09,5.16,5.16,0,0,0,1.42-.39,2.35,2.35,0,0,1,.84-.19,2.74,2.74,0,0,1,2,1.26,9.76,9.76,0,0,1,1.33,2.22,5.46,5.46,0,0,1,.51,2.08,1.89,1.89,0,0,1-.6,1.43,2.09,2.09,0,0,1-1.5.57,2.49,2.49,0,0,1-1.35-.37,14.73,14.73,0,0,1-1.9-1.61,3.74,3.74,0,0,0-2.27-1.33,1.47,1.47,0,0,0-1.05.44,1.43,1.43,0,0,0-.44,1c0,.7.68,1.36,2,2A18.92,18.92,0,0,1,259.22,68a5.45,5.45,0,0,1,1.63,4,6,6,0,0,1-2.62,5,8.75,8.75,0,0,1-5.52,1.74,30,30,0,0,1-3.05-.27q-2.08-.24-2.34-.24a4.12,4.12,0,0,0-.65.06,7,7,0,0,1-.9.09,2.29,2.29,0,0,1-1.59-.52,7.25,7.25,0,0,1-1.63-2.5,7.38,7.38,0,0,1-.76-2.93c0-1.32.52-2,1.55-2a1.74,1.74,0,0,1,1.11.4,26.47,26.47,0,0,1,2.11,2.12A7.88,7.88,0,0,0,248,74.2a2.37,2.37,0,0,0,1.24.35,1.69,1.69,0,0,0,1.14-.38,1.24,1.24,0,0,0,.43-1q0-1-1.65-1.77a15.49,15.49,0,0,1-5-3.5,6.26,6.26,0,0,1-1.54-4.17,6.61,6.61,0,0,1,1.57-4.4A7.58,7.58,0,0,1,250.32,56.7Z"/>
        <path d="M275,56.68a10.47,10.47,0,0,1,6.34,1.89,5.58,5.58,0,0,1,1.93,2.31,9.53,9.53,0,0,1,.54,3.57l0,3.2A12.4,12.4,0,0,0,284.2,72a1.43,1.43,0,0,0,.45.6,2.86,2.86,0,0,0,.91.24c.45.07.68.37.68.9a3.63,3.63,0,0,1-.82,2.15,6.82,6.82,0,0,1-2.14,1.87,6.56,6.56,0,0,1-3.37.94,4.42,4.42,0,0,1-3.57-1.54,1.21,1.21,0,0,0-.84-.56,2.17,2.17,0,0,0-1.14.54,8.06,8.06,0,0,1-4.87,1.56,7.88,7.88,0,0,1-4.56-1.2,5.9,5.9,0,0,1-2.47-4.75,5.3,5.3,0,0,1,1.81-4q2.81-2.7,8.42-2.73a3.16,3.16,0,0,0,1.57-.24,1.49,1.49,0,0,0,.32-1.15,8.52,8.52,0,0,0-.47-3.3,1.6,1.6,0,0,0-1.55-1,1.89,1.89,0,0,0-1.15.38,7.38,7.38,0,0,0-1.26,1.42q-1.9,2.73-4,2.73a2.44,2.44,0,0,1-1.72-.62,2.12,2.12,0,0,1-.67-1.63,3.33,3.33,0,0,1,.83-2.07,7.36,7.36,0,0,1,2.24-1.8A17.07,17.07,0,0,1,275,56.68Zm-1.43,12.43a2.16,2.16,0,0,0-1.65.78,2.68,2.68,0,0,0-.7,1.85,2.63,2.63,0,0,0,.5,1.66A1.58,1.58,0,0,0,273,74c1.14,0,1.72-1,1.72-2.9a3.54,3.54,0,0,0-.25-1.59A1.06,1.06,0,0,0,273.53,69.11Z"/>
        <path d="M309,63.42l-5.56,10.83a17.06,17.06,0,0,1-2.19,3.49,2.64,2.64,0,0,1-1.94,1,2.07,2.07,0,0,1-1.63-.73,17.24,17.24,0,0,1-2.3-3.31l-6.1-11.58a4.78,4.78,0,0,0-1-1.36A2.92,2.92,0,0,0,287,61.1c-1.09-.31-1.64-.9-1.64-1.76s.68-1.51,2-1.91a24.52,24.52,0,0,1,6.38-.6,20.22,20.22,0,0,1,5.39.52c1.15.35,1.72.89,1.72,1.62a2,2,0,0,1-.81,1.49,3.86,3.86,0,0,0-.67.71,1.07,1.07,0,0,0-.15.6,6.16,6.16,0,0,0,.95,2.42c.63,1.11,1.21,1.66,1.76,1.66a1.1,1.1,0,0,0,.88-.41,16.15,16.15,0,0,0,1.25-2.1,4.59,4.59,0,0,0,.66-1.89,1.94,1.94,0,0,0-.69-1.23,2.21,2.21,0,0,1-.73-1.44c0-.69.46-1.18,1.37-1.48a15.77,15.77,0,0,1,4.48-.45q4.38,0,4.38,1.89c0,.73-.52,1.26-1.57,1.59a4.18,4.18,0,0,0-1.66.84A11.08,11.08,0,0,0,309,63.42Z"/>
        <path d="M332.56,68.47h-10.1c-.44,0-.66.19-.66.58a3.44,3.44,0,0,0,1.51,2.63,5,5,0,0,0,3.26,1.24,7.57,7.57,0,0,0,2.29-.4,7.67,7.67,0,0,0,2.07-1,2.28,2.28,0,0,1,1.16-.51,1.45,1.45,0,0,1,1.09.68,2.3,2.3,0,0,1,.54,1.42,3.91,3.91,0,0,1-1.93,3,12.85,12.85,0,0,1-7.89,2.55,11.2,11.2,0,0,1-7.56-2.62,10.76,10.76,0,0,1-2.45-3.11,11,11,0,0,1-1.27-5.11,10.45,10.45,0,0,1,2.2-6.49,11.55,11.55,0,0,1,4.81-3.72,12.19,12.19,0,0,1,4.75-.92A10.65,10.65,0,0,1,331.32,59a9.42,9.42,0,0,1,2.32,2.75,7.22,7.22,0,0,1,1,3.52,4.2,4.2,0,0,1-.58,2.29A1.75,1.75,0,0,1,332.56,68.47Zm-9.54-4h2.17q1.38,0,1.38-1.26a2.69,2.69,0,0,0-.67-1.9,2.26,2.26,0,0,0-1.74-.73,2.22,2.22,0,0,0-1.89.95,3,3,0,0,0-.64,1.78,1.1,1.1,0,0,0,.31.9A1.68,1.68,0,0,0,323,64.45Z"/>
        <path d="M358.81,57.92V69.57c0,2.49.83,3.73,2.47,3.73a2.35,2.35,0,0,0,1.23-.35,2.05,2.05,0,0,0,.84-.87,13.88,13.88,0,0,0,.47-4.6,31.33,31.33,0,0,0-.24-4.81,1.42,1.42,0,0,0-.3-.78,2.73,2.73,0,0,0-.81-.57,1.89,1.89,0,0,1-1.23-1.64c0-.88.7-1.5,2.11-1.84a32.65,32.65,0,0,1,7.88-1c1.45,0,2.17.76,2.17,2.3,0,.15,0,.48,0,1q0,1-.06,2.52V72.1c0,.49.41.93,1.24,1.33a1.64,1.64,0,0,1,1.05,1.51q0,1.94-4.57,3a22.78,22.78,0,0,1-5.2.63,2.86,2.86,0,0,1-1.41-.24,1.15,1.15,0,0,1-.44-.92c-.07-.89-.34-1.34-.82-1.34a1,1,0,0,0-.47.12c-.14.08-.58.37-1.31.87a8.08,8.08,0,0,1-4.66,1.48q-4.12,0-5.82-2.38-1.44-2-1.44-6.72c0-1.18,0-2.18,0-3v-1a8.25,8.25,0,0,0-.33-2.72,2.64,2.64,0,0,0-1.22-1.36,4.1,4.1,0,0,1-1.06-.69,1,1,0,0,1-.23-.71c0-1.09.83-1.81,2.47-2.17a39.64,39.64,0,0,1,8.43-.92C358.39,56.85,358.81,57.21,358.81,57.92Z"/>
        <path d="M385.45,56.7a21.43,21.43,0,0,1,3.51.47,4.72,4.72,0,0,0,.81.09,5.16,5.16,0,0,0,1.42-.39,2.35,2.35,0,0,1,.84-.19,2.74,2.74,0,0,1,2,1.26,9.76,9.76,0,0,1,1.33,2.22,5.46,5.46,0,0,1,.51,2.08,1.89,1.89,0,0,1-.6,1.43,2.09,2.09,0,0,1-1.5.57,2.49,2.49,0,0,1-1.35-.37,14.73,14.73,0,0,1-1.9-1.61,3.74,3.74,0,0,0-2.27-1.33,1.47,1.47,0,0,0-1.05.44,1.43,1.43,0,0,0-.44,1c0,.7.68,1.36,2,2A18.92,18.92,0,0,1,394.35,68,5.45,5.45,0,0,1,396,72a6,6,0,0,1-2.62,5,8.75,8.75,0,0,1-5.52,1.74,30,30,0,0,1-3-.27q-2.09-.24-2.34-.24a4,4,0,0,0-.65.06,7.13,7.13,0,0,1-.9.09,2.29,2.29,0,0,1-1.59-.52,7.25,7.25,0,0,1-1.63-2.5,7.38,7.38,0,0,1-.76-2.93c0-1.32.52-2,1.55-2a1.74,1.74,0,0,1,1.11.4,26.47,26.47,0,0,1,2.11,2.12,7.88,7.88,0,0,0,1.47,1.3,2.37,2.37,0,0,0,1.24.35,1.69,1.69,0,0,0,1.14-.38,1.24,1.24,0,0,0,.43-1q0-1-1.65-1.77a15.49,15.49,0,0,1-5-3.5,6.26,6.26,0,0,1-1.54-4.17,6.61,6.61,0,0,1,1.57-4.4A7.58,7.58,0,0,1,385.45,56.7Z"/>
        <path d="M403.8,49.07A4.31,4.31,0,0,1,407,50.45a6.94,6.94,0,0,1,1.79,5.11,16.28,16.28,0,0,1-.77,4.71,17.48,17.48,0,0,1-2,4.5c-1,1.49-1.74,2.24-2.33,2.24s-1.38-1-2.42-2.86a20.82,20.82,0,0,1-1.84-4.51,16.4,16.4,0,0,1-.68-4.4,7.3,7.3,0,0,1,1.1-4.11A4.42,4.42,0,0,1,403.8,49.07Zm.11,20.3a4.28,4.28,0,0,1,3.2,1.4A4.48,4.48,0,0,1,408.44,74a4.69,4.69,0,0,1-4.66,4.68A4.61,4.61,0,0,1,399.14,74a4.29,4.29,0,0,1,1.27-3.15A4.64,4.64,0,0,1,403.91,69.37Z"/>
      </g>
    </svg>

  </>
}

export default Label
