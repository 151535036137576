// import React from 'react'

import './index.css'
import hashtag from './hashtag@2x.png'
import facebook from './facebook@2x.png'

// ? Twitter and Instagram not in use for first release due to reduced activity
// import twitter from './twitter@2x.png'
// import instagram from './instagram@2x.png'

const links = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/smithschips',
    icon: facebook,
  },
  // {
  //   name: 'Twitter',
  //   link: '',
  //   icon: twitter,
  // },
  // {
  //   name: 'Instagram',
  //   link: '',
  //   icon: instagram,
  // },
]

function SocialLinks (props) {

  return <>
    <article id="socialLinks">

      <section id="hashtagWrapper">
        <img src={ hashtag } alt="#ShowMeYourToobs" />
      </section>

      <section id="linksWrapper">
        {
          links.map(l => <a className="socialLink"
            key={ `sociallink-${l.name}` }
            href={ l.link }
            rel="noreferrer"
            target="_blank"
          >
            <img src={ l.icon } alt={ l.name } />
          </a>)
        }
      </section>
    </article>
  </>
}

export default SocialLinks
