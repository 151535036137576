// import React from 'react'

import './index.css'

import SemiCircle from '../semi-circle'
import Segment from '../segment'
import Label01 from '../labels/label-01'
import Label02 from '../labels/label-02'
import Label03 from '../labels/label-03'
import Label04 from '../labels/label-04'
import Label05 from '../labels/label-05'
import Label06 from '../labels/label-06'


function Gauge (props) {
  const segmentSettings = [
    {
      angle: 0,
      fill: '#CB2027'
    },
    {
      angle: 30,
      fill: '#141C46'
    },
    {
      angle: 60,
      fill: '#272E68'
    },
    {
      angle: 90,
      fill: '#3053A4'
    },
    {
      angle: 120,
      fill: '#456BB2'
    },
    {
      angle: 150,
      fill: '#5B96D0'
    },
  ]

  return <>
    <div className="gaugeWrapper">
      <div id="segmentWrapper">
        {
          segmentSettings.map((s, i) => <Segment key={ `segment-${i}` } settings={ s } index={ i } />)
        }
      </div>

      <SemiCircle />

      <div id="labelWrapper">
        <Label01 index={ 0 } angle={ segmentSettings[0].angle + 15 }/>
        <Label02 index={ 1 } angle={ segmentSettings[1].angle + 15 }/>
        <Label03 index={ 2 } angle={ segmentSettings[2].angle + 15 }/>
        <Label04 index={ 3 } angle={ segmentSettings[3].angle + 15 }/>
        <Label05 index={ 4 } angle={ segmentSettings[4].angle + 15 }/>
        <Label06 index={ 5 } angle={ segmentSettings[5].angle + 15 }/>
      </div>

    </div>
  </>
}

export default Gauge
