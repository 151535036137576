// import React from 'react'

import './index.css'

// TODO
// Swap out for png to reduce file size

function Title (props) {

  return <>
  {/* <svg
    id="keepOMeterTitle"
    viewBox="0 0 414 61"
    // width="414"
    // height="61"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
  > */}

  <img id="keepOMeterTitle" src="./keep-o-meter@2x.png" alt="Keep-O-Meter" />

  </>
}


export default Title
