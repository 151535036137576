// import React from 'react'

import './index.css'

function Label (props) {
  const { angle, index = 0 } = props

  return <>
    <svg id="label-02"
      className="label"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 741 91.14"
      width="741"
      height="91.14"
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        transformOrigin: 'center right',
        transform: `translateY(50%) rotate(${angle}deg)`,
        animationDelay: `${1700 + (index * 200)}ms`,
      }}
    >
      <g>
        <path d="M17.66,10.76a15.46,15.46,0,0,1,9.65,3.16,16.24,16.24,0,0,1,4.53,5.63,15.42,15.42,0,0,1,1.7,6.88,13.68,13.68,0,0,1-3.36,9.06,15.76,15.76,0,0,1-7.41,4.88,18.43,18.43,0,0,1-5.65.9A17,17,0,0,1,10,39.72a14.28,14.28,0,0,1-6.42-5.65,15,15,0,0,1-2.26-8,14.71,14.71,0,0,1,9.84-14.14A18.15,18.15,0,0,1,17.66,10.76Zm-2,6.17a3.27,3.27,0,0,0-2.86,1.61,5,5,0,0,0-.82,2.86,19.31,19.31,0,0,0,1,5.62,25.53,25.53,0,0,0,2.49,5.66,5.37,5.37,0,0,0,1.65,1.67,3.62,3.62,0,0,0,2,.63,3.24,3.24,0,0,0,2.63-1.23,5,5,0,0,0,1-3.22,21,21,0,0,0-.84-5.61,19.42,19.42,0,0,0-2.17-5.13,7.18,7.18,0,0,0-1.89-2.07A4.07,4.07,0,0,0,15.62,16.93Z"/>
        <path d="M47.31,12.42l-.28,9c0,1.07.3,1.61,1,1.61a1.31,1.31,0,0,0,1-.71,6.45,6.45,0,0,1,5.57-3A7.19,7.19,0,0,1,60,21.38a5,5,0,0,1,1.28,2.19,14.41,14.41,0,0,1,.42,3.63l.2,7.75a3.58,3.58,0,0,0,.26,1.49,4.49,4.49,0,0,0,1.15,1.18A1.46,1.46,0,0,1,64,38.8q0,2.33-7,2.32-6.23,0-6.23-2.17a1.21,1.21,0,0,1,.11-.56,12.74,12.74,0,0,1,.75-1q.9-1.19.9-5.91A12.1,12.1,0,0,0,52,27.28a2.57,2.57,0,0,0-2.54-1.78,2.15,2.15,0,0,0-1.93.95A5.09,5.09,0,0,0,47,29v4.15a29.09,29.09,0,0,0,.25,3.12,5.58,5.58,0,0,0,1,1.35,1.53,1.53,0,0,1,.41,1q0,2.43-6.31,2.43-7.4,0-7.4-2.51a1.46,1.46,0,0,1,.24-.9,6.21,6.21,0,0,1,1.16-1c.55-.39.92-1.43,1.13-3.13a78.05,78.05,0,0,0,.31-8.43,52.82,52.82,0,0,0-.26-6.4,2.09,2.09,0,0,0-.31-1,2.3,2.3,0,0,0-.85-.63c-.89-.46-1.33-1-1.33-1.59a2,2,0,0,1,1-1.74,14.49,14.49,0,0,1,3.87-1.44,24.32,24.32,0,0,1,6.16-1.08C46.93,11.34,47.33,11.7,47.31,12.42Z"/>
        <path d="M101.34,13.08V32.7a9.45,9.45,0,0,0,.14,2.15,1.84,1.84,0,0,0,.78.92,3.82,3.82,0,0,1,1,.95,1.73,1.73,0,0,1,.27,1q0,1.72-3.46,2.62a25.05,25.05,0,0,1-6.23.8,1.37,1.37,0,0,1-.89-.2,1.71,1.71,0,0,1-.27-1c-.07-.73-.33-1.1-.77-1.1a2.33,2.33,0,0,0-1.14.65,7.54,7.54,0,0,1-4.88,1.78,8.56,8.56,0,0,1-6-2.47,12.19,12.19,0,0,1-2.54-3.42,11,11,0,0,1-1.28-5.09,11.23,11.23,0,0,1,1.67-5.84,10.52,10.52,0,0,1,4.21-4,9.56,9.56,0,0,1,4.41-1.16,8.92,8.92,0,0,1,4.46,1.12,1.28,1.28,0,0,0,.52.17c.46,0,.69-.61.69-1.85a1.75,1.75,0,0,0-.38-1.21,3,3,0,0,0-1.34-.72C89.46,16.51,89,16,89,15.3c0-1.05,1-1.86,3.12-2.45a34.92,34.92,0,0,1,7.37-1.53C100.72,11.32,101.34,11.91,101.34,13.08ZM89.67,23.89a2.46,2.46,0,0,0-2.21,1.48,10.71,10.71,0,0,0-1.1,5.2A7.55,7.55,0,0,0,87.42,35a2.37,2.37,0,0,0,2,1.07,2.15,2.15,0,0,0,2-1.62,15.69,15.69,0,0,0,.63-5.23q0-3.32-.75-4.41A2,2,0,0,0,89.67,23.89Z"/>
        <path d="M124.69,31.07h-10.1c-.44,0-.66.19-.66.58a3.45,3.45,0,0,0,1.51,2.63,5.08,5.08,0,0,0,3.26,1.23,7.74,7.74,0,0,0,2.28-.39,7.9,7.9,0,0,0,2.08-1,2.2,2.2,0,0,1,1.16-.52,1.46,1.46,0,0,1,1.09.69,2.28,2.28,0,0,1,.54,1.42,3.9,3.9,0,0,1-1.93,3A12.79,12.79,0,0,1,116,41.27a11.17,11.17,0,0,1-7.56-2.62,11,11,0,0,1-3.72-8.23,10.44,10.44,0,0,1,2.19-6.49,11.47,11.47,0,0,1,4.82-3.71,12,12,0,0,1,4.74-.93,10.67,10.67,0,0,1,6.94,2.32,9.25,9.25,0,0,1,2.32,2.75,7.15,7.15,0,0,1,1,3.53,4.06,4.06,0,0,1-.58,2.28A1.73,1.73,0,0,1,124.69,31.07Zm-9.54-4h2.17c.92,0,1.38-.42,1.38-1.27a2.73,2.73,0,0,0-.67-1.89,2.22,2.22,0,0,0-1.74-.73,2.19,2.19,0,0,0-1.89.95,3,3,0,0,0-.65,1.78,1.1,1.1,0,0,0,.32.9A1.7,1.7,0,0,0,115.15,27.05Z"/>
        <path d="M140.44,19.27a10.46,10.46,0,0,1,6.33,1.89,5.64,5.64,0,0,1,1.93,2.31,9.57,9.57,0,0,1,.55,3.58l-.05,3.2a12.4,12.4,0,0,0,.47,4.32,1.49,1.49,0,0,0,.46.6,3,3,0,0,0,.9.24.78.78,0,0,1,.69.9,3.7,3.7,0,0,1-.83,2.15,6.92,6.92,0,0,1-2.14,1.86,6.57,6.57,0,0,1-3.37,1,4.44,4.44,0,0,1-3.57-1.55,1.21,1.21,0,0,0-.84-.56,2.17,2.17,0,0,0-1.14.54A8,8,0,0,1,135,41.27a7.88,7.88,0,0,1-4.56-1.2A5.59,5.59,0,0,1,128.6,38a5.74,5.74,0,0,1-.67-2.67,5.32,5.32,0,0,1,1.81-3.95q2.81-2.72,8.42-2.73a3,3,0,0,0,1.57-.25c.21-.16.32-.55.32-1.15a8.52,8.52,0,0,0-.47-3.3,1.6,1.6,0,0,0-1.55-1,1.84,1.84,0,0,0-1.15.39,7.4,7.4,0,0,0-1.26,1.42q-1.91,2.73-4,2.73a2.4,2.4,0,0,1-1.72-.63,2.14,2.14,0,0,1-.67-1.63,3.3,3.3,0,0,1,.83-2.06,7.31,7.31,0,0,1,2.25-1.81A17.15,17.15,0,0,1,140.44,19.27ZM139,31.71a2.18,2.18,0,0,0-1.65.77,2.71,2.71,0,0,0-.69,1.85,2.65,2.65,0,0,0,.49,1.67,1.59,1.59,0,0,0,1.31.63c1.15,0,1.72-1,1.72-2.9a3.34,3.34,0,0,0-.25-1.59A1,1,0,0,0,139,31.71Z"/>
        <path d="M163.14,19.42c.76,0,1.23.11,1.39.32a6,6,0,0,1,.44,2.07c.07.63.29.94.67.94s.55-.21.81-.62a5.17,5.17,0,0,1,4.49-2.69,4.3,4.3,0,0,1,3.2,1.27,5,5,0,0,1,1,1.6,5.07,5.07,0,0,1,.39,1.88,5.13,5.13,0,0,1-.47,2.07A5.19,5.19,0,0,1,173.82,28a4.69,4.69,0,0,1-3.37,1.32,3.94,3.94,0,0,1-1.84-.39,9.33,9.33,0,0,1-1.94-1.57,1.26,1.26,0,0,0-.84-.45.79.79,0,0,0-.79.68,13.85,13.85,0,0,0-.18,2.84,26.54,26.54,0,0,0,.24,4.43,1.71,1.71,0,0,0,.42.89,3.25,3.25,0,0,0,1.15.5c1.29.39,1.93,1.06,1.93,2a2.19,2.19,0,0,1-.62,1.54,3.06,3.06,0,0,1-1.66.87,31.49,31.49,0,0,1-6,.43,18.85,18.85,0,0,1-5.78-.67c-1.18-.4-1.77-1.09-1.77-2.08a2.48,2.48,0,0,1,1.29-2,3.21,3.21,0,0,0,.86-.74,2.11,2.11,0,0,0,.33-.91,51.41,51.41,0,0,0,.34-7.24,3.14,3.14,0,0,0-.22-1.39,1.53,1.53,0,0,0-.86-.67c-1-.41-1.52-1-1.52-1.74A2.16,2.16,0,0,1,154,21.9a13,13,0,0,1,3.53-1.47A21.06,21.06,0,0,1,163.14,19.42Z"/>
        <path d="M179.77,30.85a4.7,4.7,0,0,1,2.09.51,4.87,4.87,0,0,1,1.71,1.36,8,8,0,0,1,1.64,5.16,13.43,13.43,0,0,1-.64,3.85,16.81,16.81,0,0,1-1.66,3.88q-1.69,2.84-3.55,2.84a2.4,2.4,0,0,1-1.47-.5,1.43,1.43,0,0,1-.65-1.14,5,5,0,0,1,.81-1.65,10.09,10.09,0,0,0,.87-1.74A4.57,4.57,0,0,0,179.3,42a1,1,0,0,0-.73-1.05,6.93,6.93,0,0,1-2-1.06,5,5,0,0,1-1.81-4A4.74,4.74,0,0,1,176,32.57,4.94,4.94,0,0,1,179.77,30.85Z"/>
        <path d="M210.47,12.42l-.28,9c0,1.07.3,1.61,1,1.61a1.35,1.35,0,0,0,1-.71,6.44,6.44,0,0,1,5.56-3,7.23,7.23,0,0,1,5.44,2.09,5.15,5.15,0,0,1,1.28,2.19,14.41,14.41,0,0,1,.42,3.63l.19,7.75a3.4,3.4,0,0,0,.27,1.49,4.33,4.33,0,0,0,1.15,1.18,1.48,1.48,0,0,1,.62,1.18q0,2.33-7,2.32c-4.16,0-6.23-.72-6.23-2.17a1.09,1.09,0,0,1,.11-.56,12.74,12.74,0,0,1,.75-1q.9-1.19.9-5.91a11.82,11.82,0,0,0-.54-4.17,2.55,2.55,0,0,0-2.53-1.78,2.17,2.17,0,0,0-1.94.95,5.09,5.09,0,0,0-.45,2.57v4.15a27.67,27.67,0,0,0,.26,3.12,5,5,0,0,0,1,1.35,1.53,1.53,0,0,1,.41,1q0,2.43-6.32,2.43-7.4,0-7.39-2.51a1.46,1.46,0,0,1,.24-.9,5.87,5.87,0,0,1,1.16-1c.54-.39.92-1.43,1.13-3.13A80.81,80.81,0,0,0,201,25.2a52.82,52.82,0,0,0-.26-6.4,2.21,2.21,0,0,0-.31-1,2.39,2.39,0,0,0-.85-.63c-.89-.46-1.33-1-1.33-1.59a2,2,0,0,1,1-1.74,14.53,14.53,0,0,1,3.86-1.44,24.39,24.39,0,0,1,6.17-1.08Q210.52,11.34,210.47,12.42Z"/>
        <path d="M248.24,31.07h-10.1c-.44,0-.66.19-.66.58A3.45,3.45,0,0,0,239,34.28a5.06,5.06,0,0,0,3.26,1.23,7.74,7.74,0,0,0,2.28-.39,7.9,7.9,0,0,0,2.08-1,2.2,2.2,0,0,1,1.16-.52,1.46,1.46,0,0,1,1.09.69,2.28,2.28,0,0,1,.54,1.42,3.92,3.92,0,0,1-1.93,3,12.79,12.79,0,0,1-7.89,2.56A11.17,11.17,0,0,1,232,38.65a10.63,10.63,0,0,1-2.45-3.12,10.81,10.81,0,0,1-1.27-5.11,10.44,10.44,0,0,1,2.19-6.49,11.47,11.47,0,0,1,4.82-3.71,12,12,0,0,1,4.74-.93A10.67,10.67,0,0,1,247,21.61a9.25,9.25,0,0,1,2.32,2.75,7.15,7.15,0,0,1,1,3.53,4.06,4.06,0,0,1-.58,2.28A1.73,1.73,0,0,1,248.24,31.07Zm-9.54-4h2.17c.92,0,1.38-.42,1.38-1.27a2.73,2.73,0,0,0-.67-1.89,2.22,2.22,0,0,0-1.74-.73,2.19,2.19,0,0,0-1.89.95,3,3,0,0,0-.65,1.78,1.1,1.1,0,0,0,.32.9A1.7,1.7,0,0,0,238.7,27.05Z"/>
        <path d="M261.75,19.42c.76,0,1.22.11,1.39.32a6,6,0,0,1,.44,2.07c.07.63.29.94.66.94s.56-.21.82-.62a5.17,5.17,0,0,1,4.49-2.69,4.32,4.32,0,0,1,3.2,1.27,5,5,0,0,1,1,1.6,5.07,5.07,0,0,1,.39,1.88,5.62,5.62,0,0,1-1.7,3.82,4.69,4.69,0,0,1-3.37,1.32,3.92,3.92,0,0,1-1.84-.39,9.33,9.33,0,0,1-1.94-1.57,1.28,1.28,0,0,0-.84-.45.79.79,0,0,0-.79.68,13.85,13.85,0,0,0-.18,2.84,26.54,26.54,0,0,0,.24,4.43,1.71,1.71,0,0,0,.42.89,3.25,3.25,0,0,0,1.15.5c1.28.39,1.93,1.06,1.93,2a2.19,2.19,0,0,1-.62,1.54,3.06,3.06,0,0,1-1.66.87,31.54,31.54,0,0,1-6.06.43,18.94,18.94,0,0,1-5.78-.67,2.17,2.17,0,0,1-1.76-2.08,2.48,2.48,0,0,1,1.29-2,3.21,3.21,0,0,0,.86-.74,2,2,0,0,0,.32-.91,49.44,49.44,0,0,0,.35-7.24,3.14,3.14,0,0,0-.22-1.39,1.53,1.53,0,0,0-.86-.67c-1-.41-1.52-1-1.52-1.74a2.16,2.16,0,0,1,1-1.78,13.07,13.07,0,0,1,3.52-1.47A21.19,21.19,0,0,1,261.75,19.42Z"/>
        <path d="M294.62,31.07h-10.1c-.44,0-.66.19-.66.58a3.45,3.45,0,0,0,1.51,2.63,5.08,5.08,0,0,0,3.26,1.23,7.8,7.8,0,0,0,2.29-.39,8,8,0,0,0,2.07-1,2.23,2.23,0,0,1,1.16-.52,1.47,1.47,0,0,1,1.1.69,2.32,2.32,0,0,1,.53,1.42,3.9,3.9,0,0,1-1.93,3A12.79,12.79,0,0,1,286,41.27a11.2,11.2,0,0,1-7.56-2.62A10.8,10.8,0,0,1,276,35.53a10.91,10.91,0,0,1-1.26-5.11,10.44,10.44,0,0,1,2.19-6.49,11.44,11.44,0,0,1,4.81-3.71,12,12,0,0,1,4.75-.93,10.71,10.71,0,0,1,6.94,2.32,9.55,9.55,0,0,1,2.32,2.75,7.26,7.26,0,0,1,1,3.53,4.14,4.14,0,0,1-.58,2.28A1.74,1.74,0,0,1,294.62,31.07Zm-9.54-4h2.17c.92,0,1.38-.42,1.38-1.27a2.68,2.68,0,0,0-.67-1.89,2.22,2.22,0,0,0-1.74-.73,2.19,2.19,0,0,0-1.89.95,3,3,0,0,0-.64,1.78,1.1,1.1,0,0,0,.31.9A1.74,1.74,0,0,0,285.08,27.05Z"/>
        <path d="M25.82,74.74l-2.21-3.55a15.11,15.11,0,0,0-1-1.44.81.81,0,0,0-.59-.23c-.52,0-1.05.47-1.59,1.42l-3.59,6.18c-.68,1.19-1.46,1.79-2.32,1.79a2.29,2.29,0,0,1-1.46-.55,7.49,7.49,0,0,1-1.44-1.86L5.18,65.78a16.77,16.77,0,0,0-2.52-3.39,4.59,4.59,0,0,0-1.56-.67,1.53,1.53,0,0,1-.79-.59,1.63,1.63,0,0,1-.31-1c0-1.1.85-1.87,2.56-2.32a23.15,23.15,0,0,1,5.63-.64c4.56,0,6.85.85,6.85,2.55a3,3,0,0,1-.67,1.44.79.79,0,0,0-.13.41,7.78,7.78,0,0,0,1,2.34c.75,1.42,1.39,2.13,1.94,2.13q.46,0,1.2-1.08a3.35,3.35,0,0,0,.73-1.74,1.67,1.67,0,0,0-.32-1.06,10.13,10.13,0,0,0-1.53-1.26,1.49,1.49,0,0,1-.73-1.22c0-1.12,1.15-1.85,3.44-2.19a31.84,31.84,0,0,1,4.9-.32,14.54,14.54,0,0,1,4.68.54A1.88,1.88,0,0,1,31,59.51a1.62,1.62,0,0,1-.17.79,7.29,7.29,0,0,1-.93,1.08,1,1,0,0,0-.23.66,7.39,7.39,0,0,0,1,2.45c.57,1.09,1.09,1.63,1.56,1.63s1.17-.69,2-2.08A6.11,6.11,0,0,0,35,62a2.52,2.52,0,0,0-.69-1.27,2,2,0,0,1-.49-1.31,1.54,1.54,0,0,1,1-1.55A10.73,10.73,0,0,1,39,57.21a16.68,16.68,0,0,1,3.25.28,2.49,2.49,0,0,1,1.33.7,1.68,1.68,0,0,1,.51,1.21,1.43,1.43,0,0,1-.6,1.16,9.25,9.25,0,0,1-1.5.6,3.37,3.37,0,0,0-1.31.86,9.28,9.28,0,0,0-1.25,2l-5.37,10.4Q31.69,79,29.88,79a2.56,2.56,0,0,1-1.7-1A20.78,20.78,0,0,1,25.82,74.74Z"/>
        <path d="M63.23,68.83H53.13c-.45,0-.67.19-.67.58A3.45,3.45,0,0,0,54,72a5,5,0,0,0,3.25,1.24,7.52,7.52,0,0,0,2.29-.4,7.12,7.12,0,0,0,2.07-1,2.32,2.32,0,0,1,1.16-.51,1.49,1.49,0,0,1,1.1.68,2.3,2.3,0,0,1,.54,1.42,3.93,3.93,0,0,1-1.94,3A12.82,12.82,0,0,1,54.57,79,11.17,11.17,0,0,1,47,76.41a10.59,10.59,0,0,1-2.45-3.11,10.83,10.83,0,0,1-1.27-5.11,10.44,10.44,0,0,1,2.19-6.49A11.55,11.55,0,0,1,50.29,58,12.22,12.22,0,0,1,55,57.06,10.65,10.65,0,0,1,62,59.38a9.27,9.27,0,0,1,2.32,2.75,7.22,7.22,0,0,1,1,3.52,4.12,4.12,0,0,1-.58,2.29A1.75,1.75,0,0,1,63.23,68.83Zm-9.54-4h2.17c.91,0,1.37-.42,1.37-1.26a2.69,2.69,0,0,0-.66-1.89,2.26,2.26,0,0,0-1.74-.74,2.22,2.22,0,0,0-1.9.95,3,3,0,0,0-.64,1.78,1.1,1.1,0,0,0,.31.9A1.69,1.69,0,0,0,53.69,64.81Z"/>
        <path d="M100.54,55.9a1.16,1.16,0,0,1,.95.61,2.53,2.53,0,0,1,.41,1.43,4.73,4.73,0,0,1-.55,2A5.66,5.66,0,0,1,100,61.72c-.33.29-.5.52-.5.69s0,.21,0,.51a6.33,6.33,0,0,1,.06.82A5.87,5.87,0,0,1,97.92,68a10.42,10.42,0,0,1-4,2.4,16.15,16.15,0,0,1-5.36.86,12.85,12.85,0,0,1-1.4-.08,6.74,6.74,0,0,0-.86-.07,1,1,0,0,0-1.14,1.14,1.28,1.28,0,0,0,.74,1.15,4.11,4.11,0,0,0,2,.42,19.64,19.64,0,0,0,2.54-.24,41.16,41.16,0,0,1,5.2-.49A7.79,7.79,0,0,1,99.6,74a4.81,4.81,0,0,1,1.77,1.81,4.93,4.93,0,0,1,.65,2.49,6.77,6.77,0,0,1-1.59,4.21A11.06,11.06,0,0,1,94.87,86a22.36,22.36,0,0,1-6.66.9,17.58,17.58,0,0,1-7.73-1.46,5.93,5.93,0,0,1-2.19-1.8,3.87,3.87,0,0,1-.82-2.32,2.76,2.76,0,0,1,.46-1.55,2.63,2.63,0,0,1,1.17-1c.43-.17.65-.36.65-.56s-.18-.39-.54-.69a4,4,0,0,1-1.72-3,3.57,3.57,0,0,1,.83-2.13,5.62,5.62,0,0,1,2.05-1.7q.51-.26.51-.51c0-.2-.21-.5-.64-.91A6.28,6.28,0,0,1,78,64.55a5.28,5.28,0,0,1,.78-2.74A6.94,6.94,0,0,1,81,59.57a13.77,13.77,0,0,1,7.67-2.25,18.93,18.93,0,0,1,3.74.42,16.62,16.62,0,0,0,2.68.39,7.44,7.44,0,0,0,4.67-1.85A1.6,1.6,0,0,1,100.54,55.9Zm-16.35,24c-.36,0-.53.21-.53.63a2.32,2.32,0,0,0,1.37,2,7.21,7.21,0,0,0,3.57.78,7.76,7.76,0,0,0,3.29-.56,1.72,1.72,0,0,0,1.2-1.56,1.16,1.16,0,0,0-.45-.92,1.72,1.72,0,0,0-1.12-.37,9.53,9.53,0,0,0-1,.08,16.86,16.86,0,0,1-2.69.2,20.19,20.19,0,0,1-2.1-.11,8.94,8.94,0,0,1-1.22-.17A2,2,0,0,0,84.19,79.89Zm4.69-19.55a2.11,2.11,0,0,0-1.73.82,3.36,3.36,0,0,0-.66,2.17,8.3,8.3,0,0,0,.71,3.72,2.11,2.11,0,0,0,1.87,1.39,2.14,2.14,0,0,0,1.8-.87,3.69,3.69,0,0,0,.67-2.33,7.46,7.46,0,0,0-.7-3.57A2.24,2.24,0,0,0,88.88,60.34Z"/>
        <path d="M116.44,57a11.89,11.89,0,0,1,6.9,2,11,11,0,0,1,3.41,3.8A10.53,10.53,0,0,1,128.08,68q0,4.67-4.27,7.93A15,15,0,0,1,114.46,79a11.79,11.79,0,0,1-8.31-2.92,9.56,9.56,0,0,1-3.09-7.39,10.4,10.4,0,0,1,3.78-8.29A14.23,14.23,0,0,1,116.44,57Zm-1.72,5a1.84,1.84,0,0,0-1.51.7,2.82,2.82,0,0,0-.57,1.85,17.73,17.73,0,0,0,.48,3.52A27,27,0,0,0,114.27,72a2.29,2.29,0,0,0,2.17,1.76c1.27,0,1.91-.81,1.91-2.43a20.29,20.29,0,0,0-.48-4.1,16.75,16.75,0,0,0-1.17-3.74A2.28,2.28,0,0,0,114.72,62Z"/>
        <path d="M152.9,57a10.46,10.46,0,0,1,6.33,1.89,5.58,5.58,0,0,1,1.93,2.31,9.51,9.51,0,0,1,.55,3.57l-.05,3.2a12.4,12.4,0,0,0,.47,4.32,1.36,1.36,0,0,0,.46.6,2.69,2.69,0,0,0,.9.24.78.78,0,0,1,.69.9,3.65,3.65,0,0,1-.83,2.15,6.82,6.82,0,0,1-2.14,1.87,6.56,6.56,0,0,1-3.37.94,4.44,4.44,0,0,1-3.57-1.54,1.21,1.21,0,0,0-.84-.56,2.17,2.17,0,0,0-1.14.54A8.06,8.06,0,0,1,147.42,79a7.88,7.88,0,0,1-4.56-1.2,5.64,5.64,0,0,1-1.8-2.07,5.75,5.75,0,0,1-.67-2.68,5.33,5.33,0,0,1,1.81-4q2.81-2.7,8.42-2.73a3.16,3.16,0,0,0,1.57-.24,1.49,1.49,0,0,0,.32-1.15,8.52,8.52,0,0,0-.47-3.3,1.6,1.6,0,0,0-1.55-1,1.89,1.89,0,0,0-1.15.38,7.73,7.73,0,0,0-1.26,1.42q-1.91,2.73-4,2.73a2.44,2.44,0,0,1-1.72-.62A2.15,2.15,0,0,1,141.7,63a3.33,3.33,0,0,1,.83-2.07,7.28,7.28,0,0,1,2.25-1.8A17,17,0,0,1,152.9,57Zm-1.44,12.43a2.16,2.16,0,0,0-1.65.78,2.67,2.67,0,0,0-.69,1.85,2.62,2.62,0,0,0,.49,1.66,1.59,1.59,0,0,0,1.31.63c1.15,0,1.72-1,1.72-2.9a3.4,3.4,0,0,0-.25-1.59A1,1,0,0,0,151.46,69.47Z"/>
        <path d="M188.41,55.9a1.13,1.13,0,0,1,.94.61,2.45,2.45,0,0,1,.41,1.43,4.58,4.58,0,0,1-.55,2,5.37,5.37,0,0,1-1.32,1.78c-.33.29-.49.52-.49.69s0,.21,0,.51a5,5,0,0,1,.07.82,5.91,5.91,0,0,1-1.72,4.3,10.39,10.39,0,0,1-4,2.4,16.07,16.07,0,0,1-5.36.86,12.66,12.66,0,0,1-1.39-.08,6.87,6.87,0,0,0-.86-.07,1,1,0,0,0-1.14,1.14,1.28,1.28,0,0,0,.74,1.15,4.06,4.06,0,0,0,2,.42,19.37,19.37,0,0,0,2.53-.24,41.48,41.48,0,0,1,5.2-.49,7.76,7.76,0,0,1,3.93.83,4.76,4.76,0,0,1,1.78,1.81,5,5,0,0,1,.65,2.49,6.77,6.77,0,0,1-1.59,4.21A11.06,11.06,0,0,1,182.74,86a22.36,22.36,0,0,1-6.66.9,17.66,17.66,0,0,1-7.74-1.46,6.1,6.1,0,0,1-2.19-1.8,3.92,3.92,0,0,1-.81-2.32,2.76,2.76,0,0,1,.46-1.55,2.63,2.63,0,0,1,1.17-1c.43-.17.64-.36.64-.56s-.18-.39-.53-.69a4,4,0,0,1-1.72-3,3.55,3.55,0,0,1,.82-2.13,5.74,5.74,0,0,1,2.06-1.7q.51-.26.51-.51c0-.2-.21-.5-.64-.91a6.29,6.29,0,0,1-2.22-4.77,5.29,5.29,0,0,1,.79-2.74,6.94,6.94,0,0,1,2.18-2.24,13.77,13.77,0,0,1,7.67-2.25,18.93,18.93,0,0,1,3.74.42,16.47,16.47,0,0,0,2.68.39,7.41,7.41,0,0,0,4.66-1.85A1.62,1.62,0,0,1,188.41,55.9Zm-16.35,24q-.54,0-.54.63a2.33,2.33,0,0,0,1.38,2,7.14,7.14,0,0,0,3.56.78,7.78,7.78,0,0,0,3.3-.56A1.71,1.71,0,0,0,181,81.23a1.16,1.16,0,0,0-.45-.92,1.67,1.67,0,0,0-1.11-.37,9.53,9.53,0,0,0-1,.08,16.86,16.86,0,0,1-2.69.2,20.19,20.19,0,0,1-2.1-.11,9.36,9.36,0,0,1-1.23-.17A1.81,1.81,0,0,0,172.06,79.89Zm4.68-19.55a2.14,2.14,0,0,0-1.73.82,3.42,3.42,0,0,0-.65,2.17,8.16,8.16,0,0,0,.71,3.72,2.1,2.1,0,0,0,1.87,1.39,2.14,2.14,0,0,0,1.8-.87,3.69,3.69,0,0,0,.67-2.33,7.58,7.58,0,0,0-.7-3.57A2.26,2.26,0,0,0,176.74,60.34Z"/>
        <path d="M203.12,57a10.47,10.47,0,0,1,6.34,1.89,5.48,5.48,0,0,1,1.92,2.31,9.31,9.31,0,0,1,.55,3.57l0,3.2a12.4,12.4,0,0,0,.47,4.32,1.43,1.43,0,0,0,.45.6,2.86,2.86,0,0,0,.91.24c.45.07.68.37.68.9a3.63,3.63,0,0,1-.82,2.15,6.93,6.93,0,0,1-2.14,1.87,6.58,6.58,0,0,1-3.37.94,4.42,4.42,0,0,1-3.57-1.54,1.21,1.21,0,0,0-.84-.56,2.17,2.17,0,0,0-1.14.54A8.09,8.09,0,0,1,197.65,79a7.88,7.88,0,0,1-4.56-1.2,5.9,5.9,0,0,1-2.47-4.75,5.3,5.3,0,0,1,1.81-4q2.81-2.7,8.42-2.73a3.16,3.16,0,0,0,1.57-.24,1.49,1.49,0,0,0,.32-1.15,8.29,8.29,0,0,0-.48-3.3,1.59,1.59,0,0,0-1.54-1,1.89,1.89,0,0,0-1.15.38,7.38,7.38,0,0,0-1.26,1.42q-1.91,2.73-4,2.73a2.42,2.42,0,0,1-1.72-.62,2.12,2.12,0,0,1-.67-1.63,3.33,3.33,0,0,1,.83-2.07A7.36,7.36,0,0,1,195,59.1,17.07,17.07,0,0,1,203.12,57Zm-1.44,12.43a2.14,2.14,0,0,0-1.64.78,2.68,2.68,0,0,0-.7,1.85,2.63,2.63,0,0,0,.5,1.66,1.58,1.58,0,0,0,1.31.63c1.14,0,1.72-1,1.72-2.9a3.54,3.54,0,0,0-.25-1.59A1.06,1.06,0,0,0,201.68,69.47Z"/>
        <path d="M227.74,58.54V71a6.38,6.38,0,0,0,.27,2.17,2.9,2.9,0,0,0,1.09,1.27,3.67,3.67,0,0,1,.89.8,1.51,1.51,0,0,1,.22.85q0,2.77-7,2.77a23.75,23.75,0,0,1-6-.6,2.39,2.39,0,0,1-1.22-.75,1.82,1.82,0,0,1-.46-1.22,1.51,1.51,0,0,1,.24-.91,6.83,6.83,0,0,1,1.2-1,2.77,2.77,0,0,0,.95-1.61,15.19,15.19,0,0,0,.27-3.33c0-.47,0-1.36-.09-2.65s-.11-2-.14-2.27a1.8,1.8,0,0,0-1.2-1.35,1.87,1.87,0,0,1-1.34-1.72c0-.9.58-1.58,1.74-2a39.48,39.48,0,0,1,5.16-1.57,20,20,0,0,1,4.25-.64C227.35,57.21,227.74,57.65,227.74,58.54Zm-4.25-9.41a5.46,5.46,0,0,1,3,.7,2.12,2.12,0,0,1,1.09,1.88,2.94,2.94,0,0,1-1.65,2.62,12.38,12.38,0,0,1-6.34,1.52,4.5,4.5,0,0,1-2.49-.6,2.4,2.4,0,0,1-1.25-2.15c0-1.23.88-2.19,2.64-2.9A13.71,13.71,0,0,1,223.49,49.13Z"/>
        <path d="M257.54,65l.3,7.57a3,3,0,0,0,.27,1.26,10.12,10.12,0,0,0,1.32,1.57,1.34,1.34,0,0,1,.32,1c0,.94-.56,1.6-1.69,2a22.35,22.35,0,0,1-6.06.55,12,12,0,0,1-4.19-.51,1.81,1.81,0,0,1-1.27-1.74,1.2,1.2,0,0,1,.13-.64,13,13,0,0,1,.86-1c.57-.61.86-2.61.86-6a10.48,10.48,0,0,0-.69-4.38,2.08,2.08,0,0,0-2.06-1.14,2.11,2.11,0,0,0-2,.92,7.45,7.45,0,0,0-.53,3.35V72a6.2,6.2,0,0,0,.16,1.74,10.17,10.17,0,0,0,1.06,1.82,1.67,1.67,0,0,1,.24.89c0,.84-.59,1.46-1.76,1.86a18.07,18.07,0,0,1-5.5.61c-4,0-6-.83-6-2.47a1.34,1.34,0,0,1,.19-.79,3.83,3.83,0,0,1,.87-.76,1.81,1.81,0,0,0,.71-.94,17.69,17.69,0,0,0,.27-2.58c.1-1.64.15-3.35.15-5.14a5.32,5.32,0,0,0-.22-1.94,3.46,3.46,0,0,0-1.3-1.13,1.66,1.66,0,0,1-.92-1.42,1.69,1.69,0,0,1,.75-1.41A14.52,14.52,0,0,1,234.94,59c1.38-.49,2.7-.9,4-1.24a12.76,12.76,0,0,1,2.62-.5,1.42,1.42,0,0,1,1.08.47,1.57,1.57,0,0,1,.44,1.12s0,.22,0,.53c0,.12,0,.22,0,.31,0,.68.29,1,.86,1,.34,0,.71-.28,1.09-.82a6.13,6.13,0,0,1,5.37-2.66A7.68,7.68,0,0,1,255,58.6,5.54,5.54,0,0,1,256.88,61,13.32,13.32,0,0,1,257.54,65Z"/>
      </g>
    </svg>

  </>
}

export default Label
