import './index.css'

function Tagline (props) {

  return <>
   <svg id="tagline"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 75"
      width="1000"
      height="75"
    >
      <g>
        <path d="M9.36,1.66H31.22c4.43,0,7.65-.09,9.68-.28A31.15,31.15,0,0,0,46.84.26,5.06,5.06,0,0,1,48.24,0a4.81,4.81,0,0,1,3.91,2.38A15.52,15.52,0,0,1,55,11.74a7.23,7.23,0,0,1-1.17,4.36,3.78,3.78,0,0,1-3.21,1.6,3.66,3.66,0,0,1-2.19-.58,10.64,10.64,0,0,1-2.11-2.57,9.09,9.09,0,0,0-4.74-3.81q-2.88-1-9.42-1c-3.26,0-5.25.59-6,1.75A5.64,5.64,0,0,0,25.5,14q-.11,1.58-.1,8c0,1.7.28,2.76.85,3.17s2.05.61,4.46.61a3.76,3.76,0,0,0,2.6-.74,4.17,4.17,0,0,0,1-2.66A18.27,18.27,0,0,1,35,19.08a3.52,3.52,0,0,1,1.17-1.6,3.09,3.09,0,0,1,2.08-.76,3.63,3.63,0,0,1,3,1.66,11.82,11.82,0,0,1,1.51,4.36,33.73,33.73,0,0,1,.61,6.53,25.23,25.23,0,0,1-2.08,11,3.7,3.7,0,0,1-3.49,2.34,2.34,2.34,0,0,1-2-.74,7.37,7.37,0,0,1-.85-3.08c-.42-3.21-2.3-4.81-5.61-4.81a2.76,2.76,0,0,0-2.62,1.21,9.85,9.85,0,0,0-.7,4.49c0,1.33.09,3.05.28,5.17a26.32,26.32,0,0,0,.57,4.19,2.64,2.64,0,0,0,2.11,1.76,24.44,24.44,0,0,0,5.89.49c4.25,0,7.46-.54,9.61-1.64a12.1,12.1,0,0,0,5.27-5.93,11.4,11.4,0,0,1,2.15-3.32,3.79,3.79,0,0,1,5.45.7A6.32,6.32,0,0,1,58.45,45a20.43,20.43,0,0,1-1.32,6.38,31.73,31.73,0,0,1-3.23,6.8,8.83,8.83,0,0,1-1.62,1.94,2.6,2.6,0,0,1-1.62.49,15.69,15.69,0,0,1-2.34-.26,49.19,49.19,0,0,0-7.27-.64H5a5.21,5.21,0,0,1-3.36-.95,3.25,3.25,0,0,1-1.19-2.7,2.59,2.59,0,0,1,.51-1.79,8.17,8.17,0,0,1,2.34-1.4,4.63,4.63,0,0,0,1.89-1.37,6.05,6.05,0,0,0,1-2.38,85,85,0,0,0,1.4-17.31q0-16.42-2-20.72a3.15,3.15,0,0,0-.94-1.19A9.22,9.22,0,0,0,2.43,9.1,3.09,3.09,0,0,1,0,5.87,3.86,3.86,0,0,1,.81,3.45,3.81,3.81,0,0,1,2.94,2,35,35,0,0,1,9.36,1.66Z"/>
        <path d="M85,17.31A20.72,20.72,0,0,1,97.5,21.06a11.11,11.11,0,0,1,3.81,4.57,18.79,18.79,0,0,1,1.08,7.08l-.08,6.34q0,6.6.93,8.55a2.85,2.85,0,0,0,.9,1.19,5.63,5.63,0,0,0,1.78.47c.91.14,1.36.74,1.36,1.79a7.2,7.2,0,0,1-1.63,4.25,13.74,13.74,0,0,1-4.24,3.7,13,13,0,0,1-6.68,1.87,8.78,8.78,0,0,1-7.06-3.06A2.37,2.37,0,0,0,86,56.7a4.26,4.26,0,0,0-2.25,1.07,16,16,0,0,1-9.66,3.1,15.5,15.5,0,0,1-9-2.38,11.62,11.62,0,0,1-4.9-9.4,10.51,10.51,0,0,1,3.58-7.83q5.57-5.35,16.67-5.4a6,6,0,0,0,3.11-.49c.42-.33.64-1.08.64-2.28a16.64,16.64,0,0,0-.94-6.52,3.17,3.17,0,0,0-3.06-2,3.67,3.67,0,0,0-2.28.77,14.14,14.14,0,0,0-2.49,2.81q-3.78,5.4-7.91,5.4a4.78,4.78,0,0,1-3.4-1.23,4.24,4.24,0,0,1-1.32-3.24A6.48,6.48,0,0,1,64.43,25a14.46,14.46,0,0,1,4.44-3.57A33.77,33.77,0,0,1,85,17.31ZM82.1,41.94a4.24,4.24,0,0,0-3.25,1.53,5.75,5.75,0,0,0-.41,7A3.12,3.12,0,0,0,81,51.68q3.4,0,3.4-5.74A6.76,6.76,0,0,0,84,42.79,2.09,2.09,0,0,0,82.1,41.94Z"/>
        <path d="M135.32,28.5V42.88a6.59,6.59,0,0,0,1.25,4.17,3.89,3.89,0,0,0,3.21,1.61,9.69,9.69,0,0,0,4.38-1.53,2.52,2.52,0,0,1,1.28-.38A2.35,2.35,0,0,1,147.31,48a4.63,4.63,0,0,1,.85,2.68q0,2.64-2.89,5.07a21.82,21.82,0,0,1-14.63,5.14q-6.81,0-10.94-3.85a13.21,13.21,0,0,1-4.12-10.14V27.22a1.92,1.92,0,0,0-.24-1.21,2,2,0,0,0-1.21-.23h-4.38c-.82,0-1.35-.14-1.57-.41a3.31,3.31,0,0,1-.34-1.89V21.61a2.12,2.12,0,0,1,1-1.87L128.3,7.49a3.69,3.69,0,0,1,1.78-.39h3.45a1.69,1.69,0,0,1,1.42.49,3.55,3.55,0,0,1,.37,1.94v5.74a2.39,2.39,0,0,0,.4,1.66,2.62,2.62,0,0,0,1.72.38h7.87a2.6,2.6,0,0,1,2,.58,4.17,4.17,0,0,1,.47,2.44v2.51a5,5,0,0,1-.59,2.83,2.56,2.56,0,0,1-2.17.79h-7.7a2.32,2.32,0,0,0-1.62.42A2.35,2.35,0,0,0,135.32,28.5Z"/>
        <path d="M197.42,28.5V42.88a6.59,6.59,0,0,0,1.26,4.17,3.86,3.86,0,0,0,3.21,1.61,9.69,9.69,0,0,0,4.38-1.53,2.52,2.52,0,0,1,1.28-.38A2.35,2.35,0,0,1,209.42,48a4.63,4.63,0,0,1,.85,2.68q0,2.64-2.89,5.07a21.82,21.82,0,0,1-14.64,5.14q-6.8,0-10.93-3.85a13.21,13.21,0,0,1-4.12-10.14V27.22a1.92,1.92,0,0,0-.24-1.21,2,2,0,0,0-1.21-.23h-4.38a2.12,2.12,0,0,1-1.58-.41,3.4,3.4,0,0,1-.34-1.89V21.61a2.15,2.15,0,0,1,1-1.87L190.4,7.49a3.76,3.76,0,0,1,1.79-.39h3.45a1.69,1.69,0,0,1,1.42.49,3.57,3.57,0,0,1,.36,1.94v5.74a2.38,2.38,0,0,0,.41,1.66,2.62,2.62,0,0,0,1.72.38h7.87a2.6,2.6,0,0,1,2,.58,4.26,4.26,0,0,1,.47,2.44v2.51a4.87,4.87,0,0,1-.6,2.83,2.55,2.55,0,0,1-2.17.79h-7.7a2.31,2.31,0,0,0-1.61.42A2.3,2.3,0,0,0,197.42,28.5Z"/>
        <path d="M236.43,3.74l-.55,17.83c-.06,2.12.59,3.19,2,3.19.68,0,1.35-.47,2-1.41a12.78,12.78,0,0,1,11-6q6.77,0,10.76,4.12a10.27,10.27,0,0,1,2.53,4.34A28,28,0,0,1,265,33l.39,15.36a6.76,6.76,0,0,0,.53,2.93,8.3,8.3,0,0,0,2.27,2.34A2.89,2.89,0,0,1,269.4,56q0,4.59-13.87,4.59-12.33,0-12.34-4.29a2.25,2.25,0,0,1,.22-1.11c.14-.25.63-.93,1.48-2q1.8-2.34,1.79-11.7,0-5.66-1.06-8.25a5.05,5.05,0,0,0-5-3.53,4.25,4.25,0,0,0-3.83,1.87c-.6.91-.89,2.61-.89,5.11v8.21a51.86,51.86,0,0,0,.51,6.16,9.74,9.74,0,0,0,2,2.68,3,3,0,0,1,.81,2.09q0,4.8-12.5,4.8-14.64,0-14.64-5a2.89,2.89,0,0,1,.47-1.77,11.71,11.71,0,0,1,2.3-1.89q1.62-1.15,2.23-6.19a156.18,156.18,0,0,0,.62-16.7,104.15,104.15,0,0,0-.51-12.67,4.37,4.37,0,0,0-.62-1.94,4.78,4.78,0,0,0-1.68-1.25q-2.64-1.37-2.64-3.15a4,4,0,0,1,2-3.45,29.67,29.67,0,0,1,7.66-2.85,48.64,48.64,0,0,1,12.21-2.12C235.69,1.62,236.49,2.33,236.43,3.74Z"/>
        <path d="M311.21,40.67h-20c-.88,0-1.32.38-1.32,1.15q0,2.76,3,5.21a10,10,0,0,0,6.44,2.44,15.3,15.3,0,0,0,4.54-.78,14.9,14.9,0,0,0,4.1-1.94,4.48,4.48,0,0,1,2.3-1,2.88,2.88,0,0,1,2.17,1.36,4.5,4.5,0,0,1,1.06,2.81q0,3.06-3.83,5.91a25.31,25.31,0,0,1-15.61,5.06,22.16,22.16,0,0,1-15-5.19,21,21,0,0,1-4.85-6.17,21.45,21.45,0,0,1-2.51-10.12,20.65,20.65,0,0,1,4.34-12.85,22.79,22.79,0,0,1,9.53-7.36,24.08,24.08,0,0,1,9.4-1.82A21.14,21.14,0,0,1,308.75,22a18.4,18.4,0,0,1,4.59,5.44,14.31,14.31,0,0,1,2,7,8.12,8.12,0,0,1-1.15,4.53A3.43,3.43,0,0,1,311.21,40.67Zm-18.88-8h4.29c1.82,0,2.72-.83,2.72-2.51A5.36,5.36,0,0,0,298,26.46,4.44,4.44,0,0,0,294.58,25a4.38,4.38,0,0,0-3.74,1.87,6,6,0,0,0-1.28,3.53,2.21,2.21,0,0,0,.62,1.79A3.44,3.44,0,0,0,292.33,32.71Z"/>
        <path d="M338.74,17.65c1.61,0,2.42,1.15,2.42,3.45v.72c0,.46,0,.82,0,1.11,0,1.53.45,2.29,1.36,2.29.48,0,1.23-.6,2.25-1.82a16.34,16.34,0,0,1,5.79-4.19,17.33,17.33,0,0,1,7.15-1.56q7.09,0,9.86,5.19c.71,1.28,1.29,1.92,1.75,1.92s1.05-.57,2.21-1.7A17.48,17.48,0,0,1,384,17.65a13.21,13.21,0,0,1,9,3q3.66,3.15,3.66,9.45c0,.9,0,2.44-.08,4.59q-.09,1.11-.09,6.68c0,2.38,0,4.06,0,5,0,.6,0,1,0,1.28a7.18,7.18,0,0,0,1.7,4.76,14.88,14.88,0,0,1,1.25,1.64,2.11,2.11,0,0,1,.24,1.08q0,5.44-12.81,5.45a25.56,25.56,0,0,1-9.12-1.36q-3.46-1.36-3.47-3.58a2.77,2.77,0,0,1,.24-1.29A28.25,28.25,0,0,1,376.08,52q1.32-1.74,1.32-11.61c0-3.83-.27-6.49-.81-8a3.65,3.65,0,0,0-3.74-2.68,3.88,3.88,0,0,0-2.49.88,4,4,0,0,0-1.42,2.19,49.31,49.31,0,0,0-.43,8.16,71.92,71.92,0,0,0,.45,8.68,13.66,13.66,0,0,0,2.15,4.26,2.85,2.85,0,0,1,.42,1.61q0,5.07-13.23,5.06c-5.3,0-8.87-.39-10.72-1.19a3.34,3.34,0,0,1-2.21-3.31,2.83,2.83,0,0,1,.28-1.49,15.78,15.78,0,0,1,1.89-2,4.52,4.52,0,0,0,1.21-1.68,12.06,12.06,0,0,0,.41-2.87c.19-3.12.3-6.27.3-9.45q0-8.32-4.26-8.33-3.19,0-3.79,3.57a70.09,70.09,0,0,0-.51,9.61,58.28,58.28,0,0,0,.2,6.13,8.63,8.63,0,0,0,1,3,6.59,6.59,0,0,1,1,3q0,2.76-3.87,3.83A35.47,35.47,0,0,1,330,60.57c-4.36,0-7.57-.34-9.61-1s-3.06-1.79-3.06-3.29a2.52,2.52,0,0,1,.4-1.45,12.59,12.59,0,0,1,1.85-1.87,7.12,7.12,0,0,0,2.21-4.81c.32-3.52.47-7.16.47-10.93a26.53,26.53,0,0,0-.4-5.64A4.52,4.52,0,0,0,320.15,29a8.32,8.32,0,0,1-1.64-1.49,2.4,2.4,0,0,1-.36-1.4c0-1.9,1.49-3.41,4.46-4.51Q333.38,17.7,338.74,17.65Z"/>
        <path d="M449.17,28.5V42.88a6.59,6.59,0,0,0,1.25,4.17,3.89,3.89,0,0,0,3.21,1.61A9.69,9.69,0,0,0,458,47.13a2.52,2.52,0,0,1,1.28-.38A2.35,2.35,0,0,1,461.16,48a4.63,4.63,0,0,1,.85,2.68q0,2.64-2.89,5.07a21.82,21.82,0,0,1-14.63,5.14q-6.81,0-10.94-3.85a13.21,13.21,0,0,1-4.12-10.14V27.22a1.92,1.92,0,0,0-.24-1.21,2,2,0,0,0-1.21-.23H423.6c-.82,0-1.35-.14-1.57-.41a3.31,3.31,0,0,1-.34-1.89V21.61a2.12,2.12,0,0,1,1-1.87L442.15,7.49a3.69,3.69,0,0,1,1.78-.39h3.45a1.69,1.69,0,0,1,1.42.49,3.55,3.55,0,0,1,.37,1.94v5.74a2.39,2.39,0,0,0,.4,1.66,2.62,2.62,0,0,0,1.72.38h7.87a2.6,2.6,0,0,1,2,.58,4.17,4.17,0,0,1,.47,2.44v2.51a5,5,0,0,1-.59,2.83,2.56,2.56,0,0,1-2.17.79h-7.7a2.32,2.32,0,0,0-1.62.42A2.35,2.35,0,0,0,449.17,28.5Z"/>
        <path d="M490.09,17.31a23.49,23.49,0,0,1,13.65,4,21.65,21.65,0,0,1,6.77,7.53A20.92,20.92,0,0,1,513.15,39q0,9.23-8.47,15.69a29.64,29.64,0,0,1-18.5,6.17q-10.17,0-16.47-5.78a19,19,0,0,1-6.12-14.64A20.6,20.6,0,0,1,471.07,24,28.21,28.21,0,0,1,490.09,17.31Zm-3.4,9.83a3.67,3.67,0,0,0-3,1.38,5.66,5.66,0,0,0-1.13,3.68,34.81,34.81,0,0,0,1,7,51.76,51.76,0,0,0,2.27,7.8q1.32,3.49,4.3,3.49,3.78,0,3.78-4.81a39.71,39.71,0,0,0-.95-8.12,33.28,33.28,0,0,0-2.32-7.4Q489.19,27.23,486.69,27.14Z"/>
        <path d="M562.62,3.74l-.39,13.32c0,1.33,0,3.29,0,5.87a50,50,0,0,0,.13,5.06c.2,1.16.79,1.74,1.79,1.74A8.8,8.8,0,0,0,568,28.1c1.76-1.09,2.64-1.91,2.64-2.45a4,4,0,0,0-.94-1.7,2.88,2.88,0,0,1-.68-1.7q0-4.61,11.31-4.6a29.12,29.12,0,0,1,9.28,1.19,4.81,4.81,0,0,1,2.25,1.62,3.94,3.94,0,0,1,.9,2.47A2.71,2.71,0,0,1,591,25.61a17.41,17.41,0,0,1-2.47.55,14.28,14.28,0,0,0-4.85,2.32c-2,1.32-3,2.28-3,2.87q0,.6,3.6,5.59t6.7,8.7a31.36,31.36,0,0,0,3.12,3.41,19,19,0,0,0,3.13,2.08q3.06,1.66,3.06,3.79a4,4,0,0,1-1.93,3.53,9.55,9.55,0,0,1-5.3,1.27H579.25a4.89,4.89,0,0,1-4-1.91l-7.06-9.06q-2.82-3.57-3.43-4.25c-.41-.46-.76-.69-1-.69s-.57.26-.68.77a16.21,16.21,0,0,0-.17,2.89,6.83,6.83,0,0,0,.42,2.79,7.28,7.28,0,0,0,2,2.1A3.2,3.2,0,0,1,566.32,55q0,3-3.15,4.3t-10.38,1.32q-8.5,0-11.06-.73a4.77,4.77,0,0,1-2.47-1.72,4.36,4.36,0,0,1-1-2.74A3.39,3.39,0,0,1,540,52.62a9.83,9.83,0,0,0,2-1.49,4,4,0,0,0,.68-1.62,122.08,122.08,0,0,0,.93-18.37V21.82a22.43,22.43,0,0,0-.49-6.08,2.66,2.66,0,0,0-2.4-1.58q-3.5-.46-3.49-2.89a3.76,3.76,0,0,1,1.53-2.91,17.31,17.31,0,0,1,5-2.58q11.15-4.13,16.51-4.12C561.89,1.66,562.67,2.35,562.62,3.74Z"/>
        <path d="M639.7,40.67h-20c-.88,0-1.32.38-1.32,1.15q0,2.76,3,5.21a10,10,0,0,0,6.45,2.44,15.29,15.29,0,0,0,4.53-.78,14.63,14.63,0,0,0,4.1-1.94,4.52,4.52,0,0,1,2.3-1,2.88,2.88,0,0,1,2.17,1.36A4.5,4.5,0,0,1,642,49.9c0,2-1.27,4-3.83,5.91a25.29,25.29,0,0,1-15.61,5.06,22.18,22.18,0,0,1-15-5.19,21.14,21.14,0,0,1-4.85-6.17,21.57,21.57,0,0,1-2.51-10.12,20.71,20.71,0,0,1,4.34-12.85,22.85,22.85,0,0,1,9.53-7.36,24.08,24.08,0,0,1,9.4-1.82A21.1,21.1,0,0,1,637.23,22a18.4,18.4,0,0,1,4.59,5.44,14.2,14.2,0,0,1,2,7,8.12,8.12,0,0,1-1.15,4.53A3.42,3.42,0,0,1,639.7,40.67Zm-18.89-8h4.3c1.81,0,2.72-.83,2.72-2.51a5.32,5.32,0,0,0-1.32-3.74,4.75,4.75,0,0,0-7.19.42A6,6,0,0,0,618,30.41a2.21,2.21,0,0,0,.62,1.79A3.44,3.44,0,0,0,620.81,32.71Z"/>
        <path d="M685.89,40.67h-20c-.88,0-1.32.38-1.32,1.15q0,2.76,3,5.21A10,10,0,0,0,674,49.47a15.29,15.29,0,0,0,4.53-.78,14.76,14.76,0,0,0,4.1-1.94,4.52,4.52,0,0,1,2.3-1,2.88,2.88,0,0,1,2.17,1.36,4.5,4.5,0,0,1,1.06,2.81q0,3.06-3.83,5.91a25.31,25.31,0,0,1-15.61,5.06,22.16,22.16,0,0,1-15-5.19,21,21,0,0,1-4.85-6.17,21.45,21.45,0,0,1-2.51-10.12,20.71,20.71,0,0,1,4.34-12.85,22.79,22.79,0,0,1,9.53-7.36,24.08,24.08,0,0,1,9.4-1.82A21.14,21.14,0,0,1,683.43,22,18.4,18.4,0,0,1,688,27.39a14.31,14.31,0,0,1,2,7,8.12,8.12,0,0,1-1.15,4.53A3.43,3.43,0,0,1,685.89,40.67Zm-18.88-8h4.29c1.82,0,2.73-.83,2.73-2.51a5.32,5.32,0,0,0-1.32-3.74A4.44,4.44,0,0,0,669.26,25a4.38,4.38,0,0,0-3.74,1.87,6,6,0,0,0-1.28,3.53,2.21,2.21,0,0,0,.62,1.79A3.44,3.44,0,0,0,667,32.71Z"/>
        <path d="M713.63,17.57a7.09,7.09,0,0,1,3.23.44c.43.3.64,1,.64,2.24s.34,1.57,1,1.57a11.2,11.2,0,0,0,2.85-1.61A16.71,16.71,0,0,1,726,18.14a18.49,18.49,0,0,1,5.12-.83,14.19,14.19,0,0,1,9.66,3.75,19,19,0,0,1,5,7.19,24.84,24.84,0,0,1,1.87,9.57q0,10.71-7.23,17.65a17.73,17.73,0,0,1-12.68,5.4,18.15,18.15,0,0,1-7.44-1.7,3.18,3.18,0,0,0-1.23-.34c-.85,0-1.28.85-1.28,2.55a5.38,5.38,0,0,0,2.25,4.85,15.33,15.33,0,0,1,2.49,1.9,2.56,2.56,0,0,1,.41,1.63c0,1.7-1,3-2.85,3.94a18.87,18.87,0,0,1-8,1.38,33.79,33.79,0,0,1-16.17-3.7c-2.35-1.25-3.53-2.79-3.53-4.64a3,3,0,0,1,.53-1.93A6.22,6.22,0,0,1,695,63.42a4.73,4.73,0,0,0,2.7-3.7q.48-2.67.74-15.4-.3-10.45-.76-11.74a1,1,0,0,0-.49-.55,20.63,20.63,0,0,0-2.11-.47,3.68,3.68,0,0,1-2.21-1.25,3.58,3.58,0,0,1-.85-2.4,4.3,4.3,0,0,1,1.19-3,12.17,12.17,0,0,1,3.87-2.54Q707.5,17.66,713.63,17.57Zm4.21,16.08-.64,11.7c0,.48,0,.95,0,1.4a2.48,2.48,0,0,0,1.15,2.13,5.18,5.18,0,0,0,3.06.8q5.86,0,5.87-9.65,0-6.26-2.47-8.81a4.91,4.91,0,0,0-3.65-1.57Q718.09,29.65,717.84,33.65Z"/>
        <path d="M797.81,28.5V42.88a6.59,6.59,0,0,0,1.26,4.17,3.88,3.88,0,0,0,3.21,1.61,9.69,9.69,0,0,0,4.38-1.53,2.52,2.52,0,0,1,1.28-.38A2.35,2.35,0,0,1,809.81,48a4.63,4.63,0,0,1,.85,2.68q0,2.64-2.89,5.07a21.82,21.82,0,0,1-14.64,5.14q-6.79,0-10.93-3.85a13.21,13.21,0,0,1-4.12-10.14V27.22a1.92,1.92,0,0,0-.24-1.21,2,2,0,0,0-1.21-.23h-4.38a2.14,2.14,0,0,1-1.58-.41,3.4,3.4,0,0,1-.34-1.89V21.61a2.15,2.15,0,0,1,1-1.87L790.79,7.49a3.76,3.76,0,0,1,1.79-.39H796a1.69,1.69,0,0,1,1.42.49,3.57,3.57,0,0,1,.36,1.94v5.74a2.38,2.38,0,0,0,.41,1.66,2.62,2.62,0,0,0,1.72.38h7.87a2.6,2.6,0,0,1,2,.58,4.26,4.26,0,0,1,.47,2.44v2.51a4.87,4.87,0,0,1-.6,2.83,2.55,2.55,0,0,1-2.17.79h-7.69a2.32,2.32,0,0,0-1.62.42A2.3,2.3,0,0,0,797.81,28.5Z"/>
        <path d="M836.82,3.74l-.55,17.83c-.06,2.12.59,3.19,2,3.19.68,0,1.34-.47,2-1.41a12.76,12.76,0,0,1,11-6q6.76,0,10.76,4.12a10.29,10.29,0,0,1,2.54,4.34,28.54,28.54,0,0,1,.82,7.19l.39,15.36a6.91,6.91,0,0,0,.53,2.93,8.38,8.38,0,0,0,2.28,2.34A2.91,2.91,0,0,1,869.79,56q0,4.59-13.87,4.59-12.33,0-12.33-4.29a2.38,2.38,0,0,1,.21-1.11c.14-.25.64-.93,1.49-2q1.79-2.34,1.78-11.7,0-5.66-1.06-8.25a5.05,5.05,0,0,0-5-3.53,4.25,4.25,0,0,0-3.83,1.87c-.59.91-.89,2.61-.89,5.11v8.21a54.43,54.43,0,0,0,.51,6.16,9.64,9.64,0,0,0,2,2.68,3.05,3.05,0,0,1,.8,2.09q0,4.8-12.5,4.8-14.64,0-14.63-5a2.89,2.89,0,0,1,.46-1.77,11.71,11.71,0,0,1,2.3-1.89q1.62-1.15,2.23-6.19a156.18,156.18,0,0,0,.62-16.7,104.15,104.15,0,0,0-.51-12.67,4.24,4.24,0,0,0-.62-1.94,4.78,4.78,0,0,0-1.68-1.25q-2.64-1.37-2.64-3.15a4,4,0,0,1,2-3.45,29.82,29.82,0,0,1,7.66-2.85,48.72,48.72,0,0,1,12.21-2.12C836.08,1.62,836.88,2.33,836.82,3.74Z"/>
        <path d="M911.6,40.67h-20c-.88,0-1.32.38-1.32,1.15q0,2.76,3,5.21a10,10,0,0,0,6.45,2.44,15.29,15.29,0,0,0,4.53-.78,14.76,14.76,0,0,0,4.1-1.94,4.52,4.52,0,0,1,2.3-1,2.88,2.88,0,0,1,2.17,1.36,4.5,4.5,0,0,1,1.06,2.81q0,3.06-3.83,5.91a25.31,25.31,0,0,1-15.61,5.06,22.16,22.16,0,0,1-15-5.19,21,21,0,0,1-4.85-6.17,21.45,21.45,0,0,1-2.51-10.12,20.65,20.65,0,0,1,4.34-12.85A22.79,22.79,0,0,1,886,19.18a24.08,24.08,0,0,1,9.4-1.82A21.14,21.14,0,0,1,909.14,22a18.4,18.4,0,0,1,4.59,5.44,14.31,14.31,0,0,1,2,7,8.12,8.12,0,0,1-1.15,4.53A3.43,3.43,0,0,1,911.6,40.67Zm-18.88-8H897c1.82,0,2.73-.83,2.73-2.51a5.32,5.32,0,0,0-1.32-3.74A4.44,4.44,0,0,0,895,25a4.38,4.38,0,0,0-3.74,1.87A6,6,0,0,0,890,30.41a2.21,2.21,0,0,0,.62,1.79A3.44,3.44,0,0,0,892.72,32.71Z"/>
        <path d="M939.13,17.65c1.61,0,2.42,1.15,2.42,3.45v.72c0,.46,0,.82,0,1.11,0,1.53.45,2.29,1.36,2.29.48,0,1.23-.6,2.26-1.82a16.21,16.21,0,0,1,5.78-4.19,17.37,17.37,0,0,1,7.15-1.56q7.1,0,9.87,5.19c.7,1.28,1.29,1.92,1.74,1.92s1.05-.57,2.21-1.7a17.48,17.48,0,0,1,12.55-5.41,13.25,13.25,0,0,1,9,3q3.66,3.15,3.65,9.45c0,.9,0,2.44-.08,4.59q-.09,1.11-.09,6.68c0,2.38,0,4.06,0,5,0,.6,0,1,0,1.28a7.23,7.23,0,0,0,1.7,4.76,17.09,17.09,0,0,1,1.26,1.64,2.22,2.22,0,0,1,.23,1.08q0,5.44-12.8,5.45a25.58,25.58,0,0,1-9.13-1.36q-3.47-1.36-3.47-3.58a2.77,2.77,0,0,1,.24-1.29A26.91,26.91,0,0,1,976.48,52q1.32-1.74,1.31-11.61c0-3.83-.27-6.49-.8-8a3.66,3.66,0,0,0-3.75-2.68,3.9,3.9,0,0,0-2.49.88,4.06,4.06,0,0,0-1.42,2.19A49.31,49.31,0,0,0,968.9,41a71.92,71.92,0,0,0,.45,8.68,13.66,13.66,0,0,0,2.15,4.26,2.85,2.85,0,0,1,.42,1.61q0,5.07-13.23,5.06c-5.3,0-8.87-.39-10.72-1.19a3.34,3.34,0,0,1-2.21-3.31,2.83,2.83,0,0,1,.28-1.49,15.78,15.78,0,0,1,1.89-2,4.56,4.56,0,0,0,1.22-1.68,12.77,12.77,0,0,0,.4-2.87q.3-4.68.3-9.45,0-8.32-4.26-8.33c-2.12,0-3.39,1.19-3.78,3.57a68.51,68.51,0,0,0-.51,9.61,59.31,59.31,0,0,0,.19,6.13,8.84,8.84,0,0,0,1,3,6.59,6.59,0,0,1,1,3q0,2.76-3.87,3.83a35.45,35.45,0,0,1-9.27,1.14c-4.37,0-7.57-.34-9.62-1s-3.06-1.79-3.06-3.29a2.52,2.52,0,0,1,.4-1.45A12.59,12.59,0,0,1,920,52.92a7.17,7.17,0,0,0,2.22-4.81q.46-5.28.46-10.93a26.53,26.53,0,0,0-.4-5.64A4.48,4.48,0,0,0,920.54,29a8.64,8.64,0,0,1-1.64-1.49,2.4,2.4,0,0,1-.36-1.4c0-1.9,1.49-3.41,4.47-4.51Q933.76,17.7,939.13,17.65Z"/>
      </g>
    </svg>
  </>
}

export default Tagline
