// import React from 'react'

import './index.css'

function Label (props) {
  const { angle, index = 0 } = props

  return <>
    <svg id="segment"
      className="label"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 741 91.14"
      width="741"
      height="91.14"
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        transformOrigin: 'center right',
        transform: `translateY(50%) rotate(${angle}deg)`,
        animationDelay: `${1700 + (index * 200)}ms`,
      }}
    >
      <g>
        <path d="M10.4,11q6.7,0,6.7,2.77A2.06,2.06,0,0,1,16.76,15a4.75,4.75,0,0,1-1.31,1,2.24,2.24,0,0,0-1.07,1.35,16.24,16.24,0,0,0-.22,3.4c0,1,.18,1.61.56,1.84a1.94,1.94,0,0,0,1,.26c.44,0,1.47,0,3.09,0a9.24,9.24,0,0,0,2.75-.23c.35-.16.53-.55.53-1.18a20.8,20.8,0,0,0-.25-4,2.19,2.19,0,0,0-1-1.52,4.69,4.69,0,0,1-1.25-.91,1.57,1.57,0,0,1-.3-1,2.28,2.28,0,0,1,.67-1.6,3.33,3.33,0,0,1,1.74-1,24.26,24.26,0,0,1,5.6-.54,17.41,17.41,0,0,1,5.83.72C34.35,12.23,35,13,35,14a2.05,2.05,0,0,1-1.33,1.93,2.79,2.79,0,0,0-1.09.74A3.34,3.34,0,0,0,32.14,18c-.06.36-.13,2-.22,4.77s-.13,5.06-.13,6.73A27.45,27.45,0,0,0,32.12,35a2,2,0,0,0,.38.86,9.64,9.64,0,0,0,1.36.86,1.47,1.47,0,0,1,.81,1.37,2.52,2.52,0,0,1-1.94,2.38,19.43,19.43,0,0,1-6.31.74,15.61,15.61,0,0,1-5.65-.76,2.25,2.25,0,0,1-1.65-2.12,2.09,2.09,0,0,1,1.27-1.91,2.42,2.42,0,0,0,.85-.69,4.54,4.54,0,0,0,.44-1.27,22.1,22.1,0,0,0,.38-4.42q0-1.37-.39-1.68a4,4,0,0,0-2.08-.32,16.62,16.62,0,0,0-4.9.43c-.28.11-.46.45-.53,1A20,20,0,0,0,14,32a6.82,6.82,0,0,0,.69,3.53,4.07,4.07,0,0,0,1.11.88,1.8,1.8,0,0,1,.91,1.69c0,1-.7,1.77-2.09,2.3a17.36,17.36,0,0,1-6,.8,19.14,19.14,0,0,1-5.76-.73,3.52,3.52,0,0,1-1.51-.92,1.84,1.84,0,0,1-.55-1.3,1.63,1.63,0,0,1,.34-1.09,5.82,5.82,0,0,1,1.51-1,1.9,1.9,0,0,0,.85-.77A5,5,0,0,0,4,33.85a52.66,52.66,0,0,0,.33-6.58c0-.5,0-1.09,0-1.78,0-3.44-.08-5.4-.08-5.89a5.77,5.77,0,0,0-.31-2.44,4.29,4.29,0,0,0-1.75-1.29,1.45,1.45,0,0,1-.95-1.36q0-2.17,4.21-3A25.31,25.31,0,0,1,10.4,11Z"/>
        <path d="M56.24,31.18H46.15c-.45,0-.67.2-.67.58A3.44,3.44,0,0,0,47,34.39a5,5,0,0,0,3.26,1.24,7.52,7.52,0,0,0,2.29-.4,7.12,7.12,0,0,0,2.07-1,2.28,2.28,0,0,1,1.16-.51,1.47,1.47,0,0,1,1.1.69,2.32,2.32,0,0,1,.53,1.41,3.9,3.9,0,0,1-1.93,3,12.79,12.79,0,0,1-7.89,2.56A11.25,11.25,0,0,1,40,38.77a11,11,0,0,1-3.71-8.23,10.44,10.44,0,0,1,2.19-6.49,11.55,11.55,0,0,1,4.81-3.72,12.19,12.19,0,0,1,4.75-.92A10.65,10.65,0,0,1,55,21.73a9.27,9.27,0,0,1,2.32,2.75,7.22,7.22,0,0,1,1,3.52,4.18,4.18,0,0,1-.58,2.29A1.73,1.73,0,0,1,56.24,31.18Zm-9.54-4h2.17c.92,0,1.38-.43,1.38-1.27A2.65,2.65,0,0,0,49.58,24a2.4,2.4,0,0,0-3.63.21A3,3,0,0,0,45.31,26a1.11,1.11,0,0,0,.31.9A1.74,1.74,0,0,0,46.7,27.17Z"/>
        <path d="M72,19.39a10.51,10.51,0,0,1,6.34,1.89,5.55,5.55,0,0,1,1.92,2.31,9.37,9.37,0,0,1,.55,3.58l0,3.2a12,12,0,0,0,.47,4.31,1.55,1.55,0,0,0,.45.61,3.05,3.05,0,0,0,.9.23.79.79,0,0,1,.69.9,3.65,3.65,0,0,1-.83,2.15,6.71,6.71,0,0,1-2.14,1.87,6.54,6.54,0,0,1-3.37,1,4.42,4.42,0,0,1-3.56-1.55c-.31-.37-.58-.56-.84-.56a2.11,2.11,0,0,0-1.14.54,8.1,8.1,0,0,1-4.88,1.57A7.79,7.79,0,0,1,62,40.18a5.83,5.83,0,0,1-2.47-4.74,5.34,5.34,0,0,1,1.8-4q2.82-2.7,8.42-2.73a3.14,3.14,0,0,0,1.57-.24c.22-.17.32-.55.32-1.15a8.33,8.33,0,0,0-.47-3.3,1.6,1.6,0,0,0-1.55-1,1.79,1.79,0,0,0-1.14.39,7.08,7.08,0,0,0-1.26,1.42q-1.92,2.71-4,2.72A2.41,2.41,0,0,1,61.46,27a2.13,2.13,0,0,1-.66-1.63,3.27,3.27,0,0,1,.82-2.06,7.43,7.43,0,0,1,2.25-1.81A17.15,17.15,0,0,1,72,19.39ZM70.55,31.83a2.12,2.12,0,0,0-1.64.77,2.65,2.65,0,0,0-.7,1.85,2.6,2.6,0,0,0,.49,1.66,1.57,1.57,0,0,0,1.31.64c1.15,0,1.72-1,1.72-2.9a3.51,3.51,0,0,0-.24-1.59A1,1,0,0,0,70.55,31.83Z"/>
        <path d="M109.78,13.2V32.82a10,10,0,0,0,.14,2.14,1.82,1.82,0,0,0,.78.93,4.06,4.06,0,0,1,1,.94,1.82,1.82,0,0,1,.26,1q0,1.73-3.45,2.62a25.17,25.17,0,0,1-6.23.8,1.33,1.33,0,0,1-.9-.21,1.73,1.73,0,0,1-.26-1c-.08-.74-.33-1.1-.78-1.1a2.28,2.28,0,0,0-1.14.64,7.52,7.52,0,0,1-4.87,1.79,8.64,8.64,0,0,1-6-2.47,12.16,12.16,0,0,1-2.53-3.42,11,11,0,0,1-1.29-5.09,11.3,11.3,0,0,1,1.67-5.84,10.48,10.48,0,0,1,4.22-4,9.49,9.49,0,0,1,4.4-1.16,8.88,8.88,0,0,1,4.47,1.12,1.21,1.21,0,0,0,.51.17c.46,0,.69-.62.69-1.85a1.71,1.71,0,0,0-.38-1.21,3,3,0,0,0-1.34-.72c-.9-.29-1.35-.79-1.35-1.51,0-1,1-1.86,3.11-2.45a35.47,35.47,0,0,1,7.37-1.52C109.16,11.44,109.78,12,109.78,13.2ZM98.11,24a2.47,2.47,0,0,0-2.21,1.48,10.71,10.71,0,0,0-1.09,5.2,7.6,7.6,0,0,0,1,4.4,2.36,2.36,0,0,0,2,1.08,2.14,2.14,0,0,0,2-1.63,15.65,15.65,0,0,0,.63-5.23c0-2.2-.25-3.67-.75-4.4A2,2,0,0,0,98.11,24Z"/>
        <path d="M125.23,20.89V33.37a6.66,6.66,0,0,0,.26,2.17,3,3,0,0,0,1.09,1.27,3.88,3.88,0,0,1,.89.81,1.47,1.47,0,0,1,.23.85c0,1.84-2.35,2.77-7,2.77a23.75,23.75,0,0,1-6-.6,2.52,2.52,0,0,1-1.22-.76,1.83,1.83,0,0,1-.46-1.22,1.46,1.46,0,0,1,.24-.9,6.38,6.38,0,0,1,1.2-1,2.8,2.8,0,0,0,1-1.62,15.06,15.06,0,0,0,.28-3.33c0-.47,0-1.35-.1-2.65s-.11-2.05-.14-2.27a1.82,1.82,0,0,0-1.2-1.35,1.87,1.87,0,0,1-1.34-1.72c0-.9.58-1.57,1.74-2a41.07,41.07,0,0,1,5.16-1.57,20,20,0,0,1,4.25-.64C124.83,19.56,125.23,20,125.23,20.89ZM121,11.48a5.53,5.53,0,0,1,3,.7A2.13,2.13,0,0,1,125,14.06a2.93,2.93,0,0,1-1.65,2.62A12.38,12.38,0,0,1,117,18.21a4.43,4.43,0,0,1-2.49-.61,2.4,2.4,0,0,1-1.25-2.14c0-1.23.88-2.2,2.65-2.9A13.49,13.49,0,0,1,121,11.48Z"/>
        <path d="M155,27.34l.3,7.56a3.09,3.09,0,0,0,.27,1.27,10.75,10.75,0,0,0,1.32,1.56,1.36,1.36,0,0,1,.33,1c0,.95-.57,1.61-1.7,2a22.24,22.24,0,0,1-6.06.56,12,12,0,0,1-4.19-.52A1.82,1.82,0,0,1,144,39a1.23,1.23,0,0,1,.13-.63,10.66,10.66,0,0,1,.86-1q.86-.9.86-6a10.54,10.54,0,0,0-.69-4.38,2.5,2.5,0,0,0-4-.21,7.39,7.39,0,0,0-.53,3.35v4.25a6.11,6.11,0,0,0,.16,1.74,10.81,10.81,0,0,0,1.06,1.83,1.61,1.61,0,0,1,.24.88c0,.84-.59,1.47-1.76,1.87a18.46,18.46,0,0,1-5.5.6c-4,0-6-.82-6-2.47A1.41,1.41,0,0,1,129,38a3.83,3.83,0,0,1,.87-.76,1.78,1.78,0,0,0,.71-.93,17.34,17.34,0,0,0,.28-2.59c.1-1.64.15-3.35.15-5.14a5.25,5.25,0,0,0-.23-1.94,3.55,3.55,0,0,0-1.3-1.13,1.61,1.61,0,0,1-.17-2.82,13.9,13.9,0,0,1,3.09-1.39c1.38-.49,2.7-.9,4-1.24a12.76,12.76,0,0,1,2.62-.5,1.42,1.42,0,0,1,1.08.47,1.57,1.57,0,0,1,.44,1.12s0,.22,0,.54c0,.11,0,.21,0,.3,0,.69.29,1,.86,1,.34,0,.71-.27,1.1-.82a6.13,6.13,0,0,1,5.37-2.66A7.61,7.61,0,0,1,152.47,21a5.46,5.46,0,0,1,1.89,2.37A13.4,13.4,0,0,1,155,27.34Z"/>
        <path d="M181.32,18.25a1.13,1.13,0,0,1,.94.61,2.47,2.47,0,0,1,.41,1.43,4.58,4.58,0,0,1-.55,2,5.37,5.37,0,0,1-1.32,1.78c-.33.29-.49.52-.49.69s0,.21,0,.51a5.13,5.13,0,0,1,.07.82,5.9,5.9,0,0,1-1.72,4.3,10.39,10.39,0,0,1-4,2.4,15.82,15.82,0,0,1-5.36.86,12.81,12.81,0,0,1-1.39-.08,6.87,6.87,0,0,0-.86-.07A1,1,0,0,0,166,34.64a1.28,1.28,0,0,0,.74,1.15,4.06,4.06,0,0,0,2,.42,19.37,19.37,0,0,0,2.53-.24,41.48,41.48,0,0,1,5.2-.49,7.65,7.65,0,0,1,3.93.84,4.73,4.73,0,0,1,1.78,1.8,5.08,5.08,0,0,1,.65,2.49,6.77,6.77,0,0,1-1.59,4.21,11,11,0,0,1-5.56,3.57,22.36,22.36,0,0,1-6.66.9,17.51,17.51,0,0,1-7.74-1.46,6.1,6.1,0,0,1-2.19-1.8,3.92,3.92,0,0,1-.81-2.32,2.76,2.76,0,0,1,.46-1.55,2.63,2.63,0,0,1,1.17-1c.43-.17.64-.36.64-.56s-.18-.38-.53-.69a4,4,0,0,1-1.72-3,3.55,3.55,0,0,1,.82-2.13,5.84,5.84,0,0,1,2.06-1.7q.51-.26.51-.51c0-.2-.21-.5-.64-.9a6.29,6.29,0,0,1-2.22-4.77,5.3,5.3,0,0,1,.79-2.75,6.94,6.94,0,0,1,2.18-2.24,13.77,13.77,0,0,1,7.67-2.25,18.27,18.27,0,0,1,3.74.43,17.46,17.46,0,0,0,2.68.38,7.45,7.45,0,0,0,4.66-1.84A1.57,1.57,0,0,1,181.32,18.25ZM165,42.25c-.36,0-.54.2-.54.62a2.33,2.33,0,0,0,1.38,2.05,7.14,7.14,0,0,0,3.56.79,7.94,7.94,0,0,0,3.3-.56,1.73,1.73,0,0,0,1.19-1.57,1.14,1.14,0,0,0-.45-.91,1.63,1.63,0,0,0-1.11-.38,7.73,7.73,0,0,0-1,.09,18.19,18.19,0,0,1-2.69.19,20.19,20.19,0,0,1-2.1-.11,9.36,9.36,0,0,1-1.23-.17A1.6,1.6,0,0,0,165,42.25Zm4.68-19.55a2.13,2.13,0,0,0-1.73.81,3.42,3.42,0,0,0-.65,2.17A8.16,8.16,0,0,0,168,29.4a2.11,2.11,0,0,0,1.87,1.4,2.14,2.14,0,0,0,1.8-.87,3.72,3.72,0,0,0,.67-2.34,7.58,7.58,0,0,0-.7-3.57A2.26,2.26,0,0,0,169.65,22.7Z"/>
        <path d="M206.86,20.89V33.37a6.35,6.35,0,0,0,.27,2.17,2.9,2.9,0,0,0,1.09,1.27,3.88,3.88,0,0,1,.89.81,1.47,1.47,0,0,1,.22.85c0,1.84-2.34,2.77-7,2.77a23.89,23.89,0,0,1-6-.6,2.49,2.49,0,0,1-1.21-.76,1.78,1.78,0,0,1-.46-1.22,1.46,1.46,0,0,1,.24-.9,5.76,5.76,0,0,1,1.2-1,2.74,2.74,0,0,0,.94-1.62,14.47,14.47,0,0,0,.28-3.33c0-.47,0-1.35-.09-2.65s-.12-2.05-.14-2.27a1.81,1.81,0,0,0-1.21-1.35,1.86,1.86,0,0,1-1.33-1.72c0-.9.58-1.57,1.74-2a40.57,40.57,0,0,1,5.16-1.57,20,20,0,0,1,4.25-.64C206.47,19.56,206.86,20,206.86,20.89Zm-4.25-9.41a5.49,5.49,0,0,1,3,.7,2.14,2.14,0,0,1,1.1,1.88A2.93,2.93,0,0,1,205,16.68a12.31,12.31,0,0,1-6.33,1.53,4.41,4.41,0,0,1-2.49-.61,2.38,2.38,0,0,1-1.25-2.14c0-1.23.88-2.2,2.64-2.9A13.54,13.54,0,0,1,202.61,11.48Z"/>
        <path d="M236.66,27.34l.3,7.56a3.09,3.09,0,0,0,.27,1.27,10.75,10.75,0,0,0,1.32,1.56,1.35,1.35,0,0,1,.32,1c0,.95-.56,1.61-1.69,2a22.34,22.34,0,0,1-6.06.56,12,12,0,0,1-4.19-.52A1.81,1.81,0,0,1,225.66,39a1.14,1.14,0,0,1,.13-.63,10.66,10.66,0,0,1,.86-1q.85-.9.86-6a10.54,10.54,0,0,0-.69-4.38,2.5,2.5,0,0,0-4-.21,7.22,7.22,0,0,0-.54,3.35v4.25a6,6,0,0,0,.17,1.74,10.12,10.12,0,0,0,1.06,1.83,1.61,1.61,0,0,1,.24.88c0,.84-.59,1.47-1.77,1.87a18.37,18.37,0,0,1-5.5.6q-6,0-6-2.47a1.42,1.42,0,0,1,.18-.79,4.36,4.36,0,0,1,.87-.76,1.73,1.73,0,0,0,.71-.93,16,16,0,0,0,.28-2.59c.1-1.64.15-3.35.15-5.14a5.32,5.32,0,0,0-.22-1.94,3.64,3.64,0,0,0-1.3-1.13,1.67,1.67,0,0,1-.93-1.42,1.64,1.64,0,0,1,.76-1.4,13.9,13.9,0,0,1,3.09-1.39c1.37-.49,2.7-.9,4-1.24a13,13,0,0,1,2.62-.5,1.44,1.44,0,0,1,1.09.47,1.61,1.61,0,0,1,.44,1.12s0,.22,0,.54a1.62,1.62,0,0,0,0,.3c0,.69.28,1,.86,1,.34,0,.71-.27,1.09-.82a6.13,6.13,0,0,1,5.37-2.66A7.59,7.59,0,0,1,234.1,21,5.32,5.32,0,0,1,236,23.33,13.35,13.35,0,0,1,236.66,27.34Z"/>
        <path d="M263.88,25V32.3a3.37,3.37,0,0,0,.63,2.11,2,2,0,0,0,1.62.81,5,5,0,0,0,2.22-.77,1.23,1.23,0,0,1,.64-.2,1.19,1.19,0,0,1,.95.63,2.35,2.35,0,0,1,.43,1.35,3.43,3.43,0,0,1-1.46,2.56,11,11,0,0,1-7.39,2.6,7.72,7.72,0,0,1-5.52-2,6.66,6.66,0,0,1-2.09-5.12V24.39a1,1,0,0,0-.12-.61,1,1,0,0,0-.61-.12H251c-.42,0-.68-.06-.8-.2a1.77,1.77,0,0,1-.17-1v-.94a1.1,1.1,0,0,1,.5-.95l9.83-6.18a1.94,1.94,0,0,1,.91-.2H263a.85.85,0,0,1,.72.25,1.8,1.8,0,0,1,.18,1v2.9a1.19,1.19,0,0,0,.2.83,1.34,1.34,0,0,0,.87.2h4c.53,0,.87.09,1,.29a2.1,2.1,0,0,1,.24,1.23v1.27a2.48,2.48,0,0,1-.31,1.43,1.27,1.27,0,0,1-1.09.4h-3.89a1.21,1.21,0,0,0-.82.21A1.21,1.21,0,0,0,263.88,25Z"/>
        <path d="M283.58,12.54l-.28,9c0,1.07.3,1.61,1,1.61a1.35,1.35,0,0,0,1-.71,6.44,6.44,0,0,1,5.56-3,7.23,7.23,0,0,1,5.44,2.08,5.24,5.24,0,0,1,1.28,2.2,14.41,14.41,0,0,1,.42,3.63l.19,7.75a3.35,3.35,0,0,0,.27,1.48,4.05,4.05,0,0,0,1.15,1.18,1.49,1.49,0,0,1,.62,1.19c0,1.54-2.34,2.32-7,2.32-4.16,0-6.23-.73-6.23-2.17a1.19,1.19,0,0,1,.1-.56,12.74,12.74,0,0,1,.75-1q.91-1.18.91-5.91a11.78,11.78,0,0,0-.54-4.17,2.55,2.55,0,0,0-2.54-1.78,2.15,2.15,0,0,0-1.93.94,5.13,5.13,0,0,0-.45,2.58v4.15a27.07,27.07,0,0,0,.26,3.11,4.88,4.88,0,0,0,1,1.36,1.52,1.52,0,0,1,.4,1.05q0,2.43-6.31,2.43-7.39,0-7.39-2.52a1.52,1.52,0,0,1,.23-.89,6.16,6.16,0,0,1,1.16-.95c.55-.39.92-1.43,1.13-3.13a78.3,78.3,0,0,0,.31-8.43,53.33,53.33,0,0,0-.25-6.4,2.08,2.08,0,0,0-.32-1,2.46,2.46,0,0,0-.84-.64c-.89-.45-1.34-1-1.34-1.59a2,2,0,0,1,1-1.74,15.24,15.24,0,0,1,3.87-1.43,24.32,24.32,0,0,1,6.17-1.08Q283.63,11.46,283.58,12.54Z"/>
        <path d="M321.35,31.18h-10.1c-.44,0-.67.2-.67.58a3.45,3.45,0,0,0,1.52,2.63,5,5,0,0,0,3.25,1.24,7.52,7.52,0,0,0,2.29-.4,7.12,7.12,0,0,0,2.07-1,2.31,2.31,0,0,1,1.17-.51,1.46,1.46,0,0,1,1.09.69,2.27,2.27,0,0,1,.54,1.41,3.91,3.91,0,0,1-1.94,3,12.74,12.74,0,0,1-7.88,2.56,11.22,11.22,0,0,1-7.56-2.62,10.63,10.63,0,0,1-2.45-3.12,10.83,10.83,0,0,1-1.27-5.11,10.44,10.44,0,0,1,2.19-6.49,11.58,11.58,0,0,1,4.82-3.72,12.17,12.17,0,0,1,4.74-.92,10.63,10.63,0,0,1,6.94,2.32,9.27,9.27,0,0,1,2.32,2.75,7.22,7.22,0,0,1,1,3.52,4.1,4.1,0,0,1-.58,2.29A1.73,1.73,0,0,1,321.35,31.18Zm-9.54-4H314c.92,0,1.37-.43,1.37-1.27a2.69,2.69,0,0,0-.66-1.89,2.25,2.25,0,0,0-1.74-.73,2.21,2.21,0,0,0-1.89.94,3,3,0,0,0-.65,1.79,1.15,1.15,0,0,0,.31.9A1.76,1.76,0,0,0,311.81,27.17Z"/>
        <path d="M25.82,74.85l-2.21-3.54a15.11,15.11,0,0,0-1-1.44.78.78,0,0,0-.59-.24c-.52,0-1.05.48-1.59,1.42l-3.59,6.19C16.16,78.43,15.38,79,14.52,79a2.28,2.28,0,0,1-1.46-.54,7.49,7.49,0,0,1-1.44-1.86L5.18,65.9a16.62,16.62,0,0,0-2.52-3.4,4.62,4.62,0,0,0-1.56-.66,1.53,1.53,0,0,1-.79-.59,1.65,1.65,0,0,1-.31-1c0-1.1.85-1.88,2.56-2.32a23.14,23.14,0,0,1,5.63-.65c4.56,0,6.85.86,6.85,2.56a3.06,3.06,0,0,1-.67,1.44.76.76,0,0,0-.13.41,8,8,0,0,0,1,2.34c.75,1.42,1.39,2.13,1.94,2.13q.46,0,1.2-1.08a3.35,3.35,0,0,0,.73-1.74,1.65,1.65,0,0,0-.32-1.06A9,9,0,0,0,17.27,61a1.49,1.49,0,0,1-.73-1.22c0-1.12,1.15-1.85,3.44-2.19a31.73,31.73,0,0,1,4.9-.33,14.48,14.48,0,0,1,4.68.55A1.88,1.88,0,0,1,31,59.62a1.67,1.67,0,0,1-.17.8,6.89,6.89,0,0,1-.93,1.07,1,1,0,0,0-.23.67,7.39,7.39,0,0,0,1,2.45c.57,1.09,1.09,1.63,1.56,1.63s1.17-.69,2-2.08A6,6,0,0,0,35,62.07a2.54,2.54,0,0,0-.69-1.26,2,2,0,0,1-.49-1.32,1.53,1.53,0,0,1,1-1.54A10.51,10.51,0,0,1,39,57.32a17.49,17.49,0,0,1,3.25.28,2.63,2.63,0,0,1,1.33.7,1.7,1.7,0,0,1,.51,1.22,1.45,1.45,0,0,1-.6,1.16,9.25,9.25,0,0,1-1.5.6,3.26,3.26,0,0,0-1.31.86,9.41,9.41,0,0,0-1.25,2l-5.37,10.4q-2.36,4.56-4.17,4.56a2.56,2.56,0,0,1-1.7-1A21,21,0,0,1,25.82,74.85Z"/>
        <path d="M54.65,57.3c.76,0,1.23.11,1.39.33a5.85,5.85,0,0,1,.44,2.06c.07.63.29.94.67.94s.55-.2.81-.62a5.17,5.17,0,0,1,4.49-2.69,4.32,4.32,0,0,1,3.2,1.27,5,5,0,0,1,1,1.6A5.07,5.07,0,0,1,67,62.07a5.17,5.17,0,0,1-.47,2.08,5.39,5.39,0,0,1-1.23,1.75A4.72,4.72,0,0,1,62,67.21a3.94,3.94,0,0,1-1.84-.39,9.33,9.33,0,0,1-1.94-1.57,1.26,1.26,0,0,0-.84-.45.8.8,0,0,0-.79.68,13.85,13.85,0,0,0-.18,2.84,26.54,26.54,0,0,0,.24,4.43,1.71,1.71,0,0,0,.42.89,3.22,3.22,0,0,0,1.15.51c1.29.38,1.93,1.06,1.93,2a2.16,2.16,0,0,1-.62,1.53,3.06,3.06,0,0,1-1.66.87,31.49,31.49,0,0,1-6,.43A19.23,19.23,0,0,1,46,78.34c-1.18-.41-1.77-1.1-1.77-2.09a2.48,2.48,0,0,1,1.29-2,3.21,3.21,0,0,0,.86-.74,2.06,2.06,0,0,0,.33-.91,51.31,51.31,0,0,0,.34-7.24A3.14,3.14,0,0,0,46.83,64,1.53,1.53,0,0,0,46,63.3c-1-.4-1.52-1-1.52-1.74a2.16,2.16,0,0,1,1-1.78A13,13,0,0,1,49,58.31,21.06,21.06,0,0,1,54.65,57.3Z"/>
        <path d="M80.88,57.15a11.87,11.87,0,0,1,6.9,2A11,11,0,0,1,91.2,63a10.61,10.61,0,0,1,1.33,5.11q0,4.67-4.28,7.93a15,15,0,0,1-9.34,3.11,11.81,11.81,0,0,1-8.32-2.92,9.56,9.56,0,0,1-3.09-7.39,10.4,10.4,0,0,1,3.78-8.29A14.22,14.22,0,0,1,80.88,57.15Zm-1.71,5a1.88,1.88,0,0,0-1.52.69,2.87,2.87,0,0,0-.57,1.86,17.07,17.07,0,0,0,.49,3.51,26.85,26.85,0,0,0,1.14,3.95,2.3,2.3,0,0,0,2.17,1.76q1.92,0,1.92-2.43a19.67,19.67,0,0,0-.49-4.1,16.75,16.75,0,0,0-1.17-3.74A2.26,2.26,0,0,0,79.17,62.12Z"/>
        <path d="M120.13,65.1l.31,7.56a2.92,2.92,0,0,0,.26,1.27A10.28,10.28,0,0,0,122,75.5a1.38,1.38,0,0,1,.32,1c0,.94-.57,1.6-1.7,2a22.24,22.24,0,0,1-6.06.56,12.08,12.08,0,0,1-4.19-.51,1.82,1.82,0,0,1-1.27-1.74,1.3,1.3,0,0,1,.13-.64c.09-.13.38-.47.86-1s.86-2.6.86-6a10.56,10.56,0,0,0-.69-4.39,2.08,2.08,0,0,0-2.06-1.13,2.06,2.06,0,0,0-2,.92,7.25,7.25,0,0,0-.54,3.35v4.26a6.2,6.2,0,0,0,.16,1.74,10.87,10.87,0,0,0,1.06,1.82,1.64,1.64,0,0,1,.24.88c0,.85-.59,1.47-1.76,1.87a18.46,18.46,0,0,1-5.5.6q-6,0-6-2.47a1.41,1.41,0,0,1,.18-.78A3.59,3.59,0,0,1,95,75a1.84,1.84,0,0,0,.71-.94A17.15,17.15,0,0,0,96,71.48q.15-2.45.15-5.13a5.32,5.32,0,0,0-.23-1.95,3.55,3.55,0,0,0-1.3-1.12,1.62,1.62,0,0,1-.17-2.83,14.39,14.39,0,0,1,3.09-1.39c1.38-.48,2.7-.89,4-1.23a12.42,12.42,0,0,1,2.62-.51,1.4,1.4,0,0,1,1.08.48,1.53,1.53,0,0,1,.44,1.11c0,.05,0,.23,0,.54,0,.12,0,.22,0,.3,0,.69.29,1,.86,1,.34,0,.71-.27,1.1-.81a6.12,6.12,0,0,1,5.37-2.67,7.68,7.68,0,0,1,4.64,1.42,5.48,5.48,0,0,1,1.89,2.38A13,13,0,0,1,120.13,65.1Z"/>
        <path d="M146.43,56a1.17,1.17,0,0,1,.95.62,2.5,2.5,0,0,1,.4,1.43,4.66,4.66,0,0,1-.54,2,5.71,5.71,0,0,1-1.32,1.79c-.34.28-.5.51-.5.68s0,.22,0,.52a5,5,0,0,1,.07.82,5.85,5.85,0,0,1-1.72,4.29,10.4,10.4,0,0,1-4,2.41,16.07,16.07,0,0,1-5.36.86,10.7,10.7,0,0,1-1.39-.09c-.37,0-.66-.06-.86-.06a1,1,0,0,0-1.14,1.14,1.29,1.29,0,0,0,.74,1.15,4.21,4.21,0,0,0,2,.41,19,19,0,0,0,2.53-.23,42,42,0,0,1,5.2-.5,7.72,7.72,0,0,1,3.94.84,4.74,4.74,0,0,1,1.77,1.81,5,5,0,0,1,.65,2.49,6.79,6.79,0,0,1-1.59,4.21,11.13,11.13,0,0,1-5.56,3.57,22.36,22.36,0,0,1-6.66.9,17.58,17.58,0,0,1-7.73-1.46,6,6,0,0,1-2.2-1.81,3.9,3.9,0,0,1-.81-2.32,2.73,2.73,0,0,1,.46-1.54,2.57,2.57,0,0,1,1.17-1c.43-.18.64-.36.64-.56s-.17-.39-.53-.69a4,4,0,0,1-1.72-3,3.51,3.51,0,0,1,.83-2.12,5.62,5.62,0,0,1,2.05-1.7c.34-.17.51-.34.51-.52s-.21-.5-.64-.9a6.28,6.28,0,0,1-2.21-4.77,5.31,5.31,0,0,1,.78-2.75,6.91,6.91,0,0,1,2.18-2.23,13.7,13.7,0,0,1,7.67-2.26,19,19,0,0,1,3.74.43,16.62,16.62,0,0,0,2.68.39,7.52,7.52,0,0,0,4.67-1.85A1.52,1.52,0,0,1,146.43,56ZM130.08,80c-.36,0-.54.21-.54.62a2.34,2.34,0,0,0,1.38,2.06,7.18,7.18,0,0,0,3.57.78,7.92,7.92,0,0,0,3.29-.56,1.72,1.72,0,0,0,1.2-1.57,1.12,1.12,0,0,0-.46-.91,1.68,1.68,0,0,0-1.11-.38,7.73,7.73,0,0,0-1,.09,16.93,16.93,0,0,1-2.69.19,20.25,20.25,0,0,1-2.1-.1,9.15,9.15,0,0,1-1.23-.18Zm4.68-19.55a2.08,2.08,0,0,0-1.72.82,3.34,3.34,0,0,0-.66,2.17,8.28,8.28,0,0,0,.71,3.71,2.1,2.1,0,0,0,1.87,1.4,2.14,2.14,0,0,0,1.8-.87,3.71,3.71,0,0,0,.67-2.33,7.5,7.5,0,0,0-.7-3.58A2.25,2.25,0,0,0,134.76,60.46Z"/>
        <path d="M185.14,51V70.58a10.11,10.11,0,0,0,.14,2.15,1.93,1.93,0,0,0,.79.92,4.16,4.16,0,0,1,1,.95,1.74,1.74,0,0,1,.27,1c0,1.14-1.16,2-3.46,2.62a25.16,25.16,0,0,1-6.23.79,1.35,1.35,0,0,1-.89-.2,1.68,1.68,0,0,1-.27-1c-.07-.73-.33-1.1-.78-1.1a2.27,2.27,0,0,0-1.13.65,7.54,7.54,0,0,1-4.88,1.78,8.56,8.56,0,0,1-6-2.47,12.29,12.29,0,0,1-2.54-3.41,11.09,11.09,0,0,1-1.29-5.1,11.24,11.24,0,0,1,1.68-5.84,10.52,10.52,0,0,1,4.21-4,9.55,9.55,0,0,1,4.4-1.16,9,9,0,0,1,4.47,1.12,1.14,1.14,0,0,0,.52.17c.45,0,.68-.61.68-1.84a1.78,1.78,0,0,0-.37-1.22,3,3,0,0,0-1.34-.72c-.91-.28-1.36-.78-1.36-1.5,0-1,1-1.86,3.12-2.45a34.8,34.8,0,0,1,7.37-1.53C184.53,49.2,185.14,49.79,185.14,51Zm-11.66,10.8a2.5,2.5,0,0,0-2.22,1.48,10.71,10.71,0,0,0-1.09,5.2,7.63,7.63,0,0,0,1.05,4.41,2.37,2.37,0,0,0,2,1.07,2.14,2.14,0,0,0,2-1.62,15.37,15.37,0,0,0,.64-5.23c0-2.21-.25-3.68-.76-4.41A2,2,0,0,0,173.48,61.77Z"/>
        <path d="M200.59,58.66V71.14a6.38,6.38,0,0,0,.27,2.17,2.94,2.94,0,0,0,1.08,1.27,3.49,3.49,0,0,1,.89.8,1.49,1.49,0,0,1,.23.85q0,2.77-7,2.77a23.84,23.84,0,0,1-6-.6,2.36,2.36,0,0,1-1.21-.75,1.83,1.83,0,0,1-.46-1.23,1.5,1.5,0,0,1,.23-.9,6.79,6.79,0,0,1,1.2-1,2.76,2.76,0,0,0,.95-1.61,14.59,14.59,0,0,0,.28-3.33c0-.47,0-1.36-.1-2.65s-.11-2.06-.14-2.27a1.75,1.75,0,0,0-1.2-1.35,1.86,1.86,0,0,1-1.33-1.72c0-.91.58-1.58,1.74-2A38.86,38.86,0,0,1,195.15,58a20.3,20.3,0,0,1,4.26-.65C200.2,57.32,200.59,57.77,200.59,58.66Zm-4.25-9.41a5.43,5.43,0,0,1,3,.7,2.13,2.13,0,0,1,1.1,1.88,3,3,0,0,1-1.66,2.62A12.48,12.48,0,0,1,192.4,56a4.54,4.54,0,0,1-2.49-.6,2.41,2.41,0,0,1-1.24-2.15c0-1.23.88-2.2,2.64-2.9A13.67,13.67,0,0,1,196.34,49.25Z"/>
        <path d="M214.36,57.3c.76,0,1.22.11,1.39.33a5.85,5.85,0,0,1,.44,2.06c.07.63.29.94.66.94s.56-.2.82-.62a5.15,5.15,0,0,1,4.49-2.69,4.32,4.32,0,0,1,3.2,1.27,5,5,0,0,1,1,1.6,4.74,4.74,0,0,1-.09,4A5.23,5.23,0,0,1,225,65.9a4.72,4.72,0,0,1-3.37,1.31,3.89,3.89,0,0,1-1.84-.39,9.33,9.33,0,0,1-2-1.57,1.26,1.26,0,0,0-.83-.45.79.79,0,0,0-.79.68,13.85,13.85,0,0,0-.18,2.84,26.54,26.54,0,0,0,.24,4.43,1.8,1.8,0,0,0,.41.89,3.31,3.31,0,0,0,1.15.51c1.29.38,1.94,1.06,1.94,2a2.13,2.13,0,0,1-.63,1.53,3,3,0,0,1-1.65.87,31.54,31.54,0,0,1-6.06.43,19.32,19.32,0,0,1-5.78-.66c-1.17-.41-1.76-1.1-1.76-2.09a2.48,2.48,0,0,1,1.29-2,3.21,3.21,0,0,0,.86-.74,2.07,2.07,0,0,0,.32-.91,49.34,49.34,0,0,0,.35-7.24,3.29,3.29,0,0,0-.22-1.39,1.53,1.53,0,0,0-.86-.67c-1-.4-1.52-1-1.52-1.74a2.16,2.16,0,0,1,1.05-1.78,12.93,12.93,0,0,1,3.52-1.47A21.13,21.13,0,0,1,214.36,57.3Z"/>
        <path d="M247.23,69h-10.1c-.44,0-.66.19-.66.58A3.44,3.44,0,0,0,238,72.16a5.08,5.08,0,0,0,3.26,1.23,7.74,7.74,0,0,0,2.28-.39,7.6,7.6,0,0,0,2.08-1,2.28,2.28,0,0,1,1.16-.52,1.46,1.46,0,0,1,1.09.69,2.28,2.28,0,0,1,.54,1.42,3.91,3.91,0,0,1-1.93,3,12.85,12.85,0,0,1-7.89,2.55A11.17,11.17,0,0,1,231,76.53a11,11,0,0,1-3.72-8.23,10.46,10.46,0,0,1,2.19-6.49,11.47,11.47,0,0,1,4.82-3.71,12,12,0,0,1,4.74-.93A10.67,10.67,0,0,1,246,59.49a9.25,9.25,0,0,1,2.32,2.75,7.15,7.15,0,0,1,1,3.53,4.12,4.12,0,0,1-.58,2.29A1.74,1.74,0,0,1,247.23,69Zm-9.54-4h2.17c.92,0,1.38-.42,1.38-1.27a2.73,2.73,0,0,0-.67-1.89,2.26,2.26,0,0,0-1.74-.73,2.22,2.22,0,0,0-1.89,1,3,3,0,0,0-.65,1.78,1.1,1.1,0,0,0,.32.9A1.7,1.7,0,0,0,237.69,64.93Z"/>
        <path d="M263,57.17a12,12,0,0,1,6.74,1.92,5.31,5.31,0,0,1,2.77,4.53,3.87,3.87,0,0,1-1.29,3,4.15,4.15,0,0,1-2.9,1.09,3.07,3.07,0,0,1-2.68-1.28,10.44,10.44,0,0,1-.82-2.09,2.23,2.23,0,0,0-2.21-1.8,2.43,2.43,0,0,0-2,1,4,4,0,0,0-.8,2.53,6.55,6.55,0,0,0,1.84,4.58,5,5,0,0,0,3.78,1.61,7.72,7.72,0,0,0,1.81-.18,11,11,0,0,0,1.91-.74,1.47,1.47,0,0,1,.67-.15,1.67,1.67,0,0,1,1.29.68,2.5,2.5,0,0,1,.55,1.61,3.39,3.39,0,0,1-.79,2,7.39,7.39,0,0,1-2.08,1.86,12.32,12.32,0,0,1-6.6,1.72,11.44,11.44,0,0,1-7.54-2.58,10.27,10.27,0,0,1-4-8.31,10.09,10.09,0,0,1,3.72-8A12.74,12.74,0,0,1,263,57.17Z"/>
        <path d="M286.63,62.8v7.26a3.35,3.35,0,0,0,.63,2.11,2,2,0,0,0,1.63.82,4.92,4.92,0,0,0,2.21-.78,1.21,1.21,0,0,1,.64-.19,1.2,1.2,0,0,1,.95.62,2.41,2.41,0,0,1,.43,1.36,3.41,3.41,0,0,1-1.46,2.55,11,11,0,0,1-7.39,2.6,7.78,7.78,0,0,1-5.52-1.94,6.7,6.7,0,0,1-2.09-5.13V62.16c0-.33,0-.54-.12-.61a1,1,0,0,0-.61-.12h-2.21a1.06,1.06,0,0,1-.8-.21,1.7,1.7,0,0,1-.17-.95v-1a1.08,1.08,0,0,1,.5-.94l9.83-6.19A1.94,1.94,0,0,1,284,52h1.74a.86.86,0,0,1,.72.24,1.83,1.83,0,0,1,.18,1v2.9a1.24,1.24,0,0,0,.2.84,1.35,1.35,0,0,0,.87.19h4a1.34,1.34,0,0,1,1,.29,2.16,2.16,0,0,1,.24,1.24V60a2.45,2.45,0,0,1-.3,1.42,1.31,1.31,0,0,1-1.1.4h-3.89a.84.84,0,0,0-1,1Z"/>
        <path d="M306.18,58.66V71.14a6.38,6.38,0,0,0,.27,2.17,2.94,2.94,0,0,0,1.08,1.27,3.49,3.49,0,0,1,.89.8,1.42,1.42,0,0,1,.23.85q0,2.77-7,2.77a23.84,23.84,0,0,1-6-.6,2.41,2.41,0,0,1-1.21-.75,1.83,1.83,0,0,1-.46-1.23,1.5,1.5,0,0,1,.23-.9,6.6,6.6,0,0,1,1.21-1,2.8,2.8,0,0,0,.94-1.61,14.59,14.59,0,0,0,.28-3.33c0-.47,0-1.36-.1-2.65s-.11-2.06-.14-2.27a1.75,1.75,0,0,0-1.2-1.35,1.86,1.86,0,0,1-1.33-1.72c0-.91.58-1.58,1.74-2A38.56,38.56,0,0,1,300.75,58a20.14,20.14,0,0,1,4.25-.65C305.79,57.32,306.18,57.77,306.18,58.66Zm-4.25-9.41a5.43,5.43,0,0,1,3,.7,2.13,2.13,0,0,1,1.1,1.88,2.94,2.94,0,0,1-1.66,2.62A12.46,12.46,0,0,1,298,56a4.56,4.56,0,0,1-2.5-.6,2.41,2.41,0,0,1-1.24-2.15c0-1.23.88-2.2,2.64-2.9A13.71,13.71,0,0,1,301.93,49.25Z"/>
        <path d="M323.22,57.15a11.83,11.83,0,0,1,6.89,2A11,11,0,0,1,333.53,63a10.5,10.5,0,0,1,1.33,5.11q0,4.67-4.28,7.93a15,15,0,0,1-9.34,3.11,11.77,11.77,0,0,1-8.31-2.92,9.53,9.53,0,0,1-3.1-7.39,10.4,10.4,0,0,1,3.78-8.29A14.23,14.23,0,0,1,323.22,57.15Zm-1.72,5a1.88,1.88,0,0,0-1.52.69,2.87,2.87,0,0,0-.57,1.86,17.07,17.07,0,0,0,.49,3.51,25.63,25.63,0,0,0,1.15,3.95,2.28,2.28,0,0,0,2.17,1.76c1.27,0,1.91-.81,1.91-2.43a19.67,19.67,0,0,0-.49-4.1,16.75,16.75,0,0,0-1.17-3.74A2.26,2.26,0,0,0,321.5,62.12Z"/>
        <path d="M362.47,65.1l.3,7.56a3,3,0,0,0,.27,1.27,10.63,10.63,0,0,0,1.32,1.57,1.38,1.38,0,0,1,.32,1c0,.94-.57,1.6-1.7,2a22.24,22.24,0,0,1-6.06.56,12.08,12.08,0,0,1-4.19-.51,1.81,1.81,0,0,1-1.26-1.74,1.2,1.2,0,0,1,.13-.64,10.38,10.38,0,0,1,.86-1q.85-.9.85-6a10.76,10.76,0,0,0-.68-4.39,2.1,2.1,0,0,0-2.07-1.13,2.08,2.08,0,0,0-2,.92,7.25,7.25,0,0,0-.54,3.35v4.26a6.2,6.2,0,0,0,.16,1.74,10.46,10.46,0,0,0,1.07,1.82,1.72,1.72,0,0,1,.23.88c0,.85-.59,1.47-1.76,1.87a18.42,18.42,0,0,1-5.5.6q-6,0-6-2.47a1.41,1.41,0,0,1,.18-.78,3.59,3.59,0,0,1,.87-.76,1.84,1.84,0,0,0,.71-.94,17.15,17.15,0,0,0,.28-2.59q.15-2.45.15-5.13a5.32,5.32,0,0,0-.23-1.95,3.55,3.55,0,0,0-1.3-1.12,1.62,1.62,0,0,1-.17-2.83,14.3,14.3,0,0,1,3.1-1.39c1.37-.48,2.7-.89,4-1.23a12.53,12.53,0,0,1,2.62-.51,1.43,1.43,0,0,1,1.09.48,1.57,1.57,0,0,1,.44,1.11q0,.08,0,.54c0,.12,0,.22,0,.3,0,.69.29,1,.86,1,.35,0,.71-.27,1.1-.81a6.12,6.12,0,0,1,5.37-2.67,7.68,7.68,0,0,1,4.64,1.42,5.48,5.48,0,0,1,1.89,2.38A13.21,13.21,0,0,1,362.47,65.1Z"/>
      </g>
    </svg>

  </>
}

export default Label
