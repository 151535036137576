// import React from 'react'

import './index.css'
import logo from './logo@2x.png'

function Brand (props) {

  return <>
    <div className="logoWrapper">
      <img src={ logo } alt="Tasty Toobs" />
      <img id="headerMessage"
        src="./message@2x.png"
        alt=""
        role="presentation"
      />
    </div>
  </>
}

export default Brand
