// import React from 'react'

import './index.css'

function Label (props) {
  const { angle, index = 0 } = props

  return <>
    <svg id="label-04"
      className="label"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 741 91.14"
      width="741"
      height="91.14"
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        transformOrigin: 'center right',
        transform: `translateY(50%) rotate(${angle}deg)`,
        animationDelay: `${1700 + (index * 200)}ms`,
      }}
    >
      <g>
    <path d="M219.43,75l.62,1.5a3.4,3.4,0,0,0,1.44,1.74A2.38,2.38,0,0,1,223,80.25c0,1.84-2.75,2.75-8.25,2.75a22.73,22.73,0,0,1-6.28-.66,2.57,2.57,0,0,1-1.17-.76,1.71,1.71,0,0,1-.44-1.13,1.34,1.34,0,0,1,.21-.82,9.1,9.1,0,0,1,1.13-1.1,1.26,1.26,0,0,0,.53-.94A7.07,7.07,0,0,0,208,75.1l-1.23-2.63a17.21,17.21,0,0,0-1.19-2.26.92.92,0,0,0-1.66.07,12.83,12.83,0,0,0-.91,2.19l-1,3a6.73,6.73,0,0,0-.43,1.94,1.11,1.11,0,0,0,.9,1.13c1.21.39,1.81,1.08,1.81,2.07a1.91,1.91,0,0,1-1.4,1.84,13.3,13.3,0,0,1-4.6.56,8.74,8.74,0,0,1-3.24-.47,2.18,2.18,0,0,1-1.61-2.06,1.9,1.9,0,0,1,.29-1.08,6.18,6.18,0,0,1,1.17-1.2,3.89,3.89,0,0,0,.89-1,19.24,19.24,0,0,0,1-2.3l3.76-10c1.87-4.65,3-7.43,3.39-8.31a23.09,23.09,0,0,1,1.2-2.39,2.89,2.89,0,0,1,1.06-1.08,3.21,3.21,0,0,1,1.54-.32,3.55,3.55,0,0,1,2.08.56A4.25,4.25,0,0,1,211,55.18l4.25,10.1a4.84,4.84,0,0,0,.52,1,.57.57,0,0,0,.47.22A.91.91,0,0,0,217,66a25.16,25.16,0,0,0,1.23-2.77L221.19,56a7,7,0,0,1,1.56-2.61,3.33,3.33,0,0,1,2.24-.64,3.27,3.27,0,0,1,2.14.65,5.58,5.58,0,0,1,1.43,2.27l7.9,20.22a8.25,8.25,0,0,0,.68,1.47,4.7,4.7,0,0,0,1.15.74,2,2,0,0,1,1.1,1.89q0,3-7.52,3a20.45,20.45,0,0,1-5.57-.54,2.09,2.09,0,0,1-1.65-2.12,2.45,2.45,0,0,1,.08-.72,6.88,6.88,0,0,1,.63-1,1.87,1.87,0,0,0,.26-1,15.43,15.43,0,0,0-1.44-4.47l-.89-2.06a12,12,0,0,0-.74-1.56.58.58,0,0,0-.52-.27c-.56,0-1.13.8-1.72,2.39l-.88,2.45a1.15,1.15,0,0,0-.11.4A1.42,1.42,0,0,0,219.43,75Z"/>
    <path d="M175.19,62.87h10.1c.44,0,.67-.19.67-.58a3.45,3.45,0,0,0-1.52-2.63,5.07,5.07,0,0,0-3.25-1.24,7.83,7.83,0,0,0-2.29.4,7.39,7.39,0,0,0-2.07,1,2.35,2.35,0,0,1-1.16.52,1.46,1.46,0,0,1-1.1-.69,2.28,2.28,0,0,1-.54-1.42,3.93,3.93,0,0,1,1.94-3,12.8,12.8,0,0,1,7.88-2.55,11.17,11.17,0,0,1,7.56,2.62,10.72,10.72,0,0,1,2.45,3.11,10.87,10.87,0,0,1,1.27,5.12A10.44,10.44,0,0,1,192.94,70a11.53,11.53,0,0,1-4.81,3.72,12,12,0,0,1-4.75.92,10.63,10.63,0,0,1-6.94-2.32,9.27,9.27,0,0,1-2.32-2.75,7.2,7.2,0,0,1-1-3.52,4.12,4.12,0,0,1,.58-2.29A1.75,1.75,0,0,1,175.19,62.87Zm9.54,4h-2.17c-.91,0-1.37.42-1.37,1.27a2.72,2.72,0,0,0,.66,1.89,2.29,2.29,0,0,0,1.74.73,2.19,2.19,0,0,0,1.89-1,3,3,0,0,0,.65-1.78,1.13,1.13,0,0,0-.31-.9A1.76,1.76,0,0,0,184.73,66.89Z"/>
    <path d="M165.7,83.15A4.61,4.61,0,0,1,162,81.37a5.71,5.71,0,0,1-1.29-3.74A11.79,11.79,0,0,1,162,72.38a17.13,17.13,0,0,1,3.69-4.91,3.74,3.74,0,0,1,2.44-1.23,2.4,2.4,0,0,1,1.39.42,1.17,1.17,0,0,1,.61,1,1.1,1.1,0,0,1-.43.73,14.38,14.38,0,0,0-1.92,2.35,3.8,3.8,0,0,0-.55,1.9,1.55,1.55,0,0,0,.73,1.23,7.52,7.52,0,0,1,1.93,2.06,4.61,4.61,0,0,1,.57,2.34,4.81,4.81,0,0,1-1.35,3.51A4.56,4.56,0,0,1,165.7,83.15Z"/>
    <path d="M148.19,74.52c-.76,0-1.22-.11-1.39-.33a5.85,5.85,0,0,1-.44-2.06c-.07-.63-.29-.94-.66-.94s-.56.2-.82.62a5.14,5.14,0,0,1-4.49,2.68,4.31,4.31,0,0,1-3.2-1.26,5,5,0,0,1-1-1.6,5.11,5.11,0,0,1-.39-1.88,5,5,0,0,1,.48-2.08,5.13,5.13,0,0,1,1.22-1.75,4.72,4.72,0,0,1,3.37-1.31,3.89,3.89,0,0,1,1.84.39,9.33,9.33,0,0,1,1.95,1.57,1.26,1.26,0,0,0,.83.45.79.79,0,0,0,.79-.68,13.9,13.9,0,0,0,.18-2.85,26.47,26.47,0,0,0-.24-4.42,1.8,1.8,0,0,0-.41-.89,3.13,3.13,0,0,0-1.15-.51c-1.29-.38-1.94-1.06-1.94-2a2.13,2.13,0,0,1,.63-1.53,3,3,0,0,1,1.65-.87,31.54,31.54,0,0,1,6.06-.43,19.32,19.32,0,0,1,5.78.66,2.18,2.18,0,0,1,1.76,2.09,2.48,2.48,0,0,1-1.29,2,3,3,0,0,0-.86.74,2.07,2.07,0,0,0-.32.91,49.34,49.34,0,0,0-.35,7.24,3.32,3.32,0,0,0,.22,1.39,1.57,1.57,0,0,0,.86.67c1,.4,1.52,1,1.52,1.74a2.13,2.13,0,0,1-1,1.77,12.71,12.71,0,0,1-3.52,1.48A21.54,21.54,0,0,1,148.19,74.52Z"/>
    <path d="M115.32,62.87h10.1c.44,0,.66-.19.66-.58a3.44,3.44,0,0,0-1.51-2.63,5.1,5.1,0,0,0-3.26-1.24,7.77,7.77,0,0,0-2.28.4,7.6,7.6,0,0,0-2.08,1,2.28,2.28,0,0,1-1.16.52,1.44,1.44,0,0,1-1.09-.69,2.28,2.28,0,0,1-.54-1.42,3.91,3.91,0,0,1,1.93-3A12.85,12.85,0,0,1,124,52.67a11.18,11.18,0,0,1,7.56,2.62,11,11,0,0,1,3.72,8.23,10.39,10.39,0,0,1-2.2,6.48,11.47,11.47,0,0,1-4.81,3.72,12,12,0,0,1-4.75.92,10.61,10.61,0,0,1-6.93-2.32,9.13,9.13,0,0,1-2.32-2.75,7.09,7.09,0,0,1-1-3.52,4.12,4.12,0,0,1,.58-2.29A1.73,1.73,0,0,1,115.32,62.87Zm9.54,4h-2.17c-.92,0-1.38.42-1.38,1.27a2.73,2.73,0,0,0,.67,1.89,2.26,2.26,0,0,0,1.74.73,2.22,2.22,0,0,0,1.89-1,3,3,0,0,0,.64-1.78,1.1,1.1,0,0,0-.31-.9A1.72,1.72,0,0,0,124.86,66.89Z"/>
    <path d="M88.19,70.65H85.52a1.65,1.65,0,0,1-1-.21.66.66,0,0,1-.25-.67l.17-2.56a1.76,1.76,0,0,1,.24-1,1.27,1.27,0,0,1,.86-.2l.71,0c.25,0,.47,0,.64,0a1.27,1.27,0,0,0,1.23-.59,5.32,5.32,0,0,0,.32-2.24,12.55,12.55,0,0,0-.5-3.8,1.93,1.93,0,0,0-.56-1,3.21,3.21,0,0,0-1.2-.55c-1.26-.37-1.89-1.07-1.89-2.08s.74-1.85,2.2-2.32a25.79,25.79,0,0,1,7.23-.71q7.45,0,7.46,3a1.62,1.62,0,0,1-.31,1.08,4.35,4.35,0,0,1-1.37.79,2.36,2.36,0,0,0-1.37,1.91,25.82,25.82,0,0,0-.37,4.51,2.28,2.28,0,0,0,.38,1.5,1.73,1.73,0,0,0,1.36.4,5.61,5.61,0,0,1,1.48.11.5.5,0,0,1,.3.57l-.17,2.71a1.37,1.37,0,0,1-.22.83,2.21,2.21,0,0,1-1,.2c-.53,0-.8.29-.8.77a4.2,4.2,0,0,0,.58,1.38,7.3,7.3,0,0,1,1,3.57,5.51,5.51,0,0,1-1.81,4.27C97.12,82,94.6,82.81,91.3,82.81a10.23,10.23,0,0,1-6.49-1.91,4.33,4.33,0,0,1-1.87-3.42,3.24,3.24,0,0,1,.93-2.35,3,3,0,0,1,2.3-1,4.81,4.81,0,0,1,3,1.08,20.41,20.41,0,0,1,1.63,1.69,2.62,2.62,0,0,0,1.85,1,1.38,1.38,0,0,0,1-.43,1.41,1.41,0,0,0,.43-1q0-1.7-3.22-3.63a7.85,7.85,0,0,1-1.34-.95,3.14,3.14,0,0,1-.64-1C88.77,70.73,88.54,70.65,88.19,70.65Z"/>
    <path d="M70,73.16V60.68a6.38,6.38,0,0,0-.27-2.17,2.9,2.9,0,0,0-1.09-1.27,3.67,3.67,0,0,1-.89-.8,1.49,1.49,0,0,1-.22-.85q0-2.78,7-2.77a23.89,23.89,0,0,1,6,.6,2.41,2.41,0,0,1,1.21.75,1.83,1.83,0,0,1,.46,1.23,1.5,1.5,0,0,1-.24.9,6.08,6.08,0,0,1-1.2,1,2.75,2.75,0,0,0-.94,1.61,14.53,14.53,0,0,0-.28,3.33c0,.47,0,1.36.09,2.65s.11,2.06.14,2.27A1.78,1.78,0,0,0,81,68.54a1.87,1.87,0,0,1,1.33,1.72c0,.9-.58,1.58-1.74,2a39,39,0,0,1-5.16,1.57,20,20,0,0,1-4.25.64C70.34,74.49,70,74.05,70,73.16Zm4.25,9.41a5.4,5.4,0,0,1-3-.7A2.13,2.13,0,0,1,70.14,80a2.94,2.94,0,0,1,1.65-2.62,12.48,12.48,0,0,1,6.34-1.52,4.5,4.5,0,0,1,2.49.6,2.4,2.4,0,0,1,1.25,2.15c0,1.23-.88,2.2-2.64,2.9A13.71,13.71,0,0,1,74.2,82.57Z"/>
    <path d="M40.15,66.72l-.3-7.56a3,3,0,0,0-.27-1.27,10.63,10.63,0,0,0-1.32-1.57,1.34,1.34,0,0,1-.32-1c0-.94.56-1.6,1.69-2a21.94,21.94,0,0,1,6.06-.56,12,12,0,0,1,4.19.51,1.81,1.81,0,0,1,1.27,1.74,1.2,1.2,0,0,1-.13.64,12.26,12.26,0,0,1-.86,1q-.85.9-.86,6A10.48,10.48,0,0,0,50,67.1a2.08,2.08,0,0,0,2.06,1.14,2.11,2.11,0,0,0,2-.92A7.25,7.25,0,0,0,54.57,64V59.71A6.1,6.1,0,0,0,54.4,58a10.17,10.17,0,0,0-1.06-1.82,1.64,1.64,0,0,1-.24-.88c0-.85.59-1.47,1.77-1.87a18.37,18.37,0,0,1,5.5-.6q6,0,6,2.47a1.41,1.41,0,0,1-.18.78,4.36,4.36,0,0,1-.87.76,1.78,1.78,0,0,0-.71.94,15.85,15.85,0,0,0-.28,2.59q-.15,2.44-.15,5.13a5.38,5.38,0,0,0,.22,2,3.64,3.64,0,0,0,1.3,1.12,1.61,1.61,0,0,1,.17,2.83,14,14,0,0,1-3.09,1.38c-1.37.49-2.7.9-4,1.24a12.3,12.3,0,0,1-2.62.5A1.41,1.41,0,0,1,55.07,74a1.6,1.6,0,0,1-.44-1.12s0-.22,0-.53a1.73,1.73,0,0,0,0-.3c0-.69-.28-1-.86-1-.34,0-.71.27-1.09.81a6.12,6.12,0,0,1-5.37,2.67,7.66,7.66,0,0,1-4.64-1.42,5.39,5.39,0,0,1-1.89-2.38A13.27,13.27,0,0,1,40.15,66.72Z"/>
    <path d="M16.84,62.87h10.1c.44,0,.66-.19.66-.58a3.44,3.44,0,0,0-1.51-2.63,5.1,5.1,0,0,0-3.26-1.24,7.77,7.77,0,0,0-2.28.4,7.6,7.6,0,0,0-2.08,1,2.28,2.28,0,0,1-1.16.52,1.46,1.46,0,0,1-1.09-.69,2.28,2.28,0,0,1-.54-1.42,3.91,3.91,0,0,1,1.93-3,12.85,12.85,0,0,1,7.89-2.55,11.18,11.18,0,0,1,7.56,2.62,11,11,0,0,1,3.72,8.23A10.39,10.39,0,0,1,34.58,70a11.4,11.4,0,0,1-4.81,3.72,12,12,0,0,1-4.74.92,10.61,10.61,0,0,1-6.94-2.32,9.13,9.13,0,0,1-2.32-2.75,7.09,7.09,0,0,1-1-3.52,4.12,4.12,0,0,1,.58-2.29A1.73,1.73,0,0,1,16.84,62.87Zm9.54,4H24.21c-.92,0-1.38.42-1.38,1.27a2.73,2.73,0,0,0,.67,1.89,2.26,2.26,0,0,0,1.74.73,2.22,2.22,0,0,0,1.89-1,3,3,0,0,0,.64-1.78,1.1,1.1,0,0,0-.31-.9A1.72,1.72,0,0,0,26.38,66.89Z"/>
    <path d="M7.92,63.09a4.66,4.66,0,0,1-2.08-.51,4.9,4.9,0,0,1-1.72-1.36,8,8,0,0,1-1.63-5.16,13.75,13.75,0,0,1,.63-3.85,16.82,16.82,0,0,1,1.67-3.88q1.69-2.84,3.54-2.84A2.35,2.35,0,0,1,9.8,46a1.41,1.41,0,0,1,.66,1.13,4.9,4.9,0,0,1-.82,1.66,9.42,9.42,0,0,0-.87,1.74A4.44,4.44,0,0,0,8.4,52,1,1,0,0,0,9.13,53a6.93,6.93,0,0,1,2,1.06,5,5,0,0,1,1.8,4,4.73,4.73,0,0,1-1.26,3.31A5,5,0,0,1,7.92,63.09Z"/>
    <path d="M215.63,31.25V24a3.32,3.32,0,0,0-.64-2.11,2,2,0,0,0-1.62-.81,5,5,0,0,0-2.21.77,1.25,1.25,0,0,1-.65.19,1.17,1.17,0,0,1-.94-.62,2.28,2.28,0,0,1-.43-1.35,3.43,3.43,0,0,1,1.46-2.56A11,11,0,0,1,218,14.9a7.76,7.76,0,0,1,5.52,2A6.66,6.66,0,0,1,225.59,22V31.9a1,1,0,0,0,.12.61,1,1,0,0,0,.62.12h2.21c.41,0,.68.06.79.2a1.65,1.65,0,0,1,.17,1v.94a1.07,1.07,0,0,1-.49,1l-9.84,6.18a1.87,1.87,0,0,1-.9.2h-1.74a.86.86,0,0,1-.72-.25,1.8,1.8,0,0,1-.18-1v-2.9a1.18,1.18,0,0,0-.21-.84,1.38,1.38,0,0,0-.87-.19h-4a1.34,1.34,0,0,1-1-.29,2.1,2.1,0,0,1-.24-1.23V34.11a2.49,2.49,0,0,1,.3-1.43,1.29,1.29,0,0,1,1.1-.4h3.89a1.16,1.16,0,0,0,.81-.21A1.2,1.2,0,0,0,215.63,31.25Z"/>
    <path d="M195.93,43.75l.28-9c0-1.07-.3-1.61-1-1.61-.35,0-.68.24-1,.71a6.45,6.45,0,0,1-5.57,3,7.2,7.2,0,0,1-5.43-2.08,5.24,5.24,0,0,1-1.28-2.2,14.41,14.41,0,0,1-.42-3.63l-.19-7.75a3.67,3.67,0,0,0-.27-1.48,4.49,4.49,0,0,0-1.15-1.19,1.44,1.44,0,0,1-.62-1.18c0-1.54,2.33-2.32,7-2.32,4.15,0,6.23.73,6.23,2.17a1.18,1.18,0,0,1-.11.56,12.74,12.74,0,0,1-.75,1q-.9,1.19-.9,5.91a11.78,11.78,0,0,0,.54,4.17,2.53,2.53,0,0,0,2.53,1.78,2.14,2.14,0,0,0,1.93-.94,5,5,0,0,0,.46-2.58V23a27.07,27.07,0,0,0-.26-3.11,4.88,4.88,0,0,0-1-1.36,1.53,1.53,0,0,1-.41-1.05q0-2.43,6.32-2.43c4.92,0,7.39.84,7.39,2.52a1.45,1.45,0,0,1-.24.89,5.83,5.83,0,0,1-1.16.95c-.54.39-.92,1.43-1.13,3.13a81.08,81.08,0,0,0-.31,8.43,52.71,52.71,0,0,0,.26,6.4,2.21,2.21,0,0,0,.31,1,2.41,2.41,0,0,0,.85.64c.89.45,1.33,1,1.33,1.58a2,2,0,0,1-1,1.75,14.93,14.93,0,0,1-3.87,1.43,24.25,24.25,0,0,1-6.16,1.08Q195.89,44.83,195.93,43.75Z"/>
    <path d="M165.74,36.9A10.46,10.46,0,0,1,159.41,35a5.58,5.58,0,0,1-1.93-2.31,9.57,9.57,0,0,1-.55-3.58l0-3.2a12,12,0,0,0-.48-4.31,1.38,1.38,0,0,0-.45-.61,3.05,3.05,0,0,0-.9-.23.79.79,0,0,1-.69-.9,3.65,3.65,0,0,1,.83-2.15,6.82,6.82,0,0,1,2.14-1.87,6.57,6.57,0,0,1,3.37-.95,4.44,4.44,0,0,1,3.57,1.55,1.21,1.21,0,0,0,.84.56,2.12,2.12,0,0,0,1.13-.54,8.12,8.12,0,0,1,4.88-1.57,7.81,7.81,0,0,1,4.56,1.21,5.64,5.64,0,0,1,1.8,2.07,5.74,5.74,0,0,1,.67,2.67,5.35,5.35,0,0,1-1.81,4q-2.81,2.7-8.42,2.72a3.13,3.13,0,0,0-1.57.25,1.49,1.49,0,0,0-.32,1.15,8.52,8.52,0,0,0,.47,3.3,1.6,1.6,0,0,0,1.55,1,1.84,1.84,0,0,0,1.15-.39,7.43,7.43,0,0,0,1.25-1.42q1.92-2.72,4-2.72a2.39,2.39,0,0,1,1.72.62,2.14,2.14,0,0,1,.67,1.63,3.28,3.28,0,0,1-.83,2.06,7.31,7.31,0,0,1-2.25,1.81A17.15,17.15,0,0,1,165.74,36.9Zm1.44-12.44a2.18,2.18,0,0,0,1.65-.77,2.69,2.69,0,0,0,.69-1.85,2.6,2.6,0,0,0-.49-1.66,1.57,1.57,0,0,0-1.31-.64c-1.15,0-1.72,1-1.72,2.9a3.34,3.34,0,0,0,.25,1.59A1,1,0,0,0,167.18,24.46Z"/>
    <path d="M127,29l-.3-7.56a3.09,3.09,0,0,0-.27-1.27,11.2,11.2,0,0,0-1.32-1.57,1.32,1.32,0,0,1-.33-1c0-.95.57-1.61,1.7-2a22.24,22.24,0,0,1,6.06-.56,11.75,11.75,0,0,1,4.19.52A1.82,1.82,0,0,1,138,17.31a1.26,1.26,0,0,1-.13.63,10.66,10.66,0,0,1-.86,1q-.86.9-.86,6a10.54,10.54,0,0,0,.69,4.38,2.5,2.5,0,0,0,4,.21,7.39,7.39,0,0,0,.53-3.35V22a6.11,6.11,0,0,0-.16-1.74,10.12,10.12,0,0,0-1.06-1.83,1.61,1.61,0,0,1-.24-.88c0-.84.59-1.47,1.76-1.87a18.46,18.46,0,0,1,5.5-.6q6,0,6,2.47a1.34,1.34,0,0,1-.19.79,3.83,3.83,0,0,1-.87.76,1.83,1.83,0,0,0-.71.93,16.49,16.49,0,0,0-.27,2.59c-.1,1.63-.16,3.35-.16,5.14a5.25,5.25,0,0,0,.23,1.94,3.55,3.55,0,0,0,1.3,1.13,1.61,1.61,0,0,1,.17,2.82A13.9,13.9,0,0,1,149.61,35c-1.38.49-2.7.9-4,1.23a12.31,12.31,0,0,1-2.62.51,1.42,1.42,0,0,1-1.08-.47,1.57,1.57,0,0,1-.44-1.12s0-.22,0-.54c0-.11,0-.21,0-.3,0-.69-.29-1-.86-1-.34,0-.71.27-1.09.82a6.16,6.16,0,0,1-5.37,2.66,7.62,7.62,0,0,1-4.65-1.42A5.46,5.46,0,0,1,127.67,33,13.4,13.4,0,0,1,127,29Z"/>
    <path d="M111.11,43.75,111.3,37c0-.67,0-1.66,0-3,0-1.45,0-2.3-.07-2.56-.1-.59-.4-.88-.9-.88a4.38,4.38,0,0,0-2,.83c-.89.55-1.33,1-1.33,1.23a2,2,0,0,0,.47.86,1.45,1.45,0,0,1,.35.86q0,2.33-5.72,2.32a14.61,14.61,0,0,1-4.68-.6,2.4,2.4,0,0,1-1.14-.82,2,2,0,0,1-.45-1.24,1.38,1.38,0,0,1,.92-1.36,10.44,10.44,0,0,1,1.25-.28,7,7,0,0,0,2.45-1.17c1-.66,1.5-1.15,1.5-1.45A14.07,14.07,0,0,0,100.19,27c-1.21-1.69-2.34-3.15-3.39-4.4a15.87,15.87,0,0,0-1.58-1.72,9.55,9.55,0,0,0-1.58-1.05c-1-.56-1.54-1.19-1.54-1.91a2,2,0,0,1,1-1.78,4.79,4.79,0,0,1,2.67-.65h7a2.51,2.51,0,0,1,2,1L108.32,21c.94,1.2,1.52,1.91,1.73,2.14s.38.35.52.35.29-.13.35-.39a9,9,0,0,0,.08-1.46,3.53,3.53,0,0,0-.21-1.41,3.64,3.64,0,0,0-1-1.06,1.62,1.62,0,0,1-.56-1.33,2.24,2.24,0,0,1,1.59-2.17,14.61,14.61,0,0,1,5.24-.67,25.51,25.51,0,0,1,5.59.37,2.37,2.37,0,0,1,1.24.87,2.16,2.16,0,0,1,.52,1.38,1.7,1.7,0,0,1-.9,1.42,4.77,4.77,0,0,0-1,.75,2,2,0,0,0-.34.82,61.81,61.81,0,0,0-.48,9.28v4.7A11.65,11.65,0,0,0,121,37.7a1.33,1.33,0,0,0,1.21.79c1.18.16,1.77.65,1.77,1.46a1.88,1.88,0,0,1-.78,1.47,8.54,8.54,0,0,1-2.53,1.3q-5.62,2.09-8.34,2.09C111.48,44.81,111.08,44.46,111.11,43.75Z"/>
    <path d="M59.79,26.31l2.83-7.8a31.23,31.23,0,0,1,2.12-4.74,13.78,13.78,0,0,1,2.42-3.12A9.36,9.36,0,0,1,73.88,8,7.7,7.7,0,0,1,79,9.66,4.14,4.14,0,0,1,80.56,13a3.89,3.89,0,0,1-1.06,2.78,3.46,3.46,0,0,1-2.61,1.13,3.24,3.24,0,0,1-2.49-1,2.89,2.89,0,0,1-.61-.84,13.85,13.85,0,0,1-.45-1.61,1.06,1.06,0,0,0-1.07-1,1.39,1.39,0,0,0-1.12.58,2.29,2.29,0,0,0-.45,1.44,9.39,9.39,0,0,0,1.12,3.37l5.18,11A9.85,9.85,0,0,0,78.22,31,6.23,6.23,0,0,0,80,32.05a1.59,1.59,0,0,1,.75.67,2,2,0,0,1,.31,1q0,1.9-3.81,2.6a33.22,33.22,0,0,1-5.77.45,11.06,11.06,0,0,1-3.59-.47,2.22,2.22,0,0,1-1.76-2.13,2.38,2.38,0,0,1,.49-1.27,2.16,2.16,0,0,0,.47-1.22,2.85,2.85,0,0,0-.22-1.07,18.66,18.66,0,0,0-1-1.89c-.54-1-1-1.51-1.44-1.51s-.9.44-1.37,1.32a5.3,5.3,0,0,0-.71,2.55A2.37,2.37,0,0,0,63,32.67a2.81,2.81,0,0,1,.81,1.65c0,.73-.49,1.32-1.46,1.75a9.63,9.63,0,0,1-3.91.66c-3,0-4.55-.73-4.55-2.19a1.76,1.76,0,0,1,.46-1.28A4.38,4.38,0,0,1,56,32.35a3.33,3.33,0,0,0,1.64-1.29A25.5,25.5,0,0,0,59.79,26.31Z"/>
    <path d="M41.42,36.9a11.89,11.89,0,0,1-6.9-2,10.87,10.87,0,0,1-3.41-3.8A10.54,10.54,0,0,1,29.77,26q0-4.66,4.28-7.93A15,15,0,0,1,43.4,14.9a11.83,11.83,0,0,1,8.31,2.92,9.59,9.59,0,0,1,3.09,7.39A10.42,10.42,0,0,1,51,33.51,14.23,14.23,0,0,1,41.42,36.9Zm1.72-5a1.84,1.84,0,0,0,1.51-.7,2.83,2.83,0,0,0,.57-1.86,17.65,17.65,0,0,0-.48-3.51,26.65,26.65,0,0,0-1.15-3.94,2.29,2.29,0,0,0-2.17-1.76c-1.28,0-1.91.8-1.91,2.42A20.38,20.38,0,0,0,40,26.7a16.48,16.48,0,0,0,1.17,3.73A2.27,2.27,0,0,0,43.14,31.94Z"/>
    <path d="M16.46,35.66V24q0-3.74-2.48-3.74a2.36,2.36,0,0,0-1.22.36,2,2,0,0,0-.84.87,13.88,13.88,0,0,0-.47,4.6,31.42,31.42,0,0,0,.24,4.81,1.42,1.42,0,0,0,.3.78,2.73,2.73,0,0,0,.81.57A1.89,1.89,0,0,1,14,33.89c0,.89-.7,1.51-2.11,1.85a32.65,32.65,0,0,1-7.88,1c-1.45,0-2.17-.77-2.17-2.3,0-.16,0-.48,0-1,0-.68.05-1.52.06-2.51V21.48c0-.49-.41-.93-1.24-1.34a1.62,1.62,0,0,1-1.06-1.5c0-1.29,1.53-2.28,4.58-3A23.26,23.26,0,0,1,9.43,15a2.86,2.86,0,0,1,1.41.24,1.16,1.16,0,0,1,.44.92c.07.89.34,1.33.81,1.33a1.09,1.09,0,0,0,.48-.11c.14-.08.58-.37,1.31-.87a8.09,8.09,0,0,1,4.66-1.49q4.13,0,5.82,2.39,1.44,2,1.44,6.72c0,1.18,0,2.18,0,3v1a8.25,8.25,0,0,0,.33,2.72,2.6,2.6,0,0,0,1.22,1.36,4.3,4.3,0,0,1,1.06.69,1,1,0,0,1,.22.71c0,1.08-.82,1.81-2.47,2.17a39.48,39.48,0,0,1-8.42.92C16.88,36.73,16.46,36.37,16.46,35.66Z"/>
  </g>
    </svg>

  </>
}

export default Label
