// import React from 'react'

import './index.css'

 
// It’s okay to link directly to our corporate website for this but have added PDF’s as well if easier:

// Terms of Use: https://www.pepsico.com.au/terms-of-use
// Privacy: https://www.pepsico.com.au/privacy-policy
// Contact Us: https://www.pepsico.com.au/contact
 
// We also need to add the following line: Copyright © The Smith's Snackfood Company Pty Ltd. (My recommendation is to add this in the bottom left hand corner).

function Footer (props) {

  return <>
    <footer>
      <div>
        <p>Of course, we can’t control whether retailers choose to stock delicious Toobs® as it is completely at their discretion.</p>
        <ul className="termsLinks">
          <li>
            <a
              href="https://www.pepsico.com.au/terms-of-use"
              rel="noreferrer noopener"
            >Terms of use<span className="underline" /></a>
          </li>
          <li>
            <a
              href="https://www.pepsico.com.au/privacy-policy"
              rel="noreferrer noopener"
            >Privacy<span className="underline" /></a>
          </li>
          <li>
            <a
              href="https://www.pepsico.com.au/contact"
              rel="noreferrer noopener"
            >Contact US<span className="underline" /></a>
          </li>
        </ul>
        <p>Copyright © The Smith's Snackfood Company Pty Ltd.</p>
      </div>
    </footer>
  </>
}

export default Footer
