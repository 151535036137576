import React, { useEffect, useState } from 'react';
import './App.css'

import Brand from './components/brand'
// import DeleteOMeter from './components/meter'
import Gauge from './components/gauge'
import Title from './components/title'
import Tagline from './components/tagline'
// import SocialButtons from './components/social-buttons'
import Needle from './components/needle'
import SocialLinks from './components/social-links'
import Footer from './components/footer'

const activeSegment = 6

function App () {
  const [ segment, setSegment ] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setSegment(activeSegment)
    }, 500)
  }, [])

  return (
    <div className={ `App ${(activeSegment === 1) ? 'redAlert' : ''}` }>
      <div id="layout">

        <div id="topWrapper">
          <div className="hideX">
            <Brand />
            <Gauge />
          </div>
          <Needle />
        </div>

        <div id="bottomWrapper">
          <Title />
          <Tagline />
          {/* <SocialButtons /> */}
        </div>

        <SocialLinks />

        <Footer />

      </div>
    </div>
  );
}

export default App;
