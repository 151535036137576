// import React from 'react'

import './index.css'

function Label (props) {
  const { angle, index= 0 } = props

  return <>
    <svg id="label-05"
      className="label"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 741 91.14"
      width="741"
      height="91.14"
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        transformOrigin: 'center right',
        transform: `translateY(50%) rotate(${angle}deg)`,
        animationDelay: `${1700 + (index * 200)}ms`,
      }}
    >
      <g>
        <path d="M201.75,77.82c1,0,1.59-.25,1.82-.76a8.55,8.55,0,0,0,.49-2.19,21.75,21.75,0,0,0,.22-3,1.17,1.17,0,0,0-.51-1.1,4.29,4.29,0,0,0-1.94-.3,2.27,2.27,0,0,0-1.39.35,1.88,1.88,0,0,0-.63,1.16c-.33,1.56-1,2.34-2,2.34-1.74,0-2.62-2.09-2.62-6.28a11.58,11.58,0,0,1,1.14-5.84,2.12,2.12,0,0,1,1.89-1.14c.85,0,1.33.57,1.46,1.7a7.69,7.69,0,0,0,.27,1.55,2.25,2.25,0,0,0,.61.86,2.94,2.94,0,0,0,2.07.66,1.87,1.87,0,0,0,1.31-.34,1.75,1.75,0,0,0,.3-1.31c0-.12,0-.78,0-2,0-2.48-.26-4-.77-4.53a1.22,1.22,0,0,0-.53-.42,9,9,0,0,0-1.41-.27,1.44,1.44,0,0,1-.94-.58,1.79,1.79,0,0,1-.39-1.14c0-1.1.64-1.81,1.93-2.13a36,36,0,0,1,7.84-.66,24.33,24.33,0,0,1,6.11.51,2.43,2.43,0,0,1,1.22.86,2.22,2.22,0,0,1,.47,1.38,1.9,1.9,0,0,1-.68,1.52,7.5,7.5,0,0,1-1.19.54,2.48,2.48,0,0,0-1.44,2.19,114,114,0,0,0-.38,12.05,29.72,29.72,0,0,0,.28,5.27,2.27,2.27,0,0,0,1.35,1.67,4.64,4.64,0,0,1,1.41.83,1.46,1.46,0,0,1,.33,1,1.88,1.88,0,0,1-.92,1.58,4,4,0,0,1-2.28.63c-.57,0-1.41,0-2.51-.13-2.34-.18-5.13-.28-8.38-.28a83.14,83.14,0,0,0-10.51.43,5.13,5.13,0,0,1-.56,0,1.91,1.91,0,0,1-1.44-.86A8.44,8.44,0,0,1,189,76.42a3.33,3.33,0,0,1,.63-2.13,2,2,0,0,1,1.63-.79,1.84,1.84,0,0,1,1.14.3,22.81,22.81,0,0,1,2,2.1,4.51,4.51,0,0,0,2.34,1.47A29.62,29.62,0,0,0,201.75,77.82Z"/>
        <path d="M176.18,70V63.06a52.67,52.67,0,0,0-.14-5.33A1.62,1.62,0,0,0,175,56.57a3.1,3.1,0,0,1-1.06-.64,1.27,1.27,0,0,1-.27-.86,1.87,1.87,0,0,1,.5-1.29,2.75,2.75,0,0,1,1.34-.82,20.36,20.36,0,0,1,5.22-.47,19,19,0,0,1,5.83.62,3,3,0,0,1,1.26.87,1.91,1.91,0,0,1,.5,1.24A1.1,1.1,0,0,1,188,56a3.92,3.92,0,0,1-1.17.67c-.56.24-.9.91-1,2a69.29,69.29,0,0,0-.35,8,45.29,45.29,0,0,0,.3,6.75,3.24,3.24,0,0,0,1.51,2.23,4,4,0,0,1,1.23,1,1.85,1.85,0,0,1,.34,1.13,2.31,2.31,0,0,1-1,1.84A10.51,10.51,0,0,1,184.62,81a34.39,34.39,0,0,1-3.84.86,22.84,22.84,0,0,1-3.44.4c-1.13,0-1.7-.7-1.7-2.1,0-.46.08-1.51.22-3.16Q176.13,73.16,176.18,70Z"/>
        <path d="M152.59,63.75l2.84-7.8a30.42,30.42,0,0,1,2.11-4.74A13.91,13.91,0,0,1,160,48.08a9.41,9.41,0,0,1,6.73-2.66,7.67,7.67,0,0,1,5.15,1.68,4.1,4.1,0,0,1,1.53,3.28,3.88,3.88,0,0,1-1.07,2.78,3.42,3.42,0,0,1-2.61,1.13,3.21,3.21,0,0,1-2.49-1,2.76,2.76,0,0,1-.6-.84,13.85,13.85,0,0,1-.45-1.61,1.06,1.06,0,0,0-1.08-.94,1.35,1.35,0,0,0-1.11.58,2.25,2.25,0,0,0-.45,1.44,9.58,9.58,0,0,0,1.11,3.37l5.18,11A9.9,9.9,0,0,0,171,68.43a6.27,6.27,0,0,0,1.8,1,1.59,1.59,0,0,1,.74.68,1.82,1.82,0,0,1,.31,1c0,1.27-1.26,2.14-3.8,2.59a32.32,32.32,0,0,1-5.78.46,10.79,10.79,0,0,1-3.59-.48A2.21,2.21,0,0,1,159,71.59a2.4,2.4,0,0,1,.5-1.27,2.29,2.29,0,0,0,.47-1.23,2.87,2.87,0,0,0-.23-1.07c-.15-.37-.47-1-.95-1.89s-1-1.5-1.44-1.5-.9.44-1.38,1.32a5.41,5.41,0,0,0-.71,2.54,2.36,2.36,0,0,0,.54,1.61,2.86,2.86,0,0,1,.82,1.66c0,.73-.49,1.31-1.46,1.75a9.68,9.68,0,0,1-3.91.65q-4.56,0-4.56-2.19a1.82,1.82,0,0,1,.46-1.28,4.5,4.5,0,0,1,1.69-.91,3.31,3.31,0,0,0,1.63-1.29A25,25,0,0,0,152.59,63.75Z"/>
        <path d="M133.88,72.83V60.35a6.38,6.38,0,0,0-.27-2.17,2.94,2.94,0,0,0-1.08-1.27,3.49,3.49,0,0,1-.89-.8,1.49,1.49,0,0,1-.23-.85q0-2.77,7-2.77a23.75,23.75,0,0,1,6,.6,2.36,2.36,0,0,1,1.21.75,1.84,1.84,0,0,1,.47,1.23,1.5,1.5,0,0,1-.24.9,6.79,6.79,0,0,1-1.2,1,2.76,2.76,0,0,0-.95,1.61,15.19,15.19,0,0,0-.28,3.33c0,.47,0,1.36.1,2.65s.11,2.06.14,2.27a1.77,1.77,0,0,0,1.2,1.35,1.86,1.86,0,0,1,1.33,1.72c0,.9-.58,1.58-1.74,2a38.86,38.86,0,0,1-5.15,1.57,20.06,20.06,0,0,1-4.26.64C134.28,74.16,133.88,73.72,133.88,72.83Zm4.25,9.41a5.43,5.43,0,0,1-3-.7,2.12,2.12,0,0,1-1.09-1.88A2.94,2.94,0,0,1,135.73,77a12.48,12.48,0,0,1,6.34-1.52,4.54,4.54,0,0,1,2.49.6,2.41,2.41,0,0,1,1.24,2.15c0,1.23-.88,2.2-2.64,2.9A13.67,13.67,0,0,1,138.13,82.24Z"/>
        <path d="M104.08,66.39l-.3-7.56a3,3,0,0,0-.27-1.27A10.63,10.63,0,0,0,102.19,56a1.38,1.38,0,0,1-.32-1c0-.94.57-1.6,1.7-2a21.84,21.84,0,0,1,6.06-.56,12.08,12.08,0,0,1,4.19.51,1.81,1.81,0,0,1,1.26,1.74,1.2,1.2,0,0,1-.13.64,10.38,10.38,0,0,1-.86,1q-.86.9-.85,6a10.51,10.51,0,0,0,.68,4.38,2.5,2.5,0,0,0,4,.22,7.25,7.25,0,0,0,.54-3.35V59.38a6.2,6.2,0,0,0-.16-1.74,10.46,10.46,0,0,0-1.07-1.82,1.72,1.72,0,0,1-.23-.88c0-.85.58-1.47,1.76-1.87a18.42,18.42,0,0,1,5.5-.6q6,0,6,2.47a1.41,1.41,0,0,1-.18.78,3.83,3.83,0,0,1-.87.76,1.84,1.84,0,0,0-.71.94,17.15,17.15,0,0,0-.28,2.59q-.15,2.45-.15,5.13a5.32,5.32,0,0,0,.23,2,3.55,3.55,0,0,0,1.3,1.12,1.62,1.62,0,0,1,.17,2.83,13.93,13.93,0,0,1-3.1,1.38c-1.37.49-2.7.9-4,1.24a12.3,12.3,0,0,1-2.62.5,1.42,1.42,0,0,1-1.09-.47,1.6,1.6,0,0,1-.44-1.12s0-.22,0-.53a1.89,1.89,0,0,0,0-.3c0-.69-.29-1-.86-1-.35,0-.71.27-1.1.81a6.12,6.12,0,0,1-5.37,2.67,7.68,7.68,0,0,1-4.64-1.42,5.54,5.54,0,0,1-1.89-2.38A13.27,13.27,0,0,1,104.08,66.39Z"/>
        <path d="M77.79,75.48a1.17,1.17,0,0,1-1-.62,2.53,2.53,0,0,1-.41-1.43,4.69,4.69,0,0,1,.55-2,5.71,5.71,0,0,1,1.32-1.79c.33-.28.5-.51.5-.68s0-.22,0-.52a6.21,6.21,0,0,1-.06-.82,5.85,5.85,0,0,1,1.72-4.29,10.43,10.43,0,0,1,4-2.41,16.15,16.15,0,0,1,5.36-.86,10.74,10.74,0,0,1,1.4.09c.37,0,.66.06.86.06a1,1,0,0,0,1.14-1.14,1.29,1.29,0,0,0-.74-1.15,4.12,4.12,0,0,0-2-.41,19.24,19.24,0,0,0-2.54.23,41.68,41.68,0,0,1-5.19.5,7.72,7.72,0,0,1-3.94-.84A4.74,4.74,0,0,1,77,55.6a4.93,4.93,0,0,1-.65-2.49A6.77,6.77,0,0,1,77.9,48.9a11.13,11.13,0,0,1,5.56-3.57,22.36,22.36,0,0,1,6.66-.9,17.58,17.58,0,0,1,7.73,1.46A5.86,5.86,0,0,1,100,47.7a3.84,3.84,0,0,1,.82,2.32,2.73,2.73,0,0,1-.46,1.54,2.57,2.57,0,0,1-1.17,1c-.43.18-.65.36-.65.56s.18.39.54.69a4,4,0,0,1,1.72,3,3.57,3.57,0,0,1-.83,2.12A5.62,5.62,0,0,1,98,60.63c-.34.17-.51.34-.51.52s.21.5.64.9a6.28,6.28,0,0,1,2.21,4.77,5.31,5.31,0,0,1-.78,2.75,6.91,6.91,0,0,1-2.18,2.23,13.7,13.7,0,0,1-7.67,2.26,19,19,0,0,1-3.74-.43,16.62,16.62,0,0,0-2.68-.39,7.49,7.49,0,0,0-4.67,1.85A1.55,1.55,0,0,1,77.79,75.48Zm16.35-24c.36,0,.53-.21.53-.62A2.33,2.33,0,0,0,93.3,48.8,7.21,7.21,0,0,0,89.73,48a7.92,7.92,0,0,0-3.29.56,1.72,1.72,0,0,0-1.2,1.57,1.15,1.15,0,0,0,.45.91,1.73,1.73,0,0,0,1.12.38,7.73,7.73,0,0,0,1-.09,16.93,16.93,0,0,1,2.69-.19,20.25,20.25,0,0,1,2.1.1,8.73,8.73,0,0,1,1.22.18ZM89.45,71a2.08,2.08,0,0,0,1.73-.82A3.34,3.34,0,0,0,91.84,68a8.28,8.28,0,0,0-.71-3.71,2.11,2.11,0,0,0-1.87-1.4,2.14,2.14,0,0,0-1.8.87,3.71,3.71,0,0,0-.67,2.33,7.53,7.53,0,0,0,.7,3.58A2.25,2.25,0,0,0,89.45,71Z"/>
        <path d="M50.89,74.34a11.83,11.83,0,0,1-6.89-2,11,11,0,0,1-3.42-3.81,10.5,10.5,0,0,1-1.33-5.11q0-4.66,4.27-7.93a15,15,0,0,1,9.35-3.11,11.79,11.79,0,0,1,8.31,2.92,9.57,9.57,0,0,1,3.1,7.39,10.38,10.38,0,0,1-3.79,8.29A14.23,14.23,0,0,1,50.89,74.34Zm1.72-5a1.84,1.84,0,0,0,1.51-.69,2.87,2.87,0,0,0,.57-1.86,17.82,17.82,0,0,0-.48-3.52,27,27,0,0,0-1.15-3.94,2.29,2.29,0,0,0-2.17-1.76C49.62,57.6,49,58.41,49,60a20.29,20.29,0,0,0,.48,4.1,17.86,17.86,0,0,0,1.17,3.74A2.28,2.28,0,0,0,52.61,69.37Z"/>
        <path d="M25.05,70.32H22.38a1.65,1.65,0,0,1-.95-.21.66.66,0,0,1-.25-.67l.17-2.56a1.76,1.76,0,0,1,.24-1,1.27,1.27,0,0,1,.86-.2l.71,0c.25,0,.47,0,.64,0A1.27,1.27,0,0,0,25,65.17a5.32,5.32,0,0,0,.32-2.24,12.55,12.55,0,0,0-.5-3.8,1.93,1.93,0,0,0-.56-1,3.21,3.21,0,0,0-1.2-.55c-1.26-.37-1.89-1.07-1.89-2.08s.74-1.85,2.2-2.32a25.79,25.79,0,0,1,7.23-.71q7.46,0,7.46,3a1.62,1.62,0,0,1-.31,1.08,4.35,4.35,0,0,1-1.37.79A2.36,2.36,0,0,0,35,59.23a25.85,25.85,0,0,0-.37,4.52,2.26,2.26,0,0,0,.38,1.49,1.73,1.73,0,0,0,1.36.4,5.61,5.61,0,0,1,1.48.11.5.5,0,0,1,.3.57L38,69a1.37,1.37,0,0,1-.22.83,2.21,2.21,0,0,1-1,.2c-.53,0-.8.29-.8.77a4.2,4.2,0,0,0,.58,1.38,7.3,7.3,0,0,1,1,3.57,5.54,5.54,0,0,1-1.8,4.27c-1.77,1.62-4.29,2.43-7.59,2.43a10.25,10.25,0,0,1-6.49-1.91,4.33,4.33,0,0,1-1.87-3.42,3.24,3.24,0,0,1,.93-2.35,3,3,0,0,1,2.3-1,4.81,4.81,0,0,1,3,1.08,20.41,20.41,0,0,1,1.63,1.69,2.62,2.62,0,0,0,1.85,1,1.38,1.38,0,0,0,1-.43,1.41,1.41,0,0,0,.43-1q0-1.7-3.22-3.63a7.85,7.85,0,0,1-1.34-.95,3.14,3.14,0,0,1-.64-1C25.63,70.4,25.4,70.32,25.05,70.32Z"/>
        <path d="M6.18,70.32H3.52a1.71,1.71,0,0,1-1-.21.68.68,0,0,1-.24-.67l.17-2.56a1.76,1.76,0,0,1,.24-1,1.24,1.24,0,0,1,.85-.2l.71,0c.26,0,.48,0,.65,0a1.25,1.25,0,0,0,1.22-.59,5.13,5.13,0,0,0,.33-2.24,12.55,12.55,0,0,0-.5-3.8,1.93,1.93,0,0,0-.56-1,3.21,3.21,0,0,0-1.2-.55C3,57.23,2.34,56.53,2.34,55.52s.73-1.85,2.2-2.32a25.79,25.79,0,0,1,7.23-.71q7.45,0,7.45,3a1.57,1.57,0,0,1-.31,1.08,4.15,4.15,0,0,1-1.36.79,2.39,2.39,0,0,0-1.38,1.91,26.89,26.89,0,0,0-.36,4.52,2.27,2.27,0,0,0,.37,1.49,1.77,1.77,0,0,0,1.37.4,5.61,5.61,0,0,1,1.48.11.5.5,0,0,1,.3.57L19.16,69a1.29,1.29,0,0,1-.23.83,2.17,2.17,0,0,1-1,.2c-.53,0-.79.29-.79.77a4.2,4.2,0,0,0,.58,1.38,7.3,7.3,0,0,1,1,3.57,5.51,5.51,0,0,1-1.81,4.27q-2.64,2.43-7.58,2.43a10.23,10.23,0,0,1-6.49-1.91A4.33,4.33,0,0,1,.94,77.15a3.24,3.24,0,0,1,.93-2.35,3,3,0,0,1,2.3-1,4.83,4.83,0,0,1,3,1.08,22.35,22.35,0,0,1,1.63,1.69,2.6,2.6,0,0,0,1.85,1,1.47,1.47,0,0,0,1.46-1.48q0-1.7-3.22-3.63a7.85,7.85,0,0,1-1.34-.95,3.14,3.14,0,0,1-.64-1C6.77,70.4,6.54,70.32,6.18,70.32Z"/>
        <path d="M232.25,30.92V23.66a3.37,3.37,0,0,0-.63-2.11,2,2,0,0,0-1.62-.81,4.93,4.93,0,0,0-2.21.77,1.26,1.26,0,0,1-.65.2,1.17,1.17,0,0,1-.94-.63,2.28,2.28,0,0,1-.43-1.35,3.4,3.4,0,0,1,1.46-2.56,11,11,0,0,1,7.39-2.6,7.76,7.76,0,0,1,5.52,1.95,6.66,6.66,0,0,1,2.08,5.12v9.93a1,1,0,0,0,.12.61,1,1,0,0,0,.61.12h2.22c.41,0,.67.06.79.2a1.77,1.77,0,0,1,.17,1v.94a1.09,1.09,0,0,1-.49,1l-9.84,6.18a1.93,1.93,0,0,1-.9.2h-1.74a.83.83,0,0,1-.72-.25,1.7,1.7,0,0,1-.19-1V37.6c0-.43-.06-.71-.2-.83a1.34,1.34,0,0,0-.87-.2h-4c-.53,0-.88-.09-1-.29a2.2,2.2,0,0,1-.23-1.23V33.78a2.49,2.49,0,0,1,.3-1.43,1.27,1.27,0,0,1,1.09-.4h3.89a1.19,1.19,0,0,0,.82-.21A1.21,1.21,0,0,0,232.25,30.92Z"/>
        <path d="M212.55,43.42l.28-9c0-1.07-.3-1.61-1-1.61a1.35,1.35,0,0,0-1,.71,6.43,6.43,0,0,1-5.56,3,7.23,7.23,0,0,1-5.44-2.08,5.22,5.22,0,0,1-1.27-2.2,14.41,14.41,0,0,1-.42-3.63l-.2-7.75a3.53,3.53,0,0,0-.26-1.48,4.34,4.34,0,0,0-1.15-1.18A1.48,1.48,0,0,1,195.9,17c0-1.54,2.34-2.32,7-2.32,4.15,0,6.23.73,6.23,2.17a1.18,1.18,0,0,1-.11.56,12.74,12.74,0,0,1-.75,1q-.9,1.18-.9,5.91a11.82,11.82,0,0,0,.53,4.17,2.55,2.55,0,0,0,2.54,1.78,2.14,2.14,0,0,0,1.93-.94,5.13,5.13,0,0,0,.45-2.58V22.67a28.46,28.46,0,0,0-.25-3.11,5.37,5.37,0,0,0-1-1.36,1.48,1.48,0,0,1-.41-1.05q0-2.43,6.31-2.43,7.39,0,7.39,2.52a1.45,1.45,0,0,1-.23.89,6.16,6.16,0,0,1-1.16.95c-.55.39-.92,1.43-1.13,3.13a78.3,78.3,0,0,0-.31,8.43,50.66,50.66,0,0,0,.26,6.4,2.09,2.09,0,0,0,.31,1,2.41,2.41,0,0,0,.85.64c.89.45,1.33,1,1.33,1.59a2,2,0,0,1-1,1.74,14.93,14.93,0,0,1-3.87,1.43,24.32,24.32,0,0,1-6.16,1.08C212.93,44.5,212.52,44.14,212.55,43.42Z"/>
        <path d="M174.79,24.78h10.09c.45,0,.67-.2.67-.58A3.47,3.47,0,0,0,184,21.57a5,5,0,0,0-3.26-1.24,7.52,7.52,0,0,0-2.29.4,7.12,7.12,0,0,0-2.07,1,2.28,2.28,0,0,1-1.16.51,1.47,1.47,0,0,1-1.1-.69,2.32,2.32,0,0,1-.53-1.41,3.9,3.9,0,0,1,1.93-3,12.76,12.76,0,0,1,7.88-2.56A11.26,11.26,0,0,1,191,17.19a11,11,0,0,1,3.71,8.23,10.44,10.44,0,0,1-2.19,6.49,11.55,11.55,0,0,1-4.81,3.72,12.19,12.19,0,0,1-4.75.92A10.65,10.65,0,0,1,176,34.23a9.27,9.27,0,0,1-2.32-2.75,7.22,7.22,0,0,1-1-3.52,4.18,4.18,0,0,1,.58-2.29A1.73,1.73,0,0,1,174.79,24.78Zm9.54,4h-2.17c-.92,0-1.38.43-1.38,1.27a2.65,2.65,0,0,0,.67,1.89,2.4,2.4,0,0,0,3.63-.21,3,3,0,0,0,.64-1.79,1.11,1.11,0,0,0-.31-.9A1.74,1.74,0,0,0,184.33,28.79Z"/>
        <path d="M151.71,36.55a21.48,21.48,0,0,1-3.5-.47,4.72,4.72,0,0,0-.82-.09,5.22,5.22,0,0,0-1.41.39,2.35,2.35,0,0,1-.84.19,2.71,2.71,0,0,1-2-1.27,9.35,9.35,0,0,1-1.33-2.21,5.35,5.35,0,0,1-.52-2.08,1.88,1.88,0,0,1,.6-1.43A2.13,2.13,0,0,1,143.4,29a2.43,2.43,0,0,1,1.34.37,14.07,14.07,0,0,1,1.9,1.61,3.84,3.84,0,0,0,2.28,1.33,1.43,1.43,0,0,0,1-.44,1.45,1.45,0,0,0,.44-1c0-.71-.67-1.37-2-2a18.66,18.66,0,0,1-5.56-3.57,5.42,5.42,0,0,1-1.63-4,6.06,6.06,0,0,1,2.62-5,8.75,8.75,0,0,1,5.52-1.74c.56,0,1.57.1,3,.28s2.17.24,2.34.24a3.12,3.12,0,0,0,.64-.07,9,9,0,0,1,.91-.08,2.34,2.34,0,0,1,1.59.51A7.45,7.45,0,0,1,159.49,18a7.52,7.52,0,0,1,.75,2.93c0,1.32-.51,2-1.55,2a1.75,1.75,0,0,1-1.1-.4,27.8,27.8,0,0,1-2.12-2.12,7.88,7.88,0,0,0-1.46-1.3,2.41,2.41,0,0,0-1.25-.35,1.64,1.64,0,0,0-1.13.37,1.26,1.26,0,0,0-.43,1c0,.65.55,1.23,1.65,1.76a15.61,15.61,0,0,1,5,3.51,6.24,6.24,0,0,1,1.53,4.16,6.56,6.56,0,0,1-1.57,4.41A7.57,7.57,0,0,1,151.71,36.55Z"/>
        <path d="M127.39,43.42l.28-9c0-1.07-.3-1.61-1-1.61a1.35,1.35,0,0,0-1,.71,6.43,6.43,0,0,1-5.56,3,7.23,7.23,0,0,1-5.44-2.08,5.22,5.22,0,0,1-1.27-2.2,14.41,14.41,0,0,1-.42-3.63l-.2-7.75a3.53,3.53,0,0,0-.26-1.48,4.34,4.34,0,0,0-1.15-1.18,1.48,1.48,0,0,1-.63-1.19c0-1.54,2.34-2.32,7-2.32,4.15,0,6.23.73,6.23,2.17a1.18,1.18,0,0,1-.11.56,12.74,12.74,0,0,1-.75,1q-.9,1.18-.9,5.91a11.82,11.82,0,0,0,.53,4.17,2.55,2.55,0,0,0,2.54,1.78,2.14,2.14,0,0,0,1.93-.94,5.13,5.13,0,0,0,.45-2.58V22.67a28.46,28.46,0,0,0-.25-3.11,5.37,5.37,0,0,0-1-1.36,1.48,1.48,0,0,1-.41-1.05q0-2.43,6.31-2.43,7.39,0,7.39,2.52a1.45,1.45,0,0,1-.23.89,6.16,6.16,0,0,1-1.16.95c-.55.39-.92,1.43-1.13,3.13a78.3,78.3,0,0,0-.31,8.43,50.66,50.66,0,0,0,.26,6.4,2.09,2.09,0,0,0,.31,1,2.41,2.41,0,0,0,.85.64c.89.45,1.33,1,1.33,1.59a2,2,0,0,1-1,1.74,14.93,14.93,0,0,1-3.87,1.43,24.32,24.32,0,0,1-6.16,1.08C127.77,44.5,127.36,44.14,127.39,43.42Z"/>
        <path d="M89.63,24.78H99.72c.45,0,.67-.2.67-.58a3.47,3.47,0,0,0-1.51-2.63,5,5,0,0,0-3.26-1.24,7.52,7.52,0,0,0-2.29.4,7.12,7.12,0,0,0-2.07,1,2.28,2.28,0,0,1-1.16.51,1.47,1.47,0,0,1-1.1-.69,2.32,2.32,0,0,1-.53-1.41,3.9,3.9,0,0,1,1.93-3,12.76,12.76,0,0,1,7.88-2.56,11.26,11.26,0,0,1,7.57,2.62,11.07,11.07,0,0,1,3.71,8.23,10.44,10.44,0,0,1-2.19,6.49,11.55,11.55,0,0,1-4.81,3.72,12.19,12.19,0,0,1-4.75.92,10.65,10.65,0,0,1-6.94-2.32,9.27,9.27,0,0,1-2.32-2.75,7.22,7.22,0,0,1-1-3.52,4.18,4.18,0,0,1,.58-2.29A1.73,1.73,0,0,1,89.63,24.78Zm9.54,4H97c-.92,0-1.38.43-1.38,1.27A2.65,2.65,0,0,0,96.29,32a2.4,2.4,0,0,0,3.63-.21,3,3,0,0,0,.64-1.79,1.11,1.11,0,0,0-.31-.9A1.74,1.74,0,0,0,99.17,28.79Z"/>
        <path d="M73.88,32.19v-6.9A52.41,52.41,0,0,0,73.74,20a1.6,1.6,0,0,0-1.06-1.16,3.26,3.26,0,0,1-1.07-.65,1.25,1.25,0,0,1-.27-.86A1.92,1.92,0,0,1,71.85,16a2.69,2.69,0,0,1,1.34-.81,19.7,19.7,0,0,1,5.22-.48,18.47,18.47,0,0,1,5.82.63,2.79,2.79,0,0,1,1.27.87,1.89,1.89,0,0,1,.5,1.23,1.11,1.11,0,0,1-.27.8,4.2,4.2,0,0,1-1.17.66c-.56.25-.91.91-1,2a71.91,71.91,0,0,0-.34,8,45.48,45.48,0,0,0,.3,6.75A3.2,3.2,0,0,0,85,37.84a3.82,3.82,0,0,1,1.23,1,1.86,1.86,0,0,1,.33,1.12,2.26,2.26,0,0,1-1,1.84,10.1,10.1,0,0,1-3.22,1.45,32.74,32.74,0,0,1-3.84.86A23.35,23.35,0,0,1,75,44.5c-1.13,0-1.7-.7-1.7-2.11,0-.45.07-1.51.22-3.15Q73.83,35.39,73.88,32.19Z"/>
        <path d="M48.21,29.83,53.77,19A16.77,16.77,0,0,1,56,15.51a2.64,2.64,0,0,1,1.94-1,2,2,0,0,1,1.63.73,16.93,16.93,0,0,1,2.3,3.31l6.1,11.58a5.15,5.15,0,0,0,.95,1.36,3.14,3.14,0,0,0,1.28.64c1.09.31,1.63.9,1.63,1.76s-.67,1.51-2,1.91a24.55,24.55,0,0,1-6.39.6A20.19,20.19,0,0,1,58,35.9c-1.15-.36-1.73-.9-1.73-1.63a2,2,0,0,1,.82-1.48,4.25,4.25,0,0,0,.67-.71,1.11,1.11,0,0,0,.15-.6,6.13,6.13,0,0,0-1-2.43c-.63-1.1-1.22-1.65-1.76-1.65a1.08,1.08,0,0,0-.88.41,15,15,0,0,0-1.25,2.1,4.59,4.59,0,0,0-.66,1.89A2,2,0,0,0,53.1,33a2.16,2.16,0,0,1,.73,1.44c0,.68-.45,1.18-1.36,1.48A15.77,15.77,0,0,1,48,36.4q-4.38,0-4.38-1.89c0-.73.52-1.26,1.57-1.59a4.18,4.18,0,0,0,1.66-.84A11.08,11.08,0,0,0,48.21,29.83Z"/>
        <path d="M24.62,24.78H34.71c.45,0,.67-.2.67-.58a3.44,3.44,0,0,0-1.51-2.63,5,5,0,0,0-3.26-1.24,7.52,7.52,0,0,0-2.29.4,7.25,7.25,0,0,0-2.07,1,2.28,2.28,0,0,1-1.16.51,1.47,1.47,0,0,1-1.1-.69,2.32,2.32,0,0,1-.53-1.41,3.9,3.9,0,0,1,1.93-3,12.79,12.79,0,0,1,7.89-2.56,11.25,11.25,0,0,1,7.56,2.62,10.8,10.8,0,0,1,2.45,3.12,10.94,10.94,0,0,1,1.26,5.11,10.44,10.44,0,0,1-2.19,6.49,11.55,11.55,0,0,1-4.81,3.72,12.19,12.19,0,0,1-4.75.92,10.65,10.65,0,0,1-6.94-2.32,9.27,9.27,0,0,1-2.32-2.75,7.22,7.22,0,0,1-1-3.52,4.18,4.18,0,0,1,.58-2.29A1.73,1.73,0,0,1,24.62,24.78Zm9.54,4H32c-.92,0-1.38.43-1.38,1.27A2.65,2.65,0,0,0,31.28,32a2.4,2.4,0,0,0,3.63-.21A3,3,0,0,0,35.55,30a1.11,1.11,0,0,0-.31-.9A1.74,1.74,0,0,0,34.16,28.79Z"/>
        <path d="M12.54,36.55A21.48,21.48,0,0,1,9,36.08,4.72,4.72,0,0,0,8.23,36a5.16,5.16,0,0,0-1.42.39,2.35,2.35,0,0,1-.84.19A2.71,2.71,0,0,1,4,35.3a9.35,9.35,0,0,1-1.33-2.21A5.54,5.54,0,0,1,2.12,31a1.89,1.89,0,0,1,.61-1.43A2.09,2.09,0,0,1,4.23,29a2.43,2.43,0,0,1,1.34.37A13.46,13.46,0,0,1,7.47,31a3.84,3.84,0,0,0,2.28,1.33,1.43,1.43,0,0,0,1-.44,1.41,1.41,0,0,0,.44-1c0-.71-.67-1.37-2-2a18.66,18.66,0,0,1-5.56-3.57A5.42,5.42,0,0,1,2,21.3a6.06,6.06,0,0,1,2.62-5,8.75,8.75,0,0,1,5.52-1.74c.56,0,1.57.1,3.05.28s2.17.24,2.34.24A3.28,3.28,0,0,0,16.2,15a8.67,8.67,0,0,1,.9-.08,2.34,2.34,0,0,1,1.59.51A7.3,7.3,0,0,1,20.32,18a7.52,7.52,0,0,1,.75,2.93c0,1.32-.51,2-1.54,2a1.78,1.78,0,0,1-1.11-.4,27.8,27.8,0,0,1-2.12-2.12,7.57,7.57,0,0,0-1.46-1.3,2.37,2.37,0,0,0-1.24-.35,1.65,1.65,0,0,0-1.14.37,1.26,1.26,0,0,0-.43,1c0,.65.55,1.23,1.65,1.76a15.61,15.61,0,0,1,5,3.51,6.24,6.24,0,0,1,1.53,4.16,6.56,6.56,0,0,1-1.57,4.41A7.55,7.55,0,0,1,12.54,36.55Z"/>
      </g>
    </svg>

  </>
}

export default Label
